import FlashLib from 'flashlib';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import ControllerSounds, { eSoundType } from '../sounds/controllerSounds';
import ReelStartSpinState from './states/reelStartSpinState';
import ReelSpinState from './states/reelSpinState';
import ReelStopSpin from './states/reelStopSpin';

export default class ControllerReel extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.index = parseInt(this.name.replace('reel_', ''));

    this.speed = 1; // 2
    this.startSpeed = 0.2; // 0.1

    this.needStop = false;
    this.stopped = false;
    this.isAnticipator = false;
    this.stopCallback = () => {};

    this.config = {
      symbols: {
        height: 235
      }
    };

    const state = store.getState().game;
    this.availableSymbols = state.availableSymbols[this.index];
    this.symbols = [];
    this.stopSymbolId = 0;

    // Закоментить при активации временного кода

    for (let i = 0; i < 3; i++) {
      const item = this.getChildByName(`element_${i}`);
      item.setSymbol(this.availableSymbols[i]);
      this.symbols.push(item);
    }

    //     // Показать мои символы на старте (Начало временного кода (Удалить))

    //     //  "low_orange"
    //     //  "low_green"
    //     //  "scatter"
    //     //  "low_purple"
    //     //  "low_blue"
    //     //  "toucan"
    //     //  "frog"
    //     //  "wild"
    //     //  "monkey"
    //     const startSimbols = ["wild","wild","wild","wild","wild", "wild","wild" ]

    //     for (let i = 0; i < 3; i++) {
    //       const item = this.getChildByName(`element_${i}`);
    //       item.setSymbol(startSimbols[this.index]);
    //       this.symbols.push(item);
    //     }
    //     setInterval(()=>{this.symbols[1].animateSymbol()}, 2000);
    // // (Конец временного кода (Удалить))

    this.states = {
      startSpin: new ReelStartSpinState(this),
      spin: new ReelSpinState(this),
      stop: new ReelStopSpin(this)
    };
    storeObserver.addListener(selectors.getBonusGameShowed, this.onBonusGameShowed);
  }
  onBonusGameShowed = (bonusGameShowed) => {
    if (bonusGameShowed === this.bonusGameShowed) return;
    this.bonusGameShowed = bonusGameShowed;
    const state = store.getState();
    const bonusGameType = selectors.getBonusGameType(state);
    if (this.bonusGameShowed && bonusGameType === 2 || !this.bonusGameShowed) {
      this.updateAvailableSymbols();
    }
  }

  setState(state) {
    this.state = state;
  }

  spin() {
    this.needStop = false;
    this.stopped = false;

    this.setState(this.states.startSpin);

    this.state.spin();
  }

  updateAvailableSymbols = () => {
    const state = store.getState().game;
    this.availableSymbols = state.availableSymbols[this.index];
  };

  async forceStop(type, stopCallback) {
    if (this.stopped) return;

    const delay = 50 * this.index;

    setTimeout(() => {
      ControllerSounds.stopSound(eSoundType.EST_ANTICIPATOR);

      this.needStop = true;
      this.stopSymbol = type;
      this.stopCallback = stopCallback;

      ControllerSounds.stopSound(eSoundType.EST_ANTICIPATOR);
    }, delay);

  }

  stop(type, id, stopCallback) {
    this.needStop = true;
    this.stopSymbolId = id;
    this.stopSymbol = type;

    this.stopCallback = stopCallback;
  }

  cycleSymbols() {
    this.availableSymbols.unshift(this.availableSymbols.pop());
    const item = this.symbols.pop();
    item.setSymbol(this.availableSymbols[0]);
    this.symbols.unshift(item);
    this.symbols.forEach((symbol, index) => {
      symbol.y = this.config.symbols.height * (index - 1);
    });
  }

  playSymbolAnimation() {
    this.symbols[1].animateSymbol();
  }
}
