const getBalance = (state) => state.game.balance;
const getBetAmount = (state) => state.game.betAmount;
const getBetIndex = (state) => state.game.currentBetIndex;
const getGameState = (state) => state.game.gameState;
const getCurrencyType = (state) => state.game.currencyInfo.currency;
const getWinAmount = (state) => state.game.winAmount;
const getLastWinAmount = (state) => state.game.lastWinAmount;
const getTotalWinAmount = (state) => state.game.totalWinFS;
const getCurrencyInfo = (state) => state.game.currencyInfo;
const getBetMinMax = (state) => ({ min: state.game.betMin, max: state.game.betMax });
const getStopReels = (state) => state.game.stopReels;
const getWinLine = (state) => state.game.winLine;
const getPaytable = (state) => state.game.paytable;
const getFreespinsEnabled = (state) => state.game.freespinsEnabled;
const getFreespinsCount = (state) => state.game.freespinsCount;
const getNewFreespinsCount = (state) => state.game.newFreespinsCount;
const getBonusAmount = (state) => state.game.bonusAmount;
const getPreBonusGameEnabled = (state) => state.game.preBonusGameEnabled;
const getPreBonusGameType = (state) => state.game.preBonusGameType;
const getPreBonusGameProgress = (state) => state.game.preBonusGameProgress;
const getPreBonusGameRealProgress = (state) => state.game.preBonusGameRealProgress;
const getPreBonusGameMaxProgress = (state) => state.game.preBonusGameMaxProgress;
const getPreBonusGameShowed = (state) => state.game.preBonusGameShowed;
const getBonusGameType = (state) => state.game.bonusGameType;
const getBonusGameEnabled = (state) => state.game.bonusGameEnabled;
const getBonusGameShowed = (state) => state.game.bonusGameShowed;
const getBonusGameCrocodileEnabled = (state) => state.game.bonusGameCrocodileEnabled;
const getBonusGameCrocodileProgress = (state) => state.game.bonusGameCrocodileProgress;
const getBonusGameCobraLives = (state) => state.game.bonusGameCobraLives;
const getBonusGameCobraStep = (state) => state.game.bonusGameCobraStep;
const getBonusGameScorpionProgress = (state) => state.game.bonusGameScorpionProgress;
const getBonusGameScorpionMultiplier = (state) => state.game.bonusGameScorpionMultiplier;
const getCurrentBetIndex = (state) => state.game.currentBetIndex;
const getBets = (state) => state.game.bets;
const getBasicBonusGameReels = (state) => state.game.bonusGameReels.basicReels;
const getRealBonusGameReels = (state) => state.game.bonusGameReels.realReels;
const getBonusPrices = (state) => state.game.bonusPrices[state.game.currentBetIndex];

const getAvailableSymbols = (index) => (state) => state.game.availableSymbols[index];
const getAutospin = (state) => state.game.autospin;
const getScreenMode = (state) => state.game.gameScreen;

export default {
  getBalance,
  getBetAmount,
  getBetIndex,
  getGameState,
  getCurrencyType,
  getWinAmount,
  getTotalWinAmount,
  getCurrencyInfo,
  getBetMinMax,
  getStopReels,
  getWinLine,
  getPaytable,
  getFreespinsEnabled,
  getFreespinsCount,
  getBonusAmount,
  getAvailableSymbols,
  getPreBonusGameProgress,
  getPreBonusGameRealProgress,
  getPreBonusGameMaxProgress,
  getPreBonusGameEnabled,
  getPreBonusGameType,
  getPreBonusGameShowed,
  getCurrentBetIndex,
  getBets,
  getBonusGameEnabled,
  getBonusGameShowed,
  getBonusGameType,
  getBonusGameCrocodileEnabled,
  getBonusGameCrocodileProgress,
  getBonusGameCobraLives,
  getBonusGameCobraStep,
  getBonusGameScorpionProgress,
  getBonusGameScorpionMultiplier,
  getBasicBonusGameReels,
  getRealBonusGameReels,
  getBonusPrices,
  getNewFreespinsCount,
  getAutospin,
  getScreenMode,
  getLastWinAmount
};
