export default {
  BGSA_FRAME_STATIC: 'frame_static',
  BGSA_FRAME_BLINK: 'frame_blink',

  BGSA_BAR_STEP: 'bar_juice_1_step',
  BGSA_BAR_STATIC: 'bar_juice_static',

  BGSA_X2_START: 'x2_start',
  BGSA_X2_IDLE: 'x2_idle',
  BGSA_X2_STATIC: 'x2_static',

  BGSA_X3_START: 'x3_start',
  BGSA_X3_IDLE: 'x3_idle',
  BGSA_X3_STATIC: 'x3_static',

  BGSA_X10_START: 'x10_start',
  BGSA_X10_IDLE: 'x10_idle',
  BGSA_X10_STATIC: 'x10_static',

};
