import eSymbolType from './eSymbolType';

export default {
  '7': eSymbolType.EST_LOW_BLUE,
  '6': eSymbolType.EST_LOW_PURPLE,
  '5': eSymbolType.EST_LOW_GREEN,
  '4': eSymbolType.EST_LOW_ORANGE,
  '3': eSymbolType.EST_HIGH_3,
  '2': eSymbolType.EST_HIGH_2,
  '1': eSymbolType.EST_HIGH_1,
  '0': eSymbolType.EST_WILD,
  '10': eSymbolType.EST_JUG_COBRA,
  '11': eSymbolType.EST_JUG_GOLD,
  '12': eSymbolType.EST_JUG_EMPTY
};
