import eAnimationType from './eAnimationType';

class AnimationsLoader {
  constructor() {
    this.animationsData = [
      { type: eAnimationType.EAT_LOW_ICONS, src: 'assets/animations/low_icons/low_icon.json' },
      { type: eAnimationType.EAT_HIGH_3, src: 'assets/animations/high_icons/frog.json' },
      { type: eAnimationType.EAT_HIGH_1, src: 'assets/animations/high_icons/tucan.json' },
      { type: eAnimationType.EAT_HIGH_2, src: 'assets/animations/high_icons/monkey.json' },
      { type: eAnimationType.EST_WILD, src: 'assets/animations/high_icons/sabers.json' },
      { type: eAnimationType.EST_VINES, src: 'assets/animations/vines/vines.json' },
      { type: eAnimationType.EST_TORCH, src: 'assets/animations/torch/skeleton.json' },
      { type: eAnimationType.EST_MODAL_BIG_WINS, src: 'assets/animations/modals/win_screens_m_o_b_new_pro.json' },
      { type: eAnimationType.EAT_ANIMALS_BONUS_GAME, src: 'assets/animations/bonus_game/animals/animals_bonus_game_pro.json' },
      { type: eAnimationType.EAT_BONUS_GAME_BG, src: 'assets/animations/bonus_game/backgrounds/bg_animation_pro.json' },
      { type: eAnimationType.EAT_JUGS, src: 'assets/animations/jugs/jug_bonus_game.json' },
      { type: eAnimationType.EAT_BONUS_PURCHASE, src: 'assets/animations/popup/bonus_purchase/purchase_bonuses.json' },
      { type: eAnimationType.EAT_BONUS_GAME_CROCODILE, src: 'assets/animations/bonus_game_crocodile/crocodile_freespins.json' },
      { type: eAnimationType.EAT_BONUS_GAME_SCORPION, src: 'assets/animations/bonus_game_scorpion/progressbar.json' },
      { type: eAnimationType.EAT_POPUP_START_BONUS_GAME, src: 'assets/animations/popup/start_bonus_game/skeleton.json' },
      { type: eAnimationType.EAT_PRE_BONUS_GAME_BAR_PROGRESS, src: 'assets/animations/bonus_game/progress_bar/skeleton.json' },
      { type: eAnimationType.EAT_BONUS_BUTTON, src: 'assets/animations/bonus_button/button_bones_pro.json' },
      { type: eAnimationType.EAT_COINS, src: 'assets/animations/coins/skeleton.json' },
    ];
  }

  startLoading(app, onComplete) {
    this.onLoadingComplete = onComplete;
    this.app = app;
    for (let item of this.animationsData) {
      app.loader.add(item.type, item.src);
    }
    app.loader.load(this.onAnimationLoaded);
  }

  onAnimationLoaded = (_, resources) => {
    this.resources = resources;
    this.onLoadingComplete();
  };

  createAnimation(type) {
    if (!window.OPWrapperService.UserAgentDetector.isOpera) {
      this.resources[type].spineAtlas.pages.forEach(page => {
        page.baseTexture.alphaMode = PIXI.ALPHA_MODES.PMA;
      });
    }

    return new PIXI.spine.Spine(this.resources[type].spineData);
  }
}

export default new AnimationsLoader();
