import FlashLib from 'flashlib';
import ControllerButton from './controllers/base/controllerButton';
import ModalBase from './controllers/base/modalBase';
import ControllerSlot from './controllers/slot/controllerSlot';
import ControllerReel from './controllers/reel/controllerReel';
import ControllerSymbol from './controllers/symbol/controllerSymbol';
import ControllerControlPanel from './controllers/ui';
import ModalSettings from './controllers/popups/modalSettings';
import ControllerSwitch from './controllers/base/controllerSwitch';
import ModalPaytable from './controllers/popups/modalPaytable';
import FreespinsPanel from './controllers/freespins/freespinsPanel';
import ControllerVines from './controllers/vines/controllerVines';
import ControllerBlackout from './controllers/controllerBlackout';
import ModalFreeSpins from './controllers/popups/modalFreeSpins';
import ModalTotalWin from './controllers/popups/modalTotalWin/modalTotalWin';
import TorchController from './controllers/torchController/TorchController';
import JackpotPanelController from './controllers/JackpotPanelController/JackpotPanelController';

import ControllerBonusGamePanel from './controllers/BonusGame/controllerBonusGamePanel';
import ControllerBonusGame from './controllers/BonusGame/controllerBonusGame';
import ControllerLogo from './controllers/controllerLogo';
import PopupBonusPurchase from './controllers/popups/popupBonusPurchase/popupBonusPurchase';
import ControllerBonusButton from './controllers/ui/controllerBonusButton';
import ControllerBonusPurchaseModal from './controllers/popups/popupBonusPurchase/ControllerBonusPurchaseModal';
import BonusGameCrocodile from './controllers/BonusGameCrocodile/bonusGameCrocodile';
import ControllerBonusGameScorpion from './controllers/bonusGameScorpioon/controllerBonusGameScorpion';
import ControllerHeart from './controllers/freespins/ControllerHeart';
import ModalWin from './controllers/popups/modalWin';
import PopupTransition from './controllers/popups/popupTransition';
import ControllerPreBonusGameProgressBar from './controllers/BonusGame/preBonusGameProgressBar';
import ModalBigWin from './controllers/popups/modalBigWin/modalBigWin';
import ControllerBackground from './controllers/controllerBackground';

FlashLib.registerClass('Button', ControllerButton);
FlashLib.registerClass('ControllerPopup', ModalBase);
FlashLib.registerClass('ControllerSlot', ControllerSlot);
FlashLib.registerClass('ControllerReel', ControllerReel);
FlashLib.registerClass('ControllerSymbol', ControllerSymbol);
// FlashLib.registerClass('ControllerAnticipator', ControllerAnticipator);
// FlashLib.registerClass('ControllerAnticipatorGroup', ControllerAnticipatorGroup);
FlashLib.registerClass('ControlPanel', ControllerControlPanel);
FlashLib.registerClass('ModalSettings', ModalSettings);
FlashLib.registerClass('ModalPaytable', ModalPaytable);
FlashLib.registerClass('Switch', ControllerSwitch);
FlashLib.registerClass('ModalBigWin', ModalBigWin);
FlashLib.registerClass('ModalWin', ModalWin);
FlashLib.registerClass('ModalFreeSpins', ModalFreeSpins);
FlashLib.registerClass('ModalTotalWin', ModalTotalWin);
FlashLib.registerClass('ControllerBlackout', ControllerBlackout);
FlashLib.registerClass('FreespinsPanel', FreespinsPanel);
FlashLib.registerClass('ControllerVines', ControllerVines);
FlashLib.registerClass('TorchController', TorchController);
FlashLib.registerClass('JackpotPanelController', JackpotPanelController);
FlashLib.registerClass('ControllerBonusGamePanel', ControllerBonusGamePanel);
FlashLib.registerClass('ControllerBonusGame', ControllerBonusGame);
FlashLib.registerClass('ControllerLogo', ControllerLogo);
FlashLib.registerClass('PopupBonusPurchase', PopupBonusPurchase);
FlashLib.registerClass('ControllerBonusButton', ControllerBonusButton);
FlashLib.registerClass('ControllerBonusPurchaseModal', ControllerBonusPurchaseModal);
FlashLib.registerClass('BonusGameCrocodile', BonusGameCrocodile);
FlashLib.registerClass('ControllerBonusGameScorpion', ControllerBonusGameScorpion);
FlashLib.registerClass('ControllerHeart', ControllerHeart);
FlashLib.registerClass('PopupTransition', PopupTransition);
FlashLib.registerClass('ControllerPreBonusGameProgressBar', ControllerPreBonusGameProgressBar);
FlashLib.registerClass('ControllerBackground', ControllerBackground);
