export default {
  H: {
    offsets: {
      right_panel: { top: 0, left: -11 },
    }
  },
  V: {
    offsets: {
      top: -110,
      left: 0,
    }
  }
}
