export const sleep = time => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), time);
  });
};

// class SleepWithStop {
//   constructor(time) {
//     this.time = time;
//   }
//
//   start () {
//     return new Promise(( resolve ) => {
//       this.timeout = setTimeout(() => resolve(), this.time);
//     });
//   }
//
//   stop () {
//     clearTimeout(this.timeout);
//   }
//
// }
