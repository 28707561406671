import { handleActions } from 'redux-actions';

import actions from './actions';
import serverClientSymbols from '../../controllers/symbol/serverClientSymbols';
import { eScreenMode } from '../../enums';
import { getUrlParam } from '../../libs/op-wrapper-service/utils/url';

export const eGameState = {
  EGS_READY_TO_PLAY: 'ready_to_play',
  EGS_WAITING_FOR_RESPONSE: 'waiting_for_response',
  EGS_CAN_STOP: 'can_stop',
  EGS_START_AUTOSPIN: 'start_autospin',
  EGS_STOP_AUTOSPIN: 'stop_autospin',
  EGS_STOP_SPIN: 'stop_spin',
  EGS_SHOW_WIN: 'show_win',
  EGS_FREESPIN_MODE: 'freespin_mode',
  EGS_BUY_BONUS_GAME: 'buy_bonus_game'

};

const initialState = {
  betAmount: 1,
  currency: 'RUB',
  round_hash: '',
  availableSymbols: [],
  winAmount: 0,
  lastWinAmount: 0,
  bonusAmount: 0,
  bets: [],
  betsAvailable: [],
  freeBetsAvailable: [],
  currentBetIndex: 0,
  autospin: false,
  gameScreen: eScreenMode.START,
  // user state
  balance: 0.5,
  gameState: eGameState.EGS_READY_TO_PLAY,
  betMin: 1,
  betMax: 100,
  preBonusGameEnabled: false,
  preBonusGameType: -1,
  preBonusGameProgress: 0,
  preBonusGameRealProgress: null,
  preBonusGameMaxProgreses: {},
  preBonusGameMaxProgress: 0,
  preBonusGameShowed: false,
  bonusGameReels: {
    basicReels: [],
    realReels: []
  },
  bonusGameEnabled: false,
  bonusGameType: -1,
  bonusGameShowed: false,
  bonusGameScorpionProgress: 0,
  bonusGameScorpionMultiplier: 1,
  bonusGameCobraLives: -1,
  bonusGameCrocodileEnabled: false,
  bonusGameCrocodileProgress: -1,
  freespinsEnabled: false,
  freespinsMode: false,
  freespinsCount: 0,
  freeSpinsMax: 0,
  freespinsSymbols: [],
  canSpin: false,
  stopping_reel: 0,
  anticipationReels: [],
  currencyInfo: {
    symbol: '₽',
    decimal: 2,
    currency: 'rub',
    balance: 0,
  }
};

const setData = (state, { payload }) => {
  convertToClientSymbols(payload.available_icons);
  convertToClientSymbols(payload.available_jugs_icons);

  const paytable = {};
  payload.slot_machine.paytable.forEach((p, index) => {
    paytable[serverClientSymbols[index + 2]] = p;
  });
  const betsAvailable = payload.bets_available;
  // pid : 217 PMBelarus
  let betAmount = payload.bet_amount ? payload.bet_amount : betsAvailable[getUrlParam('pid') === '217' ? 0 : betsAvailable.length - 2];
  let currentBetIndex = betsAvailable.indexOf(betAmount);

  if (payload.slot_machine.free_spins > 0 && payload.free_spins_bet) {
    currentBetIndex = betsAvailable.findIndex(value => value === payload.free_spins_bet);
  }
  let bets = betsAvailable;

  //freeBets
  let freeBetsAvailable = [];
  if (payload.freebets) {
    freeBetsAvailable = payload.freebets.bet_levels;
    const freeBetsIsActive = payload.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;

    if (freeBetsIsActive) {
      currentBetIndex = payload.freebets.bet_level;
      betAmount = freeBetsAvailable[currentBetIndex];
      bets = freeBetsAvailable;
    }
  }

  const preBonusGameEnabled = payload.bonuses && payload.bonuses[currentBetIndex]?.bonusProgress >= 0 && !payload.bonus?.condition;
  const bonusGameEnabled = payload.bonus.condition?.type != null;
  const bonusGameType = payload.bonus.condition?.type - 1 || 0;

  const bonusPrices = payload.bonus_prices;
  const preBonusGameType = payload.bonuses && payload.bonuses[currentBetIndex]?.bonusType - 1 || -1;

  const maxProgreses = payload.bonuses_config;
  const preBonusGameMaxProgreses = {};
  for (let key in maxProgreses) {
    preBonusGameMaxProgreses[key - 1] = maxProgreses[key];
  }
  const currentBonusInfo = payload.bonuses ? payload.bonuses[currentBetIndex] : null;
  const preBonusGameRealProgress = currentBonusInfo && currentBonusInfo.hasOwnProperty('bonusProgress') ? currentBonusInfo.bonusProgress : null;
  const availableSymbols = bonusGameType !== 2 ? payload.available_icons : payload.available_jugs_icons;
  return {
    ...state,
    balance: payload.balance,
    availableSymbols,
    freeBetsAvailable,
    betsAvailable,
    bets,
    betMin: bets[0],
    betMax: bets[bets.length - 1],
    currentBetIndex,
    betAmount,
    paytable,
    preBonusGameEnabled,
    preBonusGameType,
    preBonusGameProgress: currentBonusInfo && currentBonusInfo?.bonusProgressVisual || 0,
    preBonusGameRealProgress,
    preBonusGameMaxProgreses,
    preBonusGameMaxProgress: preBonusGameMaxProgreses[preBonusGameType],
    preBonusGameShowed: preBonusGameEnabled,
    bonusGameCrocodileEnabled: payload.bonus.condition?.type === 2,
    bonusGameCrocodileProgress: payload.bonus?.condition?.croc_pos - 1 >= 0
      ? payload.bonus?.condition?.croc_pos - 1
      : -1,
    bonusGameCobraLives: payload.bonus?.condition?.lives,
    bonusGameCobraStep: payload.bonus?.condition?.step,
    bonusGameScorpionProgress: payload.bonus?.condition?.scorpio_progress || 0,
    bonusGameScorpionMultiplier: payload.bonus?.condition?.win_multi || 1,
    bonuses: payload.bonuses || {},
    bonusGameEnabled,
    bonusGameType,
    bonusGameShowed: bonusGameEnabled,
    freespinsEnabled: bonusGameEnabled,
    freespinsCount: payload.bonus?.condition?.free_spins_count,
    freeSpinsMax: payload.bonus?.condition?.free_spins_count,
    totalWinFS: payload.bonus?.condition?.total_win_amount,
    bonusPrices,
    currencyInfo: {
      symbol: payload.currency,
      //todo: remove decimal in next release
      decimal: payload.decimal || payload.decimals,
      currency: payload.currency,
      balance: payload.balance,
    }
  };
};

const setNewGame = (state, { payload }) => {
  state.gameState = eGameState.EGS_CAN_STOP;

  const winLines = [];
  payload.slot_machine.combinations.symbols.forEach(lineInfo => {
    winLines.push(lineInfo);
  });

  let bets = state.betsAvailable;

  let betAmount = payload.hasOwnProperty('bet_amount')
    ? payload.bet_amount
    : payload.hasOwnProperty('bet_index')
      ? state.betsAvailable[payload.bet_index]
      : state.betAmount;

  let currentBetIndex = payload.hasOwnProperty('bet_index')
    ? payload.bet_index
    : payload.hasOwnProperty('bet_amount')
      ? state.betsAvailable.indexOf(payload.bet_amount)
      : state.currentBetIndex;

  //freeBets
  let freeBetsAvailable = [];
  let freeBetsIsActive;
  let isLastFreeBet;
  const winAmount = payload.bonus?.condition?.win_amount >= 0
    ? payload.bonus?.condition?.win_amount
    : payload.win_amount || 0;

  if (payload.freebets) {
    freeBetsAvailable = payload.freebets.bet_levels;
    freeBetsIsActive = payload.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;
    isLastFreeBet = payload.freebets && payload.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_PLAYED;

    if (freeBetsIsActive) {
      currentBetIndex = payload.freebets.bet_level;
      betAmount = freeBetsAvailable[currentBetIndex];
      bets = freeBetsAvailable;
    }
  }

  window.OPWrapperService.ControllerStatistic.win = winAmount;
  if (freeBetsIsActive || isLastFreeBet) {
    window.OPWrapperService.ControllerStatistic.increaseFreeBetsCount();
  } else {
    window.OPWrapperService.ControllerStatistic.increaseSpinCount();
  }

  const currentBonusInfo = payload.bonuses ? payload.bonuses[currentBetIndex] : null;
  const preBonusGameRealProgress = currentBonusInfo && currentBonusInfo.hasOwnProperty('bonusProgress') ? currentBonusInfo.bonusProgress : null;

  const preBonusGameEnabled = currentBonusInfo?.bonusProgress >= 0;
  const preBonusGameType = currentBonusInfo?.bonusType - 1 || -1;

  const bonusGameType = payload.bonus.condition?.type - 1 || 0;
  const freespinsCount = payload.bonus?.condition?.free_spins_count;
  const bonusGameCobraLives = payload.bonus?.condition?.lives;

  const availableSymbols = bonusGameType !== 2 || (bonusGameCobraLives === 0 || freespinsCount === 0) ? payload.available_icons : payload.available_jugs_icons;
  return {
    ...state,
    availableSymbols,
    freeBetsAvailable,
    bets,
    betAmount,
    currentBetIndex,
    stopReels: payload.slot_machine.reel,
    winLine: winLines,
    winAmount,
    lastWinAmount: state.winAmount || state.lastWinAmount,
    balance: payload.balance || 0,
    preBonusGameEnabled,
    preBonusGameType,
    preBonusGameProgress: currentBonusInfo?.bonusProgressVisual || 0,
    preBonusGameRealProgress,
    preBonusGameMaxProgress: state.preBonusGameMaxProgreses[preBonusGameType],
    bonusAmount: payload.bonus?.amount || 0,
    bonusGameCrocodileEnabled: payload.bonus.condition?.type === 2,
    bonusGameCrocodileProgress: payload.bonus?.condition?.croc_pos - 1 >= 0
      ? payload.bonus?.condition?.croc_pos - 1
      : -1,
    bonusGameCobraLives,
    bonusGameCobraStep: payload.bonus?.condition?.step,
    bonusGameScorpionProgress: payload.bonus?.condition?.scorpio_progress || 0,
    bonusGameScorpionMultiplier: payload.bonus?.condition?.win_multi || 1,
    bonuses: payload.bonuses || {},
    bonusGameEnabled: payload.bonus.condition?.type - 1 > 0,
    bonusGameType,
    bonusGameReels: {
      basicReels: payload.bonus?.condition?.basic_reel,
      realReels: payload.bonus?.condition?.real_reel
    },
    freespinsEnabled: payload.bonus.condition?.type - 1 > 0,
    freespinsCount,
    newFreespinsCount: payload.bonus.condition?.new_free_spins_count,
    freeSpinsMax: payload.bonus?.condition?.free_spins_count,
    totalWinFS: payload.bonus?.condition?.total_win_amount,
  };
};

const setBonusGame = (state, { payload }) => {
  const bonusGameType = payload.bonus.condition?.type - 1 || 0;

  const availableSymbols = bonusGameType !== 2 ? payload.available_icons : payload.available_jugs_icons;

  return {
    ...state,
    availableSymbols,
    balance: payload.balance || 0,
    preBonusGameEnabled: false,
    bonusGameCrocodileEnabled: payload.bonus.condition?.type === 2,
    bonusGameCrocodileProgress: payload.bonus?.condition?.croc_pos - 1 >= 0
      ? payload.bonus?.condition?.croc_pos - 1
      : -1,
    bonusGameCobraLives: payload.bonus?.condition?.lives,
    bonusGameCobraStep: payload.bonus?.condition?.step,
    bonusGameScorpionProgress: payload.bonus?.condition?.scorpio_progress || 0,
    bonusGameScorpionMultiplier: payload.bonus?.condition?.win_multi || 1,
    bonuses: payload.bonuses || {},
    bonusGameEnabled: payload.bonus.condition?.type - 1 > 0,
    bonusGameType,
    bonusGameReels: {
      basicReels: payload.bonus?.condition?.basic_reel,
      realReels: payload.bonus?.condition?.real_reel
    },
    freespinsEnabled: payload.bonus.condition?.type - 1 > 0,
    freespinsCount: payload.bonus?.condition?.free_spins_count,
    freeSpinsMax: payload.bonus?.condition?.free_spins_count,
    totalWinFS: payload.bonus?.condition?.total_win_amount,

  };
};
const tryShowBonusGame = (state) => {
  return {
    ...state,
    bonusGameShowed: state.bonusGameEnabled,
    preBonusGameShowed: state.bonusGameEnabled ? false : state.preBonusGameShowed,

  };

};

const closeBonusGame = (state) => {
  return {
    ...state,
    bonusGameShowed: false
  };

};

const tryShowPreBonusGame = (state) => {
  return {
    ...state,
    preBonusGameShowed: state.preBonusGameEnabled,
  };
};

const closePreBonusGame = (state) => {
  return {
    ...state,
    preBonusGameShowed: false
  };

};

const incrementBet = (state) => {
  const step = state.currentBetIndex + 1;
  if (step >= state.bets.length) {
    return state;
  }
  const newAmount = state.bets[step];
  const currentBonusInfo = state.bonuses[step];
  const preBonusGameRealProgress = currentBonusInfo?.hasOwnProperty('bonusProgress') ? currentBonusInfo.bonusProgress : null;

  const preBonusGameEnabled = state.bonuses && !!state.bonuses[step];
  const preBonusGameType = state.bonuses && state.bonuses[step]?.bonusType - 1 || -1;

  return {
    ...state,
    betAmount: newAmount,
    currentBetIndex: step,
    preBonusGameEnabled,
    preBonusGameType,
    preBonusGameProgress: currentBonusInfo?.bonusProgressVisual || 0,
    preBonusGameRealProgress,
    preBonusGameMaxProgress: state.preBonusGameMaxProgreses[preBonusGameType],
    preBonusGameShowed: preBonusGameEnabled,
  };
};

const decrementBet = (state) => {
  const step = state.currentBetIndex - 1;
  if (step < 0) {
    return state;
  }
  let newAmount = state.bets[step];

  const currentBonusInfo = state.bonuses[step];
  const preBonusGameRealProgress = currentBonusInfo?.hasOwnProperty('bonusProgress') ? currentBonusInfo.bonusProgress : null;

  const preBonusGameEnabled = state.bonuses && !!state.bonuses[step];
  const preBonusGameType = state.bonuses && state.bonuses[step]?.bonusType - 1 || -1;

  return {
    ...state,
    betAmount: newAmount,
    currentBetIndex: step,
    preBonusGameEnabled,
    preBonusGameType,
    preBonusGameProgress: currentBonusInfo?.bonusProgressVisual || 0,
    preBonusGameRealProgress,
    preBonusGameMaxProgress: state.preBonusGameMaxProgreses[preBonusGameType],
    preBonusGameShowed: preBonusGameEnabled
  };
};

const setMinBet = (state) => {
  const currentBonusInfo = state.bonuses[0];
  const preBonusGameRealProgress = currentBonusInfo?.hasOwnProperty('bonusProgress') ? currentBonusInfo.bonusProgress : null;

  const preBonusGameEnabled = !!currentBonusInfo;
  const preBonusGameType = currentBonusInfo?.bonusType - 1 || 1;

  return {
    ...state,
    betAmount: state.betMin,
    currentBetIndex: 0,
    preBonusGameEnabled,
    preBonusGameType,
    preBonusGameProgress: currentBonusInfo?.bonusProgressVisual || 0,
    preBonusGameRealProgress,
    preBonusGameMaxProgress: state.preBonusGameMaxProgreses[preBonusGameType],
    preBonusGameShowed: preBonusGameEnabled
  };
};

const setMaxBet = (state) => {
  const bets = state.bets;
  const index = Math.max(bets.length - 1, 0);
  const bet = bets[index] ? bets[index] : state.bets[0];

  const currentBonusInfo = state.bonuses[index];
  const preBonusGameRealProgress = currentBonusInfo?.hasOwnProperty('bonusProgress') ? currentBonusInfo.bonusProgress : null;

  const preBonusGameEnabled = !!currentBonusInfo;
  const preBonusGameType = currentBonusInfo?.bonusType - 1 || 1;

  return {
    ...state,
    betAmount: bet,
    currentBetIndex: index,
    preBonusGameEnabled,
    preBonusGameType,
    preBonusGameProgress: currentBonusInfo?.bonusProgressVisual || 0,
    preBonusGameRealProgress,
    preBonusGameMaxProgress: state.preBonusGameMaxProgreses[preBonusGameType],
    preBonusGameShowed: preBonusGameEnabled
  };
};

const setBet = (state, index) => {
  const currentBonusInfo = state.bonuses[index.payload];
  const preBonusGameRealProgress = currentBonusInfo?.hasOwnProperty('bonusProgress') ? currentBonusInfo.bonusProgress : null;

  const preBonusGameEnabled = !!currentBonusInfo;
  const preBonusGameType = currentBonusInfo?.bonusType - 1 || 1;

  return {
    ...state,
    currentBetIndex: index.payload,
    betAmount: state.bets[index.payload],
    preBonusGameEnabled,
    preBonusGameType,
    preBonusGameProgress: currentBonusInfo?.bonusProgressVisual || 0,
    preBonusGameRealProgress,
    preBonusGameMaxProgress: state.preBonusGameMaxProgreses[preBonusGameType],
    preBonusGameShowed: preBonusGameEnabled
  };
};

const setCurrencyInfo = (state, { payload }) => {
  //todo: ask Vova to send balance with floating point
  return ({
    ...state,
    currency: payload.currency.toUpperCase(),
    balance: payload.balance / Math.pow(10, payload.decimal || payload.decimals),
    currencyInfo: payload
  });
};

const convertToClientSymbols = (array) => {
  for (let i = 0; i < array.length; i++) {
    const reel = array[i];
    for (let j = 0; j < reel.length; j++) {
      reel[j] = serverClientSymbols[reel[j]];
    }
  }
};
// todo: только для тестов без бэка. Удалить.
const incrementBonusGamesProgress = (state) => {
  const bonusGameCrocodileProgress = state.bonusGameCrocodileProgress === 11 ? 0 : state.bonusGameCrocodileProgress + 1;
  return ({
    ...state,
    preBonusGameProgress: state.bonusProgress + 1,
    bonusGameCrocodileProgress
  });
};
const showBonusGameCrocodile = (state) => {
  return ({
    ...state,
    bonusGameCrocodileEnabled: !state.bonusGameCrocodileEnabled
  });
};

export default handleActions(
  {
    [actions.setData]: setData,
    [actions.setNewGame]: setNewGame,
    [actions.incrementBet]: incrementBet,
    [actions.decrementBet]: decrementBet,
    [actions.setMinBet]: setMinBet,
    [actions.setMaxBet]: setMaxBet,
    [actions.setBet]: setBet,
    [actions.setCurrencyInfo]: setCurrencyInfo,
    [actions.setGameState]: (state, { payload }) => ({ ...state, gameState: payload }),
    [actions.setBalance]: (state, { payload }) => ({ ...state, balance: payload }),
    [actions.startAutospin]: (state) => ({ ...state, autospin: true }),
    [actions.stopAutospin]: (state) => ({ ...state, autospin: false }),
    [actions.setFreespinMode]: (state, { payload }) => ({ ...state, freespinsMode: payload }),
    [actions.setCanSpin]: (state, { payload }) => ({ ...state, canSpin: payload }),
    [actions.setStoppingReel]: (state, { payload }) => ({ ...state, stopping_reel: payload }),
    [actions.setScreenMode]: (state, { payload }) => ({ ...state, gameScreen: payload }),
    [actions.setAnticipationReels]: (state, { payload }) => ({ ...state, anticipationReels: payload }),
    [actions.incrementBonusGamesProgress]: incrementBonusGamesProgress,
    [actions.showBonusGameCrocodile]: showBonusGameCrocodile,
    [actions.setBonusGame]: setBonusGame,
    [actions.tryShowBonusGame]: tryShowBonusGame,
    [actions.closeBonusGame]: closeBonusGame,
    [actions.tryShowPreBonusGame]: tryShowPreBonusGame,
    [actions.closePreBonusGame]: closePreBonusGame,

  },
  initialState,
);
