import FlashLib from 'flashlib';

export default class ControllerBackground extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }
  onResize = (data) => {
    if (PIXI.utils.isMobile.any && data.isLandscape) {
      this.x = this.displayData.x;
      this.y = this.displayData.y;
      this.height = this.displayData.height;
      this.width = this.displayData.width;
      this.scale.set(this.displayData.scaleX);
    } else if (PIXI.utils.isMobile.any) {
      this.x = this.displayData.x - 1800;
      this.y = this.displayData.y - 630;
      this.scale.set(2.166666666666667);
    }
  }
}
