import FlashLib from 'flashlib';
import { store, storeObserver } from '../..';
import selectors from '../../redux/game/selectors';
import AnimationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import serverClientSymbols from '../symbol/serverClientSymbols';
import ControllerAnimals from './controllerAnimals';
import eBonusGameAnimation from './eBonusGameAnimation';
import eSymbolType from '../symbol/eSymbolType';
import { eSoundType } from '../sounds/controllerSounds';
import ControllerSounds from '../sounds/controllerSounds';

export default class ControllerBonusGame extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.type = eSymbolType.EST_HIGH_1;
    this.eSymbolSoundType = {
      [eSymbolType.EST_HIGH_1]: eSoundType.EST_SFX_PROGRESS_GAME_CROCODILE,
      [eSymbolType.EST_HIGH_2]: eSoundType.EST_SFX_PROGRESS_GAME_COBRA,
      [eSymbolType.EST_HIGH_3]: eSoundType.EST_SFX_PROGRESS_GAME_SCORPION,
    };
    this.visible = false;
    this.showed = false;
    this.progressBar = this.getChildByName('progress');
    this.animal = new ControllerAnimals(this);
    this.initBackground();
    storeObserver.addListener(selectors.getBetAmount, this.onBetChange);
    storeObserver.addListener(selectors.getPreBonusGameShowed, this.onShowed);
    this.onShowed();
  }

  onShowed = (isShowed) => {
    const preBonusGameEnabled = selectors.getPreBonusGameEnabled(store.getState());
    if (preBonusGameEnabled && !this.showed ) {
      this.showed = preBonusGameEnabled;
      this.show();
    } else if ((!preBonusGameEnabled || !isShowed) && this.showed ) {
      this.showed = false;
      this.closeBonusGame();
    }
    this.animal.updateProgress();
  }

  updateProgress() {
    const showed = selectors.getPreBonusGameShowed(store.getState());
    if (showed) {
      this.animal.updateProgress(showed);
      this.progressBar.updateBarPosition();
    }
  }

  onBetChange = () => {
    const state = store.getState();
    const preBonusGameEnabled = selectors.getPreBonusGameEnabled(state);
    if (preBonusGameEnabled && this.showed) {
      this.show();
    } else if (!preBonusGameEnabled && this.showed){
      this.showed = preBonusGameEnabled;
      this.closeBonusGame();
    } else if (preBonusGameEnabled && !this.showed ) {
      this.show();
    }
    this.showed = preBonusGameEnabled;

  }

  initBackground = () => {
    this.animation = AnimationsLoader.createAnimation(eAnimationType.EAT_BONUS_GAME_BG);
    this.animation.x = this.displayData.width/2;
    this.animation.y = 543;
    this.animation.visible = false;
    this.animation.state.addListener({
      complete: (data) => {
        const name = data.animation.name;
        if (name === eBonusGameAnimation[`EBGA_BG_${this.type}_START`] && this.track.reverse === true) {
          this.hide();
        }
      }
    });
    // this.animation.stateData.defaultMix = 0.01;
    // this.animation.state.timeScale = 4;
    this.addChildAt(this.animation, 0);
  }

  show = () => {
    const state = store.getState();
    const type = serverClientSymbols[selectors.getPreBonusGameType(state)];
    if (this.type === type && this.isShowed || !type) {
      return;
    } else {
      this.type = type;
    }
    ControllerSounds.replaceSFX(this.eSymbolSoundType[this.type]);
    this.isShowed = true;
    this.animation.visible = true;
    this.track = this.animation.state.setAnimation(0, eBonusGameAnimation[`EBGA_BG_${this.type}_START`], false);
    const trackIdle = this.animation.state.addAnimation(0, eBonusGameAnimation[`EBGA_BG_${this.type}_IDLE`], true, 0);
    this.track.reverse = false;
    trackIdle.reverse = false;
    this.visible = true;
    this.animal.show();
    this.isStarted = true;
    // console.log(this.animation);
  }

  closeBonusGame = () => {
    console.log('closeBonusGame');
    this.animal.hide();
    // this.animation.showReverseAnimation(0, eBonusGameAnimation[`EBGA_BG_${this.type}_START`], false, this.hide);
    this.track = this.animation.state.setAnimation(0, eBonusGameAnimation[`EBGA_BG_${this.type}_START`], false);
    this.track.reverse = true;
    this.isShowed = false;
    ControllerSounds.replaceSFX(eSoundType.EST_SFX);
  }

  restartBonusGame = () => {
    console.log('restartBonusGame');
    this.animal.hide();
    // this.animation.showReverseAnimation(0, eBonusGameAnimation[`EBGA_BG_${this.type}_START`], false, this.show);
  }

  hide = () => {
    console.log('hide: bonusGame');
    this.animation.visible = false;
    this.visible = false;
  }

}

