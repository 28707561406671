import ModalBase from '../../base/modalBase';
import eModalType from '../eModalType';
import { winAmountCounterStyle } from '../../../fontStyles';
import { store } from '../../../index';
import WinAmountCounter from '../../winAmountCounter';
import actionsModals from '../../../redux/modals/actions';
import selectors from '../../../redux/game/selectors';
import { formatCurrency } from '../../../utils/math';
import TweenMax from 'gsap';
import animationsLoader from '../../animations/animationsLoader';
import eAnimationType from '../../animations/eAnimationType';
import ParticleSystem from '../../../utils/particles/particleSystem';
import particleConfigTotalWin from './particleConfigTotalWin';
import ControllerSounds, { eSoundType } from '../../sounds/controllerSounds';

export default class ModalTotalWin extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.type = eModalType.EMT_WIN_FREE_SPINS_END;
    this.normalScale = 1;
    this.container = this.getChildByName('container');
    this.container.pivot.x = this.container.displayData.width / 2;
    this.container.pivot.y = this.container.displayData.height / 2;
    this.container.x += this.container.displayData.width / 2;
    this.container.y += this.container.displayData.height / 2;
    this.amount = new PIXI.BitmapText('0', winAmountCounterStyle);
    this.amount.anchor.set(0.5);
    this.amount.x = this.container.displayData.width / 2 - 20;
    this.amount.y = this.container.displayData.height;
    this.container.addChild(this.amount);
    this.particleContainer = new PIXI.Container();
    this.container.addChildAt(this.particleContainer, 0);
    this.title = this.container.getChildByName('title');
    this.title.pivot.x = this.title.displayData.width / 2;
    this.title.pivot.y = this.title.displayData.height / 2;
    this.title.x = this.container.displayData.width / 2 - 20;
    this.title.scale.set(0);
    this.init();
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {
    if (PIXI.utils.isMobile.any && data.isLandscape) {
      this.container.pivot.x = this.container.displayData.width / 2;
      this.container.pivot.y = this.container.displayData.height / 2;
      this.container.x = this.container.displayData.x + this.container.displayData.width / 2;
      this.container.y = this.container.displayData.y + this.container.displayData.height / 2;
      this.normalScale = 1;
      this.container.scale.set(this.normalScale);

      this.overlay.x = this.overlay.displayData.x;
      this.overlay.y = this.overlay.displayData.y;
      this.overlay.scale.set(this.overlay.displayData.scaleX);
    } else if (PIXI.utils.isMobile.any) {
      const scaleGame = 0.45;

      this.container.pivot.x = this.container.displayData.width / 2;
      this.container.pivot.y = this.container.displayData.height / 2;

      this.container.x = this.displayData.width / 2;
      this.container.y = this.displayData.height / 2 - 50;
      this.normalScale = scaleGame * 1.1;
      this.container.scale.set(this.normalScale);

      this.overlay.x = this.overlay.displayData.x - 630 * 2.166666666666667;
      this.overlay.y = this.overlay.displayData.y + 630;
      this.overlay.scale.set(2.166666666666667);
    }
  }

  init() {
    this.overlay = this.getChildByName('blackout');
    this.initListener();
    this.showBlackout();
    // this.on('click', this.onClick);
    // document.addEventListener('keyup', event => this.onKeyDown(event), false);
    this.initAnimation();
  }

  destroy() {
    super.destroy();
    // this.off('click', this.onClick);
  }

  initAnimation() {
    this.animation = animationsLoader.createAnimation(eAnimationType.EST_MODAL_BIG_WINS);
    this.animation.x = this.container.displayData.width / 2;
    this.animation.y = this.container.displayData.height / 2;
    this.animation.visible = false;
    this.container.addChildAt(this.animation, 0);
  }

  setAmount(value) {
    this.winAmountCounter = new WinAmountCounter(this.getChildByName('winCounter'), winAmountCounterStyle);
    const currencyInfo = selectors.getCurrencyInfo(store.getState());
    const decimals = Math.min(value.getPrecision(), currencyInfo.decimal);
    this.winAmountCounter.animate(value, decimals);
    this.winAmountCounter.onStop = () => store.dispatch(actionsModals.closeModal({ type: this.type }));
  }

  showBlackout() {
    if (!this.overlay) return;
    this.overlay.show(true, 0.3);
  }

  // onClick() {
  //   this.stop();
  //   // this.winAmountCounter.stop();
  // }

  onModalDataChanged = (data) => {
    if (this.visible === data.visible) return;
    this.visible = data.visible;
    if (this.visible) this.show();
  };
  hide = () => {
    ControllerSounds.replaceSFX(eSoundType.EST_SFX);
    TweenMax.to(this.container.scale, 0.2, { x: 0, y: 0 });
    TweenMax.to(this, 0.2, { alpha: 0, onComplete: this.stop });
  }

  show = () => {
    this.container.scale.set(this.normalScale);
    this.alpha = 1;
    ControllerSounds.playBigWinSFX(0.4);
    this.showBlackout();
    this.startCounter();
  }
  startCounter = () => {
    const state = store.getState();
    this.totalWinAmount = selectors.getTotalWinAmount(state);
    const decimal = selectors.getCurrencyInfo(state).decimal;
    this.decimal = Math.min(this.totalWinAmount.getPrecision(), decimal);

    this.counterDuration = 3000;
    this.timeStartCounter = Date.now();
    this.winIsStarted = false;

    requestAnimationFrame(this.counterUpdate);
  }

  counterUpdate = () => {
    const time = Date.now() - this.timeStartCounter;

    if (time <= this.counterDuration) {
      const progress = time/this.counterDuration;
      this.drawFunction(progress);
      requestAnimationFrame(this.counterUpdate);

    } else {
      this.amount.text = formatCurrency(this.totalWinAmount, this.decimal);
      this.winAmount = null;
      this.timeStartCounter = null;
      TweenMax.killTweensOf(this.title.scale);
      TweenMax.to(this.title.scale, 0.4, { x: 1.11, y: 1.11 });
      setTimeout(this.hide, 3500);
      return;
    }
  }
  drawFunction = (progress) => {
    const amount = this.totalWinAmount * progress;
    this.amount.text = formatCurrency(amount, this.decimal);

    if (!this.winIsStarted) {
      this.winIsStarted = true;
      this.startAnimation();
    }
  }
  startAnimation = () => {
    TweenMax.killTweensOf(this.title.scale);
    this.startParticles();
    this.title.scale.set(0);
    const duration = 0.5;
    TweenMax.to(this.title.scale, duration, { x: 1, y: 1, onComplete: this.showIdleTitle });
    this.animation.visible = false;
    this.animation.visible = true;
    this.animation.state.setAnimation(0, 'total_win_start', false);
    this.animation.state.addAnimation(0, 'total_win_idle', true, 0);
  }

  showIdleTitle = () => {
    const duration = 0.6;
    const targetScale = this.title.scale.x === 1 ? 1.1 : 1;
    TweenMax.to(this.title.scale, duration, { x: targetScale, y: targetScale, ease: 'none' , onComplete: this.showIdleTitle });
  }

  startParticles = () => {
    this.coins0?.pause();
    this.coins1?.pause();
    this.coins0 = new ParticleSystem(this.particleContainer, { animationType: eAnimationType.EAT_COINS, animationName: 'coin' }, particleConfigTotalWin.leftFontan);
    this.coins1 = new ParticleSystem(this.particleContainer, { animationType: eAnimationType.EAT_COINS, animationName: 'coin' }, particleConfigTotalWin.rightFontan);
  }

  // onKeyDown(event) {
  //   if (event.keyCode === 32 && this.visible) { //32 - Space
  //     this.stop();
  //     this.winAmountCounter.stop();
  //   }
  // }

  stop = () => {
    this.coins0?.stop();
    this.coins1?.stop();
    if (this.visible)
      this.title.scale.set(0);
    this.animation.visible = false;
    store.dispatch(actionsModals.closeModal({ type: this.type }));
  }
}
