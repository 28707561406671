import FlashLib from 'flashlib';
import { TweenMax } from 'gsap';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import eBonusGameAnimation from './eBonusGameAnimation';
import { sleep } from '../../utils/sleep';
import constants from '../../constants';
export default class ControllerPreBonusGameProgressBar extends FlashLib.MovieClip {
  constructor(data, $displayItemData) {
    super(data, $displayItemData);
    this.barMaskArea = this.getChildByName('maskArea');
    this.barOffset = 10;
    this.barWidth = this.barMaskArea.width;
    this.alpha = 0;
    this.step = -1;

    this.initAnimations();
    this.setDefaultState();
    this.setProgress();
    const BonusGameShowed = storeObserver.addListenerAndGetValue(selectors.getPreBonusGameShowed, this.onShowed );
    storeObserver.addListener(selectors.getBetAmount, () => this.updateBarPosition.bind(this)(true));
    this.onShowed(BonusGameShowed);
    this.updateBarPosition();
  }

  initAnimations = () => {
    this.animationFrame = animationsLoader.createAnimation(eAnimationType.EAT_PRE_BONUS_GAME_BAR_PROGRESS);
    this.animationBar = animationsLoader.createAnimation(eAnimationType.EAT_PRE_BONUS_GAME_BAR_PROGRESS);
    this.animationBarLight = animationsLoader.createAnimation(eAnimationType.EAT_PRE_BONUS_GAME_BAR_PROGRESS);

    this.animationBar.mask = this.barMaskArea;

    const defaultX = 263;
    const defaultY = 62;

    this.animationFrame.x = defaultX;
    this.animationFrame.y = defaultY;
    this.animationBar.x = defaultX;
    this.animationBar.y = defaultY;
    this.animationBarLight.x = defaultX;
    this.animationBarLight.y = defaultY;

    this.barDefoltConfig ={
      x: this.animationBar.x - this.barWidth + this.barOffset,
      distance: this.barWidth - this.barOffset,
    };

    this.animationFrame.state.addListener({ complete: () => {
      this.animationFrame.state.setAnimation(0, eBonusGameAnimation.EBGA_FRAME_STATIC, true);
    } });
    this.animationBar.state.addListener({ complete: () => {
      this.animationBar.state.setAnimation(0, eBonusGameAnimation.EBGA_BAR_IDLE, true);
    } });
    this.animationBarLight.state.addListener({ complete: () => {
      this.animationBarLight.state.setAnimation(0, eBonusGameAnimation.EBGA_LIGHT_IDLE, true);
    } });

    this.addChildAt( this.animationFrame, 1);
    this.addChildAt(this.animationBar, 1);
    this.addChild(this.animationBarLight);

  }

  setDefaultState = () => {
    this.visible = false;

    this.animationFrame.state.setAnimation(0, eBonusGameAnimation.EBGA_FRAME_STATIC, true);
    this.animationBar.state.setAnimation(0, eBonusGameAnimation.EBGA_BAR_IDLE, true);
    this.animationBarLight.state.setAnimation(0, eBonusGameAnimation.EBGA_LIGHT_IDLE, true);
  }

  onShowed = (isShowed) => {
    isShowed ? this.show() : this.hide();
  }

  show = () => {
    this.updateBarPosition(true);
    this.visible = true;
    TweenMax.killTweensOf(this);
    TweenMax.to(this, 0.5, { alpha: 1 } );
  }

  hide = () => {
    TweenMax.killTweensOf(this);
    TweenMax.to(this, 0.5, { alpha: 0, onComplete: this.setDefaultState });
  }

  setProgress = (step) => {
    this.step = step;
    const maxStep = selectors.getPreBonusGameMaxProgress(store.getState());
    if (step > maxStep) this.step = maxStep;
    this.progress = this.step / maxStep;
    // this.updateBarPosition();
  }

  async updateBarPosition(isFastUpdate) {
    const state = store.getState();
    const step = selectors.getPreBonusGameRealProgress(state);
    if (!isFastUpdate) await sleep(constants.TIME_SLEEP_PRE_BONUS_GAME_PROGRESS);
    const preBonusGameShowed = selectors.getPreBonusGameShowed(store.getState());
    if (this.step === step || !preBonusGameShowed) return;
    this.setProgress(step);
    this.animationBar.state.setAnimation(0, eBonusGameAnimation.EBGA_BAR_STEP, false);
    this.animationFrame.state.setAnimation(0, eBonusGameAnimation.EBGA_FRAME_BLINK, false);
    this.animationBarLight.state.setAnimation(0, eBonusGameAnimation.EBGA_LIGHT_STEP, true);

    const xPosition = this.barDefoltConfig.x + this.barDefoltConfig.distance * this.progress;
    TweenMax.to(this.animationBar, 0.5, { x: xPosition });
    TweenMax.to(this.animationBarLight, 0.5, { x: xPosition });
  }

}
