import selectors from '../../../redux/game/selectors';
import { store } from '../../../index';
import actions from '../../../redux/game/actions';
import { eGameState } from '../../../redux/game/reducer';
import { sleep } from '../../../utils/sleep';
import actionsModals from '../../../redux/modals/actions';
import eModalType from '../../popups/eModalType';
import ControllerBehaviorBase from './controllerBehaviorBase';

export default class ControllerSlotNormalBehaviour extends ControllerBehaviorBase {

  constructor(slotController) {
    super(slotController);
    this._slotController = slotController;
  }

  endSpin() {
    const state = store.getState();
    const winLines = selectors.getWinLine(state);

    this._slotController.startWinAnimation(winLines, true).then(async (delay) => {
      if (state.game.autospin) await sleep(delay - 1000);

      // delay =
      this._slotController.vines.show(delay);
      this.checkShowWinPopup();
      this._slotController.preBonusGame.updateProgress();
      await this.checkShowBigWinPopup();
      await this.checkShowPopupStartPreBonusGame();
      this._slotController.freespinsPanel.hide();
      this._slotController.bonusGameScorpion.hide();
      store.dispatch(actions.closeBonusGame());

      this.updateFreeBets();
      store.dispatch(actions.setGameState(eGameState.EGS_READY_TO_PLAY));
    });
  }
  async checkShowPopupStartPreBonusGame() {
    const state = store.getState();
    const preBonusGameEnabled = selectors.getPreBonusGameEnabled(state);
    const preBonusGameShowed = selectors.getPreBonusGameShowed(state);

    console.log(preBonusGameEnabled, preBonusGameShowed);
    if (preBonusGameEnabled && !preBonusGameShowed) {
      store.dispatch(actionsModals.showModal({ type: eModalType.EMT_TRANSITION }));
      await sleep(4000);
    }
  }
}
