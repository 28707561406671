import FlashLib from 'flashlib';
import { store } from '../../index';

export default class JackpotPanelController extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
    // this.visible = window.OPWrapperService.config.jackpotEnabled;
    this.visible = false;
  }

  init() {
    const valueField = this.getChildByName('value');
    this.setupJackpotValue(valueField);

    store.subscribe(() => {
      this.setupJackpotValue(valueField);
    });
  }

  setupJackpotValue(field) {
    const state = store.getState().game;
    const jackpot = state.jackpot_prize;
    const currency = state.currencyInfo.symbol;
    field.text = `JACKPOT ${jackpot} ${currency}`;
  }
}
