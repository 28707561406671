import ModalBase from '../base/modalBase';
import eModalType from './eModalType';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import TweenMax from 'gsap';
import { store } from '../../index';
import actionsModals from '../../redux/modals/actions';
import actions from '../../redux/game/actions';
import selectors from '../../redux/game/selectors';

export default class PopupTransition extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.type = eModalType.EMT_TRANSITION;
    this.initListener();
    this.initBlocker();
    this.texts = [];

    this.container = this.getChildByName('container');
    this.textsBlockPreBonusGame = this.container.getChildByName('texts_pre_bonus_game');
    this.textsBlockBonusGame = this.container.getChildByName('texts_bonus_game');
    this.overlay = this.getChildByName('blackout');
    this.initAnimation();

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {
    if (PIXI.utils.isMobile.any && data.isLandscape) {
      this.container.pivot.x = 0;
      this.container.pivot.y = 0;

      this.container.x = this.container.displayData.x;
      this.container.y = this.container.displayData.y;
      this.container.scale.set(1);

      this.overlay.x = this.overlay.displayData.x;
      this.overlay.y = this.overlay.displayData.y;
      this.overlay.scale.set(this.overlay.displayData.scaleX);
    } else if (PIXI.utils.isMobile.any) {
      const scaleGame = 0.45;

      this.container.pivot.x = this.container.displayData.width / 2;
      this.container.pivot.y = this.container.displayData.height / 2;

      this.container.x = this.displayData.width / 2;
      this.container.y = this.displayData.height / 2 - 50;
      this.container.scale.set(scaleGame);

      this.overlay.x = this.overlay.displayData.x - 630 * 2.166666666666667;
      this.overlay.y = this.overlay.displayData.y + 630;
      this.overlay.scale.set(2.166666666666667);
    }
  }

  initAnimation = () => {
    this.animation = animationsLoader.createAnimation(eAnimationType.EAT_POPUP_START_BONUS_GAME);
    this.animation.x = this.container.displayData.width / 2;
    this.animation.y = this.container.displayData.height / 2;
    this.container.addChildAt(this.animation, 0);
  }

  onModalDataChanged = (data) => {
    if (this.visible === data.visible) return;
    this.visible = data.visible;
    this.alpha = 1;

    this.animation.visible = false;
    this.animation.visible = true;

    this.animation.state.setAnimation(0, 'pop_up_before', false);

    this.showBlackout();

    const bonusGameEnabled = selectors.getBonusGameEnabled(store.getState());
    bonusGameEnabled ? this.setBonusGameTexts() : this.setPreBonusGameTexts();

    this.showTextsAnimation();
    setTimeout(this.onCloseModal, 4000);
  }
  showBlackout() {
    if (!this.overlay) return;
    this.overlay.show(true, 0.3);
  }

  showTextsAnimation = () => {
    const duration = 1; //sec
    this.container.alpha = 0;
    TweenMax.to(this.container, duration, { alpha: 1 });
  }

  onCloseModal = () => {
    const duration = 1;
    TweenMax.to(this, duration, { alpha: 0, onComplete: this.hide });
  }

  setPreBonusGameTexts = () => {
    this.textsBlockPreBonusGame.visible = true;
    this.textsBlockBonusGame.visible = false;
  }
  setBonusGameTexts = () => {
    this.textsBlockPreBonusGame.visible = false;
    this.textsBlockBonusGame.visible = true;
  }

  hide = () => {
    this.visible = false;
    store.dispatch(actionsModals.closeModal({ type: this.type }));
    const bonusGameEnabled = selectors.getBonusGameEnabled(store.getState());
    if (!bonusGameEnabled) store.dispatch(actions.tryShowPreBonusGame());

  }
}
