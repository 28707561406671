import FlashLib from 'flashlib';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';

const eAnimationName = {
  IDLE: 'animation'
};

export default class TorchController extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this.animation = animationsLoader.createAnimation(eAnimationType.EST_TORCH);

    this.animation.state.addAnimation(0, eAnimationName.IDLE, true, 0);
    this.y -= 60;
    this.addChild(this.animation);
  }

}