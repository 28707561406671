export default class ReelAbstractState {

  constructor(reel) {
    this.reel = reel;
    this.animation = null;
  }

  init() {}
  spin() {}
  onComplete() {}

}