export const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

export const indexesOf = (arr, item) => {
  const indexes = [];

  for (let i = 0; i < arr.length; i++)
    if (arr[i] === item) indexes.push(i);

  return indexes;
};
