import { eGameState } from '../../redux/game/reducer';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import modalSelectors from '../../redux/modals/selectors';
import ControllerSounds from '../sounds/controllerSounds';
import eModalType from '../popups/eModalType';
import actions from '../../redux/game/actions';
import { requestNewGame } from '../../api/rest';
import actionsModal from '../../redux/modals/actions';
import { eScreenMode } from '../../enums';

export default class UiAdapter {
  constructor(ControllerUi) {
    this._controllerUi = ControllerUi;
    this._currentOrientation = this._controllerUi.currentOrientation;
    this._uiVisible = true;

    this.initStateHandlers();
    this.addListeners();
  }

  init(config) {
    this._initConfig = config || this._initConfig;
    this._bonusLabelText = this._initConfig.bonusLabelText;
    this._buttonClickSound = this._initConfig.clickSound;
    this._isFirstStateUpdate = true;

    const state = store.getState();
    this.syncAvailableBets();
    this.ControllerUi.setCurrentBetIndex(selectors.getBetIndex(state));
    this.ControllerUi.setBalance(selectors.getBalance(state));
    this.ControllerUi.setAutoPlayButtonActive(selectors.getAutospin(state));
    this.ControllerUi.setLastWin(selectors.getLastWinAmount(state));
    this.ControllerUi.setWin(selectors.getWinAmount(state));

    this.updateSoundMuted();

    this.baseOnStateChanged(selectors.getGameState(state));

    if (this._uiVisible) {
      this.showUi();
    } else {
      this.hideUi();
    }

    this.addActions();
  }

  addActions() {
    const controllerTypes = this.ControllerUi.controllerTypes;
    const events = this.ControllerUi.events;

    if (events[controllerTypes.ECT_BET]) {
      this.ControllerUi.on(events[controllerTypes.ECT_BET].INCREASE_BUTTON_CLICK, this.onIncreaseBetClick.bind(this));
      this.ControllerUi.on(events[controllerTypes.ECT_BET].DECREASE_BUTTON_CLICK, this.onDecreaseBetClick.bind(this));
    }
    if (events[controllerTypes.ECT_BET_SELECTOR_TABLE]) {
      this.ControllerUi.on(events[controllerTypes.ECT_BET_SELECTOR_TABLE].CHANGE_BET_CLICK, this.onChangeBetClick.bind(this));
    }
    if (events[controllerTypes.ECT_BET_SELECT]) {
      this.ControllerUi.on(events[controllerTypes.ECT_BET_SELECT].SHOW_BETS_CLICK, this.playClickSound.bind(this));
      this.ControllerUi.on(events[controllerTypes.ECT_BET_SELECT].HIDE_BETS_CLICK, this.playClickSound.bind(this));
    }
    if (events[controllerTypes.ECT_SPIN]) {
      this.ControllerUi.on(events[controllerTypes.ECT_SPIN].SPIN_CLICK, this.onSpinClick.bind(this));
      this.ControllerUi.on(events[controllerTypes.ECT_SPIN].STOP_CLICK, this.onStopClick.bind(this));
    }
    if (events[controllerTypes.ECT_AUTO_SPIN]) {
      this.ControllerUi.on(events[controllerTypes.ECT_AUTO_SPIN].AUTO_SPIN_CLICK, this.onAutoPlayClick.bind(this));
    }
    if (events[controllerTypes.ECT_MENU]) {
      this.ControllerUi.on(events[controllerTypes.ECT_MENU].BURGER_CLICK, this.onMenuClick.bind(this));
    }
    if (events[controllerTypes.ECT_SOUND]) {
      this.ControllerUi.on(events[controllerTypes.ECT_SOUND].SOUND_MUTE_CLICK, this.onSoundClick.bind(this));
      this.ControllerUi.on(events[controllerTypes.ECT_SOUND].SOUND_UNMUTE_CLICK, this.onSoundClick.bind(this));
    }
    if (events[controllerTypes.ECT_INFO]) {
      this.ControllerUi.on(events[controllerTypes.ECT_INFO].INFO_CLICK, this.onPaytableClick.bind(this));
    }

    if (events[controllerTypes.ECT_MAX_BET]) {
      this.ControllerUi.on(events[controllerTypes.ECT_MAX_BET].MAX_BET_CLICK, this.onMaxBetClick.bind(this));
    }
    if (events[controllerTypes.ECT_FULL_SCREEN]) {
      this.ControllerUi.on(events[controllerTypes.ECT_FULL_SCREEN].FULL_SCREEN_CLICK, this.playClickSound.bind(this));
      this.ControllerUi.on(events[controllerTypes.ECT_FULL_SCREEN].EXIT_FULL_SCREEN_CLICK, this.playClickSound.bind(this));
    }
  }

  addListeners() {
    storeObserver.addListenerAndGetValue(selectors.getGameState, this.baseOnStateChanged.bind(this));
    storeObserver.addListenerAndGetValue(selectors.getAutospin, this.onAutoPlaySettingsActiveChanged.bind(this));
    storeObserver.addListenerAndGetValue(selectors.getBetIndex, this.syncBet.bind(this));
    storeObserver.addListenerAndGetValue(selectors.getBets, this.syncAvailableBets.bind(this));
    storeObserver.addListenerAsNew(modalSelectors.getModalData(eModalType.EMT_PAYTABLE), this.onModalVisibilityChange.bind(this));
    storeObserver.addListenerAsNew(modalSelectors.getModalData(eModalType.EMT_TRANSITION), this.onModalVisibilityChange.bind(this));
    storeObserver.addListenerAsNew(modalSelectors.getModalData(eModalType.EMT_WIN_FREE_SPINS_END), this.onModalVisibilityChange.bind(this));
    storeObserver.addListenerAsNew(modalSelectors.getModalData(eModalType.EMT_WIN_BIG), this.onBigWinsVisibilityChange.bind(this));
    storeObserver.addListenerAsNew(modalSelectors.getModalData(eModalType.EMT_BONUS_PURCHASE), this.onModalVisibilityChange.bind(this));
    storeObserver.addListenerAsNew(modalSelectors.getModalData(eModalType.EMT_SETTINGS), this.onModalVisibilityChange.bind(this));
    ControllerSounds.onSoundSettingsCahnged.add(this.updateSoundMuted.bind(this));
    if (!OPWrapperService.config.disableSpinBySpace) window.addEventListener('keydown', this.onKeyDown.bind(this));
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onModalVisibilityChange(data) {
    if (data.visible) {
      this.hideUi();
    } else {
      this.showUi();
    }
  }

  onGameResized(data) {
    if (data.isMobile) {
      if (this._controllerUi.currentOrientation === this._currentOrientation) return;
      this._currentOrientation = this._controllerUi.currentOrientation;
      this.init();
    }
  }

  onKeyDown(event) {
    if (event.keyCode === 32 && this._uiVisible) {
      if (this.ControllerUi.spinButtonEnabled) {
        return this.onSpinClick();
      }
      if (this.ControllerUi.stopButtonEnabled) {
        return this.onStopClick();
      }
    }
  }

  showUi() {
    this._uiVisible = true;
    this.ControllerUi.show();
  }

  hideUi() {
    this._uiVisible = false;
    this.ControllerUi.hide();
  }

  onBigWinsVisibilityChange({ visible }) {
    const state = store.getState();
    if (visible) {
      if (selectors.getAutospin(state)) {
        this.ControllerUi.setOpacity(0.5);
      } else {
        this.hideUi();
      }
    } else {
      this.ControllerUi.setOpacity(1);
      this.showUi();
    }
  }

  syncBet(betIndex) {
    this.ControllerUi.setCurrentBetIndex(betIndex);
  }

  syncAvailableBets() {
    const state = store.getState();
    const bets = selectors.getBets(state);
    this.ControllerUi.setAvailableBets(bets);
  }

  updateBalance(value) {
    if (value === undefined) {
      const state = store.getState();
      value = selectors.getBalance(state);
    }
    this.ControllerUi.setBalance(value);
  }

  updateSoundMuted() {
    let soundMuted = Boolean(ControllerSounds.soundsMuted) && Boolean(ControllerSounds.musicMuted);
    this.ControllerUi.setSoundMuted(soundMuted);
  }

  onSoundClick(muted) {
    ControllerSounds.setSoundsMuted(muted);
    if (muted) {
      ControllerSounds.muteSFX();
    } else {
      ControllerSounds.unmuteSFX();
    }
  }

  onAutoPlayClick() {
    this.playClickSound();
    const state = store.getState();
    if (selectors.getGameState(state) === eGameState.EGS_READY_TO_PLAY &&
      selectors.getBetAmount(state) > selectors.getBalance(state)
      // && !EntryPoint.GameModel.isSpinWithoutBalanceCheck
    ) {
      window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
      return;
    }
    store.dispatch(actions.startAutospin());
    store.dispatch(actions.setGameState(eGameState.EGS_START_AUTOSPIN));
  }

  onStopClick() {
    this.playClickSound();
    const state = store.getState();
    if (!selectors.getAutospin(state)) {
      this.ControllerUi.toggleStopButton(false);
    } else {
      this.ControllerUi.setAutoPlayButtonActive(false);
    }
    store.dispatch(actions.stopAutospin());
  }

  onAutoPlaySettingsActiveChanged(active) {
    this.ControllerUi.toggleStopButton(active);
  }

  onSpinClick() {
    if (window.OPWrapperService.model.data.isSpinBlocked) return;

    this.playClickSound();
    const state = store.getState();
    if (selectors.getGameState(state) === eGameState.EGS_READY_TO_PLAY &&
      selectors.getBetAmount(state) > selectors.getBalance(state)
      // && !EntryPoint.GameModel.isSpinWithoutBalanceCheck
    ) {
      window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
      return;
    }

    if (window.OPWrapperService.sideBarVisible) {
      window.OPWrapperService.sideBar.hide();
    }
    store.dispatch(actions.setGameState(eGameState.EGS_WAITING_FOR_RESPONSE));
    requestNewGame();
  }

  onMaxBetClick() {
    this.playClickSound();
    store.dispatch(actions.setMaxBet());
  }

  onIncreaseBetClick() {
    this.playClickSound();
    store.dispatch(actions.incrementBet());
  }

  onDecreaseBetClick() {
    this.playClickSound();
    store.dispatch(actions.decrementBet());
  }

  onChangeBetClick(index) {
    this.playClickSound();
    store.dispatch(actions.setBet(index));
  }

  onMenuClick() {
    this.playClickSound();
    window.OPWrapperService.toggleSidebar();
  }

  onPaytableClick() {
    this.playClickSound();
    store.dispatch(actionsModal.showModal({ type: eModalType.EMT_PAYTABLE }));
    this.hideUi();
  }

  playClickSound() {
    if (this._buttonClickSound) {
      ControllerSounds.playSound(this._buttonClickSound.soundName);
    }
  }

  baseOnStateChanged(gameState) {
    this.onStateChanged(gameState);
    const state = store.getState();
    if (OPWrapperService.config.skipBlocked && !selectors.getAutospin(state) && ![eGameState.EGS_READY_TO_PLAY].includes(gameState)) {
      this.ControllerUi.showStopButton();
      this.ControllerUi.toggleStopButton(false);
    }
  }

  onStateChanged(state) {
    console.log('state:', state);
    if (this.stateHandlers.hasOwnProperty(state)) {
      this.stateHandlers[state]();
    } else {
      this.stateHandlers.default();
    }

    if (this.extraStateHandlers.hasOwnProperty(state)) {
      this.extraStateHandlers[state]();
    } else {
      this.extraStateHandlers.default();
    }

    this.handleExtras();
  }

  initStateHandlers() {
    this.stateHandlers = {
      [eGameState.EGS_READY_TO_PLAY]: () => {
        const state = store.getState();
        const freespinsEnabled = (selectors.getBonusGameEnabled(state) && selectors.getFreespinsCount(state) !== 0 && selectors.getBonusGameCobraLives(state) !== 0);
        const autospin = selectors.getAutospin(state);
        if (this._isFirstStateUpdate || !autospin && !freespinsEnabled) {
          this.ControllerUi.showSpinButton();
        } else {
          this.ControllerUi.showStopButton();
        }
        this.ControllerUi.toggleStopButton(false);
        this.ControllerUi.toggleSpinButton(this._isFirstStateUpdate || !autospin && !freespinsEnabled);

        this.ControllerUi.toggleAutoPlayButton(!autospin && !freespinsEnabled);
        this.ControllerUi.toggleMenuButton(this._isFirstStateUpdate || !autospin && !freespinsEnabled);
        this.ControllerUi.toggleInfoButton(this._isFirstStateUpdate || !autospin && !freespinsEnabled);
        this.ControllerUi.toggleBetSelectors(!autospin && !freespinsEnabled);
        this.ControllerUi.toggleMaxBetButton(!autospin && !freespinsEnabled);
        this.ControllerUi.toggleTournamentsLabel(this._isFirstStateUpdate || !autospin && !freespinsEnabled);
        this.ControllerUi.setBalance(selectors.getBalance(state));
        this.ControllerUi.setWin(selectors.getWinAmount(state));
        this._isFirstStateUpdate = false;
      },
      [eGameState.EGS_WAITING_FOR_RESPONSE]: () => {
        this.ControllerUi.closePopups();
        const state = store.getState();
        const freespinsEnabled = (selectors.getBonusGameEnabled(state) && selectors.getFreespinsCount(state) !== 0 && selectors.getBonusGameCobraLives(state) !== 0);
        this.ControllerUi.showStopButton();
        this.ControllerUi.toggleStopButton(false);
        this.ControllerUi.toggleSpinButton(false);

        this.ControllerUi.toggleAutoPlayButton(false);
        this.ControllerUi.toggleMenuButton(false);
        this.ControllerUi.toggleInfoButton(false);
        this.ControllerUi.toggleMaxBetButton(false);
        this.ControllerUi.toggleBetSelectors(false);
        this.ControllerUi.toggleTournamentsLabel(false);
        this.ControllerUi.setWin(0);
        if (!OPWrapperService.freeBetsController.isActive && !freespinsEnabled) {
          this.ControllerUi.setBalance(selectors.getBalance(state) - selectors.getBetAmount(state));
        }
      },
      [eGameState.EGS_CAN_STOP]: () => {
        this.ControllerUi.toggleStopButton(!OPWrapperService.config.roundDuration);
        this.ControllerUi.toggleSpinButton(false);

        this.ControllerUi.toggleAutoPlayButton(false);
        this.ControllerUi.toggleMenuButton(false);

        this.ControllerUi.toggleMaxBetButton(false);
        this.ControllerUi.toggleBetSelectors(false);
        this.ControllerUi.toggleInfoButton(false);
        this.ControllerUi.toggleTournamentsLabel(false);
      },
      default: () => {
        this.ControllerUi.showStopButton();
        this.ControllerUi.toggleStopButton(false);
        this.ControllerUi.toggleSpinButton(false);
        this.ControllerUi.toggleAutoPlayButton(false);
        this.ControllerUi.toggleMenuButton(false);
        this.ControllerUi.toggleMaxBetButton(false);
        this.ControllerUi.toggleBetSelectors(false);
        this.ControllerUi.toggleInfoButton(false);
        this.ControllerUi.toggleTournamentsLabel(false);
      },
    };

    if (!this.extraStateHandlers) {
      this.extraStateHandlers = {
        default: () => {}
      };
    }
  }

  handleExtras() {
    const state = store.getState();
    const autospin = selectors.getAutospin(state);
    this.ControllerUi.setAutoPlayButtonActive(autospin);

    if (autospin) {
      this.ControllerUi.showStopButton();
      this.ControllerUi.toggleStopButton(true);
      this.ControllerUi.toggleMaxBetButton(false);
      this.ControllerUi.toggleBetSelectors(false);
    }

    if (OPWrapperService.freeBetsController.isActive) {
      this.ControllerUi.toggleMaxBetButton(false);
      this.ControllerUi.toggleBetSelectors(false);
    }
  }

  get ControllerUi() {
    return this._controllerUi.hud;
  }
}
