import eSymbolType from './eSymbolType';

const symbolAnimationKeys = {
  low_purple: 'low_purple',
  low_green: 'low_green',
  low_blue: 'low_blue',
  low_orange: 'low_orange',
  high_1: 'animation',
  high_3: 'animation',
  high_2: 'animation',
  scatter: 'animation',
  wild: 'wild_idle',
  [`${eSymbolType.EST_WILD}_START`]: 'wild_start',
  [`${eSymbolType.EST_WILD}_SHOW`]: 'wild_start_freespins',

  [`${eSymbolType.EST_JUG_EMPTY}_START`]: 'jug_empty_start',
  [`${eSymbolType.EST_JUG_GOLD}_START`]: 'jug_gold_start',
  [eSymbolType.EST_JUG_GOLD]: 'jug_gold_idle',
  [`${eSymbolType.EST_JUG_COBRA}_START`]: 'jug_cobra_start',
  [eSymbolType.EST_JUG_COBRA]: 'jug_cobra_idle',

};

export default symbolAnimationKeys;
