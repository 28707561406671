import FlashLib from 'flashlib';
import { store, storeObserver } from '..';
import selectors from '../redux/game/selectors';
import { TweenMax } from 'gsap';

export default class ControllerLogo extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.visible = false;

    const preBonusGameShowed = storeObserver.addListenerAndGetValue(selectors.getPreBonusGameShowed, this.onShowed);
    const bonusGameShowed = storeObserver.addListenerAndGetValue(selectors.getBonusGameShowed, this.onShowed );
    const isHide = bonusGameShowed || preBonusGameShowed;
    this.alpha = isHide ? 0 : 1;
    isHide ? this.hide() : this.show();

  }

  onShowed = () => {
    const state = store.getState();
    const bonusGameShowed = selectors.getBonusGameShowed(state);
    const preBonusGameShowed = selectors.getPreBonusGameShowed(state);
    const isHide = bonusGameShowed || preBonusGameShowed;
    isHide ? this.hide() : this.show();
  }

  show = () => {
    this.visible = true;
    TweenMax.killTweensOf(this);
    TweenMax.to(this, 2, { alpha: 1 });
  }

  hide = () => {
    TweenMax.killTweensOf(this);
    TweenMax.to(this, 0.5, { alpha: 0, onComplete: () => this.visible = false });
  }

}
