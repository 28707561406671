import { store, storeObserver } from '../../index';

import actionsModals from '../../redux/modals/actions';
import eModalType from '../popups/eModalType';
import selectors from '../../redux/game/selectors';
import { TweenMax } from 'gsap';
import { eGameState } from '../../redux/game/reducer';
import FlashLib from 'flashlib';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import constants from '../../constants';

export default class ControllerBonusButton extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);
    this.visible = false;

    if (!OPWrapperService.config.disableBuyBonus) {
      this._init();
    }

  }

  _init() {
    this.button = this.getChildByName('btn');
    this.button.onClick = this.onClickButton;
    this.initAnimation();
    this.show();
    this.bonusGameEnabled = storeObserver.addListenerAndGetValue(selectors.getBonusGameShowed, this.onBonusGameShowed);
    const gameState = storeObserver.addListenerAndGetValue(selectors.getGameState, this.onGameStateChanged);
    this.onGameStateChanged(gameState);
    this.onBonusGameShowed(this.bonusGameEnabled);

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {

    const widthGame = constants.GAME_WIDTH;

    if (PIXI.utils.isMobile.any && data.isLandscape) {
      this.y = this.displayData.y;
      this.x = this.displayData.x;
      this.scale.set(1);
    } else if (PIXI.utils.isMobile.any) {
      this.y = 1280;
      this.x = widthGame / 2 - 470;
      this.scale.set(0.6 * 1.8);
    }
  }

  onBonusGameShowed = (value) => {
    this.bonusGameEnabled = value;
    this.bonusGameEnabled ? this.hide() : this.show();
  }

  onGameStateChanged = (gameState) => {
    const state = store.getState().game;
    this.setButtonsEnable(
      gameState === eGameState.EGS_READY_TO_PLAY
      && !state.autospin
      && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet
    );
  };

  initAnimation = () => {
    this.animation = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_BUTTON);
    this.animation.x = this.displayData.width / 2;
    this.animation.y = this.displayData.height / 2;
    this.animation.state.setAnimation(0, 'animation', false);

    this.addChild(this.animation);
  }

  setButtonsEnable = (value) => {
    this.button.enabled = value;
    this.button.enabled = value;
  };

  show = () => {
    if (this.visible) return;
    this.timeout = setInterval(this.trySetAnimation, 20000);
    this.visible = true;
    TweenMax.to(this, 2, { alpha: 1 });
  }
  hide = () => {
    if (!this.visible) return;
    clearTimeout(this.timeout);
    TweenMax.to(this, 0.5, { alpha: 0, onComplete: () => this.visible = false });
  }

  trySetAnimation = () => {
    if (this.button.interactive){
      this.animation.state.setAnimation(0, 'animation', false);
    }
  }

  onClickButton() {
    store.dispatch(actionsModals.showModal({ type: eModalType.EMT_BONUS_PURCHASE }));
  }
}
