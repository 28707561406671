import selectors from '../../../redux/game/selectors';
import { store } from '../../../index';
import actions from '../../../redux/game/actions';
import { eGameState } from '../../../redux/game/reducer';
import { sleep } from '../../../utils/sleep';
import ControllerBehaviorBase from './controllerBehaviorBase';
import actionsModals from '../../../redux/modals/actions';
import eModalType from '../../popups/eModalType';

export default class ControllerSlotFreespinsBehaviour extends ControllerBehaviorBase {

  constructor(slotController) {
    super(slotController);
    this._slotController = slotController;
  }

  endSpin() {
    const state = store.getState();
    const winLines = selectors.getWinLine(state);
    const bonusGameType = selectors.getBonusGameType(state);

    this._slotController.startWinAnimation(winLines, true).then(async dalay => {
      if (bonusGameType !== 2) {
        this.checkShowWinPopup();
      }

      await sleep((dalay - 1000));

      this._slotController.freespinsPanel.tryUpdateHearts();

      if (bonusGameType === 2) {
        await this.checkShowWinPopup();
        await sleep(200);
      }

      await this.checkShowBigWinPopup();
      this._slotController.freespinsPanel.setValue();
      this._slotController.freespinsPanel.update();

      await this.checkShowFreespinsPopup();
      this.updateFreeBets();
      store.dispatch(actions.setGameState(eGameState.EGS_READY_TO_PLAY));
    });
  }

  async checkShowFreespinsPopup() {
    const state = store.getState();
    const bonusGameType = selectors.getBonusGameType(state);
    const newFreespinsCount = selectors.getNewFreespinsCount(state);
    if ((bonusGameType === 3 || bonusGameType === 1) && newFreespinsCount > 0) {
      store.dispatch(actionsModals.showModal({ type: eModalType.EMT_WIN_FREE_SPINS }));
      await sleep(3000);
    }
  }
}
