export default {
  REELS_COUNT: 7,
  ELEMENTS_COUNT: 12,
  MULTIPLIER_BIG_WIN: 10,
  MULTIPLIER_MEGA_WIN: 20,
  MULTIPLIER_EPIC_WIN: 30,
  MIN_BET_FOR_BIG_WIN_POPUPS: 10,
  GAME_WIDTH: 2340,
  GAME_HEIGHT: 1080,
  TIME_SLEEP_PRE_BONUS_GAME_PROGRESS: 1200
};
