import eModalType from './eModalType';
import ModalBase from '../base/modalBase';
import ControllerSounds from '../sounds/controllerSounds';

export default class ModalSettings extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.type = eModalType.EMT_SETTINGS;

    this.initListener();
    this.initBlocker();
    this.getChildByName('buttonClose').addListener('pointertap', this.onCloseClicked);

    this.background = this.children[0];
    this.window = this.getChildByName('window');
    this.switchSounds = this.window.getChildByName('switchSounds');
    this.switchSounds.addEventListenerSwitched(this.onSoundsSwitched);
    this.switchSounds.setEnabled(!ControllerSounds.soundsMuted);

    this.switchMusic = this.window.getChildByName('switchMusic');
    this.switchMusic.addEventListenerSwitched(this.onMusicSwitched);
    this.switchMusic.setEnabled(!ControllerSounds.musicMuted);

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {
    if (PIXI.utils.isMobile.any && data.isLandscape) {
      this.window.pivot.x = 0;
      this.window.pivot.y = 0;

      this.window.x = this.window.displayData.x;
      this.window.y = this.window.displayData.y;

      this.window.scale.set(1);

      this.background.x = this.background.displayData.x;
      this.background.y = this.background.displayData.y;
      this.background.scale.set(this.background.displayData.scaleX);
    } else if (PIXI.utils.isMobile.any) {
      const scaleGame = 0.45;
      this.window.pivot.x = this.window.displayData.width / 2;
      this.window.pivot.y = this.window.displayData.height / 2;

      this.window.x = this.displayData.width / 2;
      this.window.y = this.displayData.height / 2;

      this.window.scale.set(scaleGame * 2);

      this.background.x = this.background.displayData.x - 1800;
      this.background.y = this.background.displayData.y - 630;
      this.background.scale.set(2.166666666666667);
    }
  };

  onModalDataChanged = (data) => {
    this.visible = data.visible;

    this.switchSounds.setEnabled(!ControllerSounds.soundsMuted);
    this.switchMusic.setEnabled(!ControllerSounds.musicMuted);
  };

  onSoundsSwitched = (value) => {
    ControllerSounds.setSoundsMuted(!value);
  };

  onMusicSwitched = () => {
    if (ControllerSounds.musicMuted) {
      ControllerSounds.unmuteSFX();
    } else {
      ControllerSounds.muteSFX();
    }
  };
}
