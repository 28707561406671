import eSymbolType from '../symbol/eSymbolType';

export default {
  [`EBGA_${eSymbolType.EST_HIGH_1}_IDLE`]: 'crocodile_idle',
  [`EBGA_${eSymbolType.EST_HIGH_1}_MOVING`]: 'crocodile_move',

  [`EBGA_${eSymbolType.EST_HIGH_3}_IDLE`]: 'scorpion_idle',
  [`EBGA_${eSymbolType.EST_HIGH_3}_MOVING`]: 'scorpion_move',

  [`EBGA_${eSymbolType.EST_HIGH_2}_IDLE`]: 'cobra_idle',
  [`EBGA_${eSymbolType.EST_HIGH_2}_MOVING`]: 'cobra_move',

  [`EBGA_BG_${eSymbolType.EST_HIGH_1}_IDLE`]: 'idle_bg_crocodile',
  [`EBGA_BG_${eSymbolType.EST_HIGH_1}_START`]: 'start_bg_crocodile',

  [`EBGA_BG_${eSymbolType.EST_HIGH_3}_IDLE`]: 'idle_bg_scorpion',
  [`EBGA_BG_${eSymbolType.EST_HIGH_3}_START`]: 'start_bg_scorpion',

  [`EBGA_BG_${eSymbolType.EST_HIGH_2}_IDLE`]: 'idle_bg_cobra',
  [`EBGA_BG_${eSymbolType.EST_HIGH_2}_START`]: 'start_bg_cobra',
  EBGA_FRAME_STATIC: 'step_frame_static',
  EBGA_FRAME_BLINK: 'step_frame',
  EBGA_BAR_IDLE: 'idle_progress',
  EBGA_BAR_STEP: 'step_progress',
  EBGA_LIGHT_IDLE: 'idle_light_top',
  EBGA_LIGHT_STEP: 'step_light_top',
};
