import FlashLib from 'flashlib';
import eSymbolType from './eSymbolType';
import eAnimationType from '../animations/eAnimationType';
import AnimationLoader from '../animations/animationsLoader';
import symbolAnimationKeys from './symbolAnimationsKeys';
import selectors from '../../redux/game/selectors';
import { storeObserver } from '../../index';
import { eGameState } from '../../redux/game/reducer';

const highAndSpecSymbols = [
  eSymbolType.EST_HIGH_3,
  eSymbolType.EST_HIGH_2,
  eSymbolType.EST_HIGH_1,
  eSymbolType.EST_WILD
];

export default class ControllerSymbol extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);

    this.type = eSymbolType.EST_HIGH_3;
    this.symbols = {};
    this.animations = {};
    this.animationType = '';
    this.animationLow = null;
    this.isFirstRunAnimation = true;
    this.needChangeToWihld = false;
    this.animationType = eAnimationType.EST_WILD;

    this.config = {
      [eSymbolType.EST_HIGH_3]: { x:0, y:1 },
      [eSymbolType.EST_HIGH_2]: { x:0, y:0 },
      [eSymbolType.EST_HIGH_1]: { x:0.5, y:0.5 },
      [eSymbolType.EST_WILD]: { x:-2.5, y: 0.5 },
    };
    storeObserver.addListener(selectors.getGameState, this.onGameStateChanged);

    this.init();
  }

  init() {
    this._setupSymbols();
    this._setupHighAnimations();
    this._setupLowAnimations();
    this._setupJugAnimations();
  }

  onGameStateChanged = (state) => {
    if (state === eGameState.EGS_WAITING_FOR_RESPONSE) {
      this.isFirstRunAnimation = true;
      this.animations[this.animationType].visible = false;
      this.symbols[this.type].visible = true;
      this.needChangeToWihld = false;
    }
  }

  setSymbol(type) {
    if (this.animations[this.type]) {
      const animation = this.animations[this.type];
      animation.state.clearTracks();
      animation.visible = false;
    }

    this.type = type;

    for (let key in this.symbols) {
      this.symbols[key].visible = key === type;
    }
    this.symbols[this.type].goToFrame(1);
  }

  animateSymbol() {

    this.animationType = this.type.includes('low') ? 'low' : this.type.includes('jug') ? 'jug' : this.type;
    const symbol = this.animations[this.animationType];
    symbol.visible = true;

    if (this.type === eSymbolType.EST_WILD) this.symbols[this.type].goToFrame(2);

    if (this.animationType === 'jug' && this.isFirstRunAnimation) {
      this.symbols[this.type].goToFrame(2);
      this.symbols[this.type].visible = false;
      symbol.state.setAnimation('0', `${symbolAnimationKeys[`${this.type}_START`]}`, false);
      if (this.type !== eSymbolType.EST_JUG_EMPTY) {
        symbol.state.addAnimation('0', symbolAnimationKeys[this.type], false, 0);
      }
      this.isFirstRunAnimation = false;

    } else if (this.animationType === 'jug') {
      if (this.type === eSymbolType.EST_JUG_EMPTY) return;
      this.symbols[this.type].visible = false;
      symbol.state.setAnimation('0', symbolAnimationKeys[this.type], false);

    } else if (this.needChangeToWihld && this.type === eSymbolType.EST_WILD && this.isFirstRunAnimation) {
      this.symbols[this.type].visible = false;
      symbol.state.setAnimation('0', `${symbolAnimationKeys[`${this.type}_SHOW`]}`, false);
      symbol.state.addAnimation('0', symbolAnimationKeys[this.type], false, 0);
      this.isFirstRunAnimation = false;

    } else if ( this.type === eSymbolType.EST_WILD && this.isFirstRunAnimation) {
      symbol.state.setAnimation('0', `${symbolAnimationKeys[`${this.type}_START`]}`, false);
      symbol.state.addAnimation('0', symbolAnimationKeys[this.type], false, 0);
      this.isFirstRunAnimation = false;

    } else {
      this.symbols[this.type].visible = false;
      symbol.state.setAnimation(
        0,
        symbolAnimationKeys[this.type],
        false);
    }
  }

  _setupSymbols() {
    for (let key in eSymbolType) {
      const item = this.getChildByName(eSymbolType[key]);
      this.symbols[eSymbolType[key]] = item;
    }
  }

  _setupHighAnimations() {
    for (let i = 0; i < highAndSpecSymbols.length; i++) {
      const type = highAndSpecSymbols[i];
      const animation = AnimationLoader.createAnimation(type);
      animation.x = this.config[type].x;
      animation.y = this.config[type].y;

      animation.visible = false;
      animation.state.addListener({
        complete: () => {
          setTimeout(()=> {
            animation.visible = false;
          },1);
          this.symbols[this.type].visible = true;
        }
      });
      this.addChild(animation);
      this.animations[type] = animation;
    }
  }

  _setupLowAnimations() {
    const animation = AnimationLoader.createAnimation(eAnimationType.EAT_LOW_ICONS);
    animation.x = (this.width / 2) - 40;
    animation.y = (this.height / 2) - 75;
    animation.visible = false;

    animation.state.addListener({
      complete: () => {
        animation.visible = false;
        this.symbols[this.type].visible = true;
      }
    });

    this.animations['low'] = animation;
    this.addChild(animation);
  }
  _setupJugAnimations() {
    const animation = AnimationLoader.createAnimation(eAnimationType.EAT_JUGS);
    animation.x = (this.width / 2) - 54.5;
    animation.y = (this.height / 2) - 71;
    animation.visible = false;

    animation.state.addListener({
      complete: () => {
        setTimeout(()=>animation.visible = false, 1);
        this.symbols[this.type].visible = true;
      }
    });

    this.animations['jug'] = animation;
    this.addChild(animation);
  }
}

