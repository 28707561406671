const defaultCondig = {
  x0min: 500, // px
  x0max: 650, // px
  y0min: 275, // px
  y0max: 275, // px
  scaleStart: 0.5,
  scaleEnd: 1,
  angleVelocityMin: 0.005,
  angleVelocityMax: 0.006,
  gravityX: 0,
  gravityY: 1.2,
  mass: 0.06, // ~kg
  drag: 1 / 90,
  lifetimeMin: 2000, // milliseconds
  lifetimeMax: 4000, // milliseconds
  box: {
    x: 0,
    y: 0,
    height: 1000
  },
  leftBound: false,
  topBound: false,
  rightBound:false,
  bottomBound: false,
};
const bigWinConfig = {
  angle0From: -2.0944,
  angle0To: -1.0472,
  v0min: 1.85, // px / milliseconds
  v0max: 2.3, // px / milliseconds
  count: 28
};
const megaWinConfig = {
  angle0From: -Math.PI / 180 * 130,
  angle0To: -Math.PI / 180 * 50,
  v0min: 2, // px / milliseconds
  v0max: 2.5, // px / milliseconds
  count: 60
};
const epicWinConfig = {
  angle0From: -Math.PI / 180 * 140,
  angle0To: -Math.PI / 180 * 40,
  v0min: 2.15, // px / milliseconds
  v0max: 2.78, // px / milliseconds
  count: 120
};

export default {
  defaultCondig,
  bigWinConfig,
  megaWinConfig,
  epicWinConfig
};
