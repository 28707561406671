import FlashLib from 'flashlib';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import Event from '../../utils/event';
import { storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';

const eAnimationName = {
  EAN_CUT: 'cut',
  EAN_GROW: 'grow',
};

export default class ControllerVines extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.isHide = false;

    this.onAnimationHideStart = new Event();
    this.onAnimationShowStart = new Event();
    this.onAnimationShowComplete = new Event();

    this.animation = animationsLoader.createAnimation(eAnimationType.EST_VINES);
    this.animation.state.addAnimation(0, eAnimationName.EAN_GROW, false, 0);
    this.animation.state.addListener({
      start: this._onAnimationStart,
      complete: this._onAnimationComplete,
    });
    this.animation.x += 280;
    this.animation.y += 173;
    this.getChildAt(0).visible = false;
    this.addChild(this.animation);
    storeObserver.addListener(selectors.getBonusGameShowed, this.onShowed );

  }

  onShowed = (isShowed) => {
    isShowed ? this.hide() : this.show();
  }

  show(delay) {
    if (!this.isHide) return delay;
    this.isHide = false;
    this.animation.state.clearTracks();

    //todo: this is hack
    setTimeout(() => {
      this.animation.skeleton.setToSetupPose();
      const track = this.animation.state.addAnimation(0, eAnimationName.EAN_GROW, false, 0);
      track.timeScale = 2;
      const duration = this.animation.state.tracks[0].animation.duration;
      return delay + duration * 1000;
    }, delay);
  }

  hide(delay) {
    if (this.isHide) return delay;
    this.isHide = true;

    this.animation.state.clearTracks();
    this.animation.skeleton.setToSetupPose();
    this.animation.state.addAnimation(0, eAnimationName.EAN_CUT, false, 0);

    const duration = this.animation.state.tracks[0].animation.duration;
    return delay + duration * 1000;
  }

  _onAnimationStart = (e) => {
    if (e.animation.name === eAnimationName.EAN_CUT) {
      console.log('onAnimationHideStart');
      this.onAnimationHideStart.call();
    } else if (e.animation.name === eAnimationName.EAN_GROW) {
      console.log('onAnimationShowComplete');
      this.onAnimationShowStart.call();
    }
  };

  _onAnimationComplete = (e) => {
    if (e.animation.name === eAnimationName.EAN_GROW) {
      console.log('onAnimationShowComplete');
      this.onAnimationShowComplete.call();
    }
  };
}

