import ModalBase from '../base/modalBase';
import eModalType from './eModalType';
import { winAmountCounterStyle } from '../../fontStyles';
import { store } from '../../index';
import actionsModals from '../../redux/modals/actions';
import TweenMax from 'gsap';
import selectors from '../../redux/game/selectors';

export default class ModalFreeSpins extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.type = eModalType.EMT_WIN_FREE_SPINS;
    this.normalScale = 1;
    this.init();
  }

  init() {
    this.overlay = this.getChildByName('blackout');
    this.initListener();
    this.showBlackout();
    this.initText();
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
    // this.on('pointertap', this.onClick);
  }

  onResize = (data) => {
    if (PIXI.utils.isMobile.any && data.isLandscape) {
      this.portretOrientation = false;
      this.container.pivot.x = this.container.displayData.width / 2;
      this.container.pivot.y = this.container.displayData.height / 2;
      this.container.x = this.container.displayData.x + this.container.displayData.width / 2;
      this.container.y = this.container.displayData.y + this.container.displayData.height / 2;
      this.normalScale = 1.6;
      this.container.scale.set(this.normalScale);

      this.overlay.x = this.overlay.displayData.x;
      this.overlay.y = this.overlay.displayData.y;
      this.overlay.scale.set(this.overlay.displayData.scaleX);

    } else if (PIXI.utils.isMobile.any) {
      this.portretOrientation = true;
      const scaleGame = 0.45;

      this.container.pivot.x = this.container.displayData.width / 2;
      this.container.pivot.y = this.container.displayData.height / 2;

      this.container.x = this.displayData.width / 2;
      this.container.y = this.displayData.height / 2 - 50;
      this.normalScale = scaleGame * 2.4;
      this.container.scale.set(this.normalScale);

      this.overlay.x = this.overlay.displayData.x - 630 * 2.166666666666667;
      this.overlay.y = this.overlay.displayData.y + 630;
      this.overlay.scale.set(2.166666666666667);
    }
  }

  initText = ()=> {
    this.container = this.getChildByName('container');
    this.container.pivot.x = this.container.displayData.width / 2;
    this.container.pivot.y = this.container.displayData.height / 2;
    this.container.x += this.container.displayData.width / 2;
    this.container.y += this.container.displayData.height / 2;
    this.normalScale = 1.6;
    this.container.scale.set(this.normalScale);
    this.freespinsBlock = this.container.getChildByName('lable');
    this.freespinsBlock.pivot.set(this.freespinsBlock.width / 2, this.freespinsBlock.height / 2);

    this.multiplierBlock = this.container.getChildByName('multipier_container');
    this.multiplierBlock.pivot.set(this.multiplierBlock.width, this.multiplierBlock.height / 2);

    const stile = { ...winAmountCounterStyle, font: { ...winAmountCounterStyle.font, size: 80 } };

    this.freespinsAmount = new PIXI.BitmapText('10', stile);
    this.freespinsAmount.anchor.set(0.5);
    this.freespinsAmount.x = 120;
    this.freespinsAmount.y = 57;
    this.freespinsBlock.addChild(this.freespinsAmount);

    this.multiplierAmount = new PIXI.BitmapText('2', stile);
    this.multiplierAmount.anchor.set(0.5);
    this.multiplierAmount.x = 146;
    this.multiplierAmount.y = 25;
    this.multiplierBlock.addChild(this.multiplierAmount);
    this.multiplierBlock.x = this.multiplierBlock.x + this.multiplierBlock.width / 2;
    this.multiplierBlock.y = this.multiplierBlock.y + this.multiplierBlock.height / 2;
    this.freespinsBlock.x = this.freespinsBlock.x + this.freespinsBlock.width /2;

    this.defaultPositions = {
      freespinsBlock: {
        x: this.freespinsBlock.x,
        y: this.freespinsBlock.y
      }
    };
  }

  setCrocodileTextPosition = () => {
    this.multiplierBlock.visible = false;
    if (this.portretOrientation) {
      this.freespinsBlock.y = this.defaultPositions.freespinsBlock.y + this.freespinsBlock.height / 2;
    } else {
      this.freespinsBlock.y = this.defaultPositions.freespinsBlock.y + this.freespinsBlock.height * 1.5 ;
    }
  }

  setScorpionTextPosition = () => {
    this.multiplierBlock.visible = true;
    this.freespinsBlock.y = this.defaultPositions.freespinsBlock.y + this.freespinsBlock.height /2;

  }

  destroy() {
    super.destroy();
    this.off('pointertap', this.onClick);
  }

  setAmount() {
    const state = store.getState();
    const newFreespinsCount = selectors.getNewFreespinsCount(state);
    const newMultiplier = selectors.getBonusGameScorpionMultiplier(state);
    this.freespinsAmount.text = newFreespinsCount;
    this.multiplierAmount.text = newMultiplier;
  }

  showBlackout() {
    if (!this.overlay) return;
    this.overlay.show(true, 0.3);
  }

  onClick() {
    store.dispatch(actionsModals.closeModal({ type: this.type }));
  }

  show() {
    this.visible = true;
    this.container.scale.set(this.normalScale);
    this.alpha = 1;
    const state = store.getState();
    const bonusType = selectors.getBonusGameType(state);
    bonusType === 1 ? this.setCrocodileTextPosition() : this.setScorpionTextPosition();
    this.setAmount();
    this.showAnimation();
    setTimeout(()=>store.dispatch(actionsModals.closeModal({ type: this.type })),1200 );
  }

  hide() {
    TweenMax.to(this, 0.2, { alpha: 0, onComplete: ()=> this.visible = false });
  }

  onModalDataChanged = (data) => {
    data.visible ? this.show() : this.hide();
  };

  showAnimation = () => {
    this.container.alpha = 0;
    TweenMax.to(this.container, 1, { alpha: 1 });
  }
}
