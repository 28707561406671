const fireflies = {
  x0min: 0, // px
  x0max: 200, // px
  y0min: 0, // px
  y0max: 380, // px
  scaleStart: 1,
  scaleEnd: 0,
  angle0From: 0,
  angle0To: Math.PI * 2,
  angleVelocityMin: 0.0005,
  angleVelocityMax: 0.0006,
  v0min: 0.05, // px / milliseconds
  v0max: 0.1, // px / milliseconds
  gravityY: 0,
  mass: 0.06, // ~kg
  drag: 1 / 160,
  lifetimeMin: 8000, // milliseconds
  lifetimeMax: 10000, // milliseconds
  count: 36,
  box: {
    x: 0,
    y: 0,
    height: 1000
  }
};

export default fireflies;
