export default {
  EMT_RULES: 'rules',
  EMT_WIN: 'win',
  EMT_WIN_BIG: 'winBig',
  EMT_WIN_REGULAR: 'winRegular',
  EMT_WIN_FREE_SPINS: 'winFreeSpins',
  EMT_WIN_FREE_SPINS_END: 'winFreeSpinsEnd',
  EMT_PROVABLY_FAIR: 'provablyFair',
  EMT_SETTINGS: 'settings',
  EMT_PAYTABLE: 'paytable',
  EMT_INFO: 'info',
  EMT_BONUS_PURCHASE: 'bonusPurches',
  EMT_TRANSITION: 'transition',

};
