import FlashLib from 'flashlib';
import { TimelineMax } from 'gsap';

export default class ControllerBlackout extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.alpha = 0;
  }

  show(needShow, duration = 1, delay = 0) {
    return new Promise((resolve) => {
      const animation = new TimelineMax();
      const alpha = needShow ? 1 : 0;
      animation.to(this, duration, { alpha, delay, onComplete: () => resolve() });
    });
  }
}
