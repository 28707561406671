import FlashLib from 'flashlib';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import { formatCurrency } from '../../utils/math';
export default class ControllerBonusGamePanel extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.textAmount = this.getChildByName('bonus_win_amount');
    this.amount = storeObserver.addListenerAndGetValue(selectors.getBonusAmount, this.onAmountChange);
    this.onAmountChange(this.amount);
  }

    onAmountChange = (value) => {
      this.amount = value;
      const state = store.getState();
      const currencyInfo = selectors.getCurrencyInfo(state);
      const decimals = Math.min(this.amount.getPrecision(), currencyInfo.decimal);
      this.textAmount.text = `${formatCurrency(this.amount, decimals)} ${currencyInfo.currency}`;
    }

}