import ModalBase from '../base/modalBase';
import eModalType from './eModalType';
import selectors from '../../redux/game/selectors';
import { store, storeObserver } from '../../index';
import WinAmountCounter from '../winAmountCounter';
import { winAmountCounterStyle } from '../../fontStyles';
import actionsModals from '../../redux/modals/actions';
import ControllerSounds, { eSoundType } from '../sounds/controllerSounds';
import { eGameState } from '../../redux/game/reducer';

export default class ModalWin extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);
    this.type = eModalType.EMT_WIN;
    this.winCounter = this.getChildByName('winCounter');
    this.init();
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {
    const widthGame = data.gameWidth;
    const heightGame = data.gameHeight;
    if (PIXI.utils.isMobile.any && data.isLandscape) {
      this.winCounter.pivot.x = 0;
      this.winCounter.pivot.y = 0;
      this.winCounter.x = this.winCounter.displayData.x;
      this.winCounter.y = this.winCounter.displayData.y;
      this.normalScale = 1;
      this.winCounter.scale.set(this.normalScale);

    } else if (PIXI.utils.isMobile.any) {
      const scaleGame = 0.45;

      this.winCounter.pivot.x = this.winCounter.displayData.width / 2;
      this.winCounter.pivot.y = this.winCounter.displayData.height / 2;

      this.winCounter.x = widthGame / 2;
      this.winCounter.y = heightGame / 2 - 50;
      this.normalScale = scaleGame * 1.6;
      this.winCounter.scale.set(this.normalScale);

    }
  }

  init() {
    this.initListener();
    this.winCounterStyle = { ...winAmountCounterStyle, font: {
      name: 'BastetFont',
      size: 180
    }, };
    this.interactive = true;
    document.addEventListener('keyup', event => this.onKeyDown(event), false);
    storeObserver.addListener(selectors.getGameState, this.onGameStateChange);
    this.visible = false;
  }
  onModalDataChanged = (data) => {
    if (this.visible === data.visible) return;
    this.visible = data.visible;
    if (!this.visible) return;
    ControllerSounds.playSound(eSoundType.EST_WIN);
    ControllerSounds.playSound(eSoundType.EST_COINS);
    const state = store.getState();
    const winAmount = selectors.getWinAmount(state);
    this.setAmount(winAmount, state);
  }

  setAmount = (value) => {
    this.winAmountCounter = new WinAmountCounter(this.winCounter, this.winCounterStyle);

    if (this.showed) return;
    this.showed = true;

    const currencyInfo = selectors.getCurrencyInfo(store.getState());
    const decimals = Math.min(value.getPrecision(), currencyInfo.decimal);

    this.winAmountCounter.animate(value, decimals, 0.3, 0.6);
    this.winAmountCounter.onStop = () => {
      this.showed = false;
      this.stop();
    };
  }

  onKeyDown(event) {
    if (event.keyCode === 32 && this.visible) { //32 - Space
      this.showed = false;
      this.stop();
      this.winAmountCounter.stop();
    }
  }
  onGameStateChange = (gameState) => {
    if (this.gameState === gameState) return;
    this.gameState = gameState;
    const state = store.getState().game;
    if (gameState === eGameState.EGS_WAITING_FOR_RESPONSE && !state.autospin) {
      this.showed = false;
      this.stop();
      this.winAmountCounter?.stop();
    }
  }
  stop() {
    this.visible = false;
    this.showed = false;
    store.dispatch(actionsModals.closeModal({ type: this.type }));
  }
}
