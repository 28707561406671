import { Linear, TimelineMax } from 'gsap';
import ReelAbstractState from './reelAbstractState';

export default class ReelSpinState extends ReelAbstractState {

  constructor(reel) {
    super(reel);

    // this._anticipatorSpinCount = 12;
    // this._anticipatorSpins = 0;

    this.init();
  }

  init() {
    this.animation = new TimelineMax({ paused: true });
    this.animation.to(this.reel.symbols, 0.07 / this.reel.speed, {
      y: '+=' + this.reel.config.symbols.height,
      ease: Linear.easeNone,
      onComplete: this.onComplete,
      callbackScope: this,
    });
  }

  spin() {
    this.animation.invalidate();
    this.animation.restart();
  }

  onComplete() {
    if (this.reel.needStop && !this.reel.isAnticipator) {
      // this._anticipatorSpins = 0;
      this.reel.setState(this.reel.states.stop);
    }

    if (this.reel.needStop && this.reel.isAnticipator) {
      // this._anticipatorSpins += 1;

      if (this._anticipatorSpins >= this._anticipatorSpinCount) {
        // this._anticipatorSpins = 0;
        this.reel.setState(this.reel.states.stop);
      }
    }

    this.reel.cycleSymbols();
    this.reel.state.spin();
  }

}
