import selectors from '../../../redux/game/selectors';
import { store } from '../../../index';
import actions from '../../../redux/game/actions';
import { eGameState } from '../../../redux/game/reducer';
import { sleep } from '../../../utils/sleep';
import actionsModals from '../../../redux/modals/actions';
import eModalType from '../../popups/eModalType';
import ControllerBehaviorBase from './controllerBehaviorBase';

export default class ControllerSlotLastFreespinBehaviour extends ControllerBehaviorBase {

  constructor(slotController) {
    super(slotController);
    this._slotController = slotController;
  }

  endSpin() {
    const state = store.getState();
    const winLines = selectors.getWinLine(state);
    const bonusGameType = selectors.getBonusGameType(state);

    this._slotController.startWinAnimation(winLines, true).then(async delay => {
      if (bonusGameType !== 2) {
        this.checkShowWinPopup();
      }
      await sleep(delay);
      this._slotController.freespinsPanel.tryUpdateHearts();
      if (bonusGameType === 2) {
        await this.checkShowWinPopup();
        await sleep(200);
      }

      await this.checkShowBigWinPopup();

      this._slotController.freespinsPanel.setValue();
      this._slotController.preBonusGame.animal.jump();

      store.dispatch(actionsModals.showModal({ type: eModalType.EMT_WIN_FREE_SPINS_END }));

      await sleep(6550);

      const time = this._slotController.vines.show(0);
      store.dispatch(actions.closeBonusGame());
      store.dispatch(actions.tryShowPreBonusGame());

      await sleep(time);

      this.updateFreeBets();
      store.dispatch(actions.setGameState(eGameState.EGS_READY_TO_PLAY));

    });
  }

}
