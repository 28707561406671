import { store } from '../../../index';
import actionsModals from '../../../redux/modals/actions';
import eModalType from '../../popups/eModalType';
import constants from '../../../constants';
import { sleep } from '../../../utils/sleep';
import actions from '../../../redux/game/actions';
import { eGameState } from '../../../redux/game/reducer';

export default class ControllerBehaviorBase {
  constructor() {
    this.freeBetsController = window.OPWrapperService.freeBetsController;
  }

  async checkShowBigWinPopup() {
    const state = store.getState().game;
    const isBigWin = (state.winAmount >= (state.betAmount * constants.MULTIPLIER_BIG_WIN) && state.betAmount >= constants.MIN_BET_FOR_BIG_WIN_POPUPS);
    const isMegaWin = (state.winAmount >= (state.betAmount * constants.MULTIPLIER_MEGA_WIN) && state.betAmount >= constants.MIN_BET_FOR_BIG_WIN_POPUPS);
    const isEpicWin = (state.winAmount >= (state.betAmount * constants.MULTIPLIER_EPIC_WIN) && state.betAmount >= constants.MIN_BET_FOR_BIG_WIN_POPUPS);

    let timeSleep;
    if (isEpicWin) {
      // timeSleep = 8200;
      timeSleep = 17500;

    } else if (isMegaWin) {
      // timeSleep = 4700;
      timeSleep = 12500;

    } else if (isBigWin) {
      // timeSleep = 3600;
      timeSleep = 7500;

    }

    if (isBigWin) {
      if (this._slotController.isWaitParticle) await sleep(1700);
      store.dispatch(actionsModals.showModal({ type: eModalType.EMT_WIN_BIG }));
      await sleep(timeSleep);
    }

  }

  async checkShowWinPopup() {
    const state = store.getState().game;
    const isSmallWin = !(state.winAmount <= 0 || (state.winAmount >= (state.betAmount * constants.MULTIPLIER_BIG_WIN) && state.betAmount >= constants.MIN_BET_FOR_BIG_WIN_POPUPS));

    if (isSmallWin) {
      store.dispatch(actionsModals.showModal({ type: eModalType.EMT_WIN }));
      await sleep(500);
    }
  }

  updateFreeBets() {
    const state = store.getState().game;
    this.freeBetsController.updateTotalWin();
    if (!state.bonusGameShowed) {
      this.freeBetsController.show();
      window.OPWrapperService.realityCheck.blockedRealityCheck = false;
    }
    if (
      (this.freeBetsController.isFirstFreeBet || this.freeBetsController.isLastFreeBet || window.OPWrapperService.realityCheck.blockedGame)
      && state.autospin
    ) {
      store.dispatch(actions.stopAutospin());
      store.dispatch(actions.setGameState(eGameState.EGS_STOP_AUTOSPIN));
    }
  }
}
