export default {
  EST_LOW_PURPLE: 'low_purple',
  EST_LOW_GREEN: 'low_green',
  EST_LOW_BLUE: 'low_blue',
  EST_LOW_ORANGE: 'low_orange',
  EST_HIGH_1: 'high_1',
  EST_HIGH_3: 'high_3',
  EST_HIGH_2: 'high_2',
  EST_WILD: 'wild',
  EST_JUG_EMPTY: 'jug_empty',
  EST_JUG_COBRA: 'jug_cobra',
  EST_JUG_GOLD: 'jug_gold',
};
