export default {
  EAT_CELL: 'fish and pearl',
  EAT_LOW_ICONS: 'low_icons',
  EAT_HIGH_1: 'high_1',
  EAT_HIGH_3: 'high_3',
  EAT_HIGH_2: 'high_2',
  EST_VINES: 'vines',
  EST_WILD: 'wild',
  EST_TORCH: 'torch',
  EST_MODAL_BIG_WINS: 'modal_big_wins',
  EAT_ANIMALS_BONUS_GAME: 'animals-bonus-game',
  EAT_BONUS_GAME_BG: 'bonus-game-bg',
  EAT_JUGS: 'jugs',
  EAT_BONUS_PURCHASE: 'bonus_purchase',
  EAT_BONUS_GAME_CROCODILE: 'bonus_game_crocodile',
  EAT_BONUS_GAME_SCORPION: 'bonus_game_scorpion',
  EAT_POPUP_START_BONUS_GAME: 'popup_start_bonus_game',
  EAT_PRE_BONUS_GAME_BAR_PROGRESS: 'pre_bonus_game_bar_progress',
  EAT_BONUS_BUTTON: 'bonus_button',
  EAT_COINS: 'coins'

};
