import FlashLib from 'flashlib';
import { requestBuyBonusGame } from '../../../api/rest';
import { store } from '../../../index';
import actionsModals from '../../../redux/modals/actions';
import eModalType from '../eModalType';
import actions from '../../../redux/game/actions';
import selectors from '../../../redux/game/selectors';
import { formatCurrency } from '../../../utils/math';
import { eGameState } from '../../../redux/game/reducer';

export default class ControllerBonusPurchaseModal extends FlashLib.MovieClip {
  constructor(data, $displayItemData) {
    super(data, $displayItemData);
    this.wrapper = this.getChildByName('wrapper');
    this.close = this.wrapper.getChildByName('btnClose');
    this.continue = this.wrapper.getChildByName('btnContinue');
    this.priceText = this.wrapper.getChildByName('amount');
    this.overlay = this.children[0];
    this.type = -1;

    this.close.onClick = this.onCloseClicked;
    this.visible = false;
    this.interactive = true;
    this.continue.onClick = this.onContinueClick;
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }
  onResize = (data) => {
    if (PIXI.utils.isMobile.any && data.isLandscape) {
      this.wrapper.pivot.x = 0;
      this.wrapper.x = this.wrapper.displayData.x;
      this.wrapper.pivot.y = 0;
      this.wrapper.y = this.wrapper.displayData.y;
      this.wrapper.scale.set(1);

      this.overlay.x = this.overlay.displayData.x;
      this.overlay.y = this.overlay.displayData.y;
      this.overlay.scale.set(this.overlay.displayData.scaleX);
    } else if (PIXI.utils.isMobile.any) {
      const scaleGame = 0.45;

      this.wrapper.pivot.x = this.wrapper.displayData.width / 2;
      this.wrapper.x = this.displayData.width / 2;
      this.wrapper.pivot.y = this.wrapper.displayData.height / 2;
      this.wrapper.y = this.displayData.height / 2;
      this.wrapper.scale.set(scaleGame * 1.8);

      this.overlay.x = this.overlay.displayData.x - 630 * 2.166666666666667;
      this.overlay.y = this.overlay.displayData.y - 630;
      this.overlay.scale.set(2.166666666666667);
    }

  }

  updatePrice = () => {
    const state = store.getState();
    const amount = selectors.getBonusPrices(state)[this.type];
    const currencyInfo = selectors.getCurrencyInfo(state);

    this.priceText.text = formatCurrency(amount, currencyInfo.decimal) + ` ${currencyInfo.currency}`;
  };

  show(type) {
    this.type = type;
    this.visible = true;
    this.updatePrice();
  }

  onContinueClick = () => {
    const state = store.getState();
    const amount = selectors.getBonusPrices(state)[this.type];
    const balance = selectors.getBalance(state);
    if (balance < amount) {
      window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
      return;
    }
    OPWrapperService.ControllerStatistic.bet = amount;
    OPWrapperService.realityCheck.blockedRealityCheck = true;
    requestBuyBonusGame(this.type).then(this.onResponse);
  }

  onResponse = () => {
    this.onCloseClicked();
    store.dispatch(actionsModals.closeModal({ type: eModalType.EMT_BONUS_PURCHASE }));
    store.dispatch(actions.tryShowBonusGame());
    store.dispatch(actions.setGameState(eGameState.EGS_BUY_BONUS_GAME));
    store.dispatch(actions.setGameState(eGameState.EGS_READY_TO_PLAY));

  }

  onCloseClicked = () => {
    this.visible = false;
  }
}
