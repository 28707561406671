export const greenShadow = {
  dropShadow: true,
  dropShadowAlpha: 0.7,
  dropShadowAngle: 1.3,
  dropShadowColor: '#016f00',
  dropShadowDistance: 6,
};

// legacy
export const greenStroke = {
  stroke: '#3c6e24',
  strokeThickness: 8,
};

export const greenStrokeShadow = {
  stroke: '#3c6e24',
  strokeThickness: 8,
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowBlur: 1,
  dropShadowColor: '#1d3d0d',
  dropShadowDistance: 10,
};

export const yellowStrokeGradient = {
  letterSpacing: 10,
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#4e0600',
  strokeThickness: 10,
  fill: [
    '#fab406',
    '#f7f6af',
    '#fab406',
    '#fffb00'
  ]
};

export const modalWinHeadline = {
  ...yellowStrokeGradient,
  align: 'center',
  fontFamily: 'ObelixPro',
  fontSize: 120
};

export const winAmountCounterStyle = {
  anchor: {
    x: 0.5,
    y: 0.5
  },
  font: {
    name: 'BastetFont',
    size: 240
  },
  align: 'center'
};

export const freeSpinsPanelValue = {
  ...yellowStrokeGradient,
  align: 'center',
  fontSize: 36
};

export const whiteYeelowOrange = {
  fill: [
    'white',
    'yellow',
    '#ffa200'
  ],
  fillGradientStops: [
    0.4,
    0.5,
    0.7
  ]
};

export const yellowToOrangeAndStroke = {
  fill: [
    '#ffff6e',
    '#fff900',
    '#ffa82d'
  ],
  lineJoin: 'round',
  stroke: '#380000',
  strokeThickness: 6,
  fillGradientStops: [
    0.4,
    0.6,
    0.8
  ],
};
