import { formatCurrency } from '../utils/math';
import ControllerSounds, { eSoundType } from './sounds/controllerSounds';

export default class WinAmountCounter {
  constructor(element, style) {
    const label = element.getChildByName('label');
    label.visible = false;

    this.label = new PIXI.BitmapText('0', style);
    this.label.anchor.set(style.anchor.x, style.anchor.y);
    this.label.x = label.x + (label.width / 2);
    this.label.y = label.y + (label.height / 2);
    this.label.visible = false;
    element.addChild(this.label);

    this.onStop = () => {};
  }

  animate(value, decimals = 2, durationCount = 1, durationEnd = 2) {
    this.durationCount = durationCount;
    this.durationEnd = durationEnd * 1000;
    this.label.visible = true;
    this.interval = this.animateValueChanged(
      0,
      value,
      decimals,
      (value) => this.label.text = value,
      this.onAnimationComplete
    );
  }

  show = (value, decimals = 2,duration = 2) => {
    this.durationEnd = duration * 1000;
    this.label.visible = true;
    this.label.text = formatCurrency(value, decimals);
    this.timeout = setTimeout(this.stop, this.durationEnd);
  }

  stop = () => {
    if (this.interval) clearInterval(this.interval);
    if (this.timeout) clearTimeout(this.timeout);
    if (this.soundTimeout) clearTimeout(this.soundTimeout);
    this.label.text = '0';
    this.label.visible = false;
    this.onStop();
  };

  onAnimationComplete = () => {
    this.timeout = setTimeout(this.stop, this.durationEnd);
  };

  playSound(delay = 0, index = 0) {
    this.soundTimeout = setTimeout(() => {
      ControllerSounds.playSound(eSoundType.EST_COINS);
      index++;
      if (index < 5) {
        this.playSound(delay + 150, index);
      }
    }, delay);
  }

  animateValueChanged = (startValue, endValue, decimals, onProgress, onComplete) => {
    if (endValue === null) return;
    const duration = this.durationCount;
    const fps = 30;
    let step = (endValue - startValue) / (duration * fps);
    const interval = setInterval(() => {
      if (startValue === endValue) {
        clearInterval(interval);
        onComplete();
        return;
      }
      let value = startValue + step;
      if (Math.abs(endValue - value) <= Math.abs(step)) value = endValue;
      startValue = value;
      if (value !== value) {
        clearInterval(interval);
        onComplete();
      }
      onProgress(formatCurrency(value, decimals));
    }, 1000 / fps);

    return interval;
  };
}
