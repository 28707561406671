import ControllerSounds, { eSoundType } from './sounds/controllerSounds';
import { yellowStrokeGradient } from '../fontStyles';
import { store } from '../index';
import actions from '../redux/game/actions';
import { eScreenMode } from '../enums';
import UiAdapter from './ui/UiAdapter';
import uiConfig from './ui/config';
export default class ControllerStartScreen {
  constructor(main) {
    this.startScreen = main.getChildByName('startScreen');
    this.background = this.startScreen.getChildByName('bg');
    this.startScreen.interactive = true;
    this.slidesBlock = this.startScreen.getChildByName('slides');
    this.setTexts();
    this.buttonStart = this.startScreen.getChildByName('buttonStart');
    this.leftButton = this.slidesBlock.getChildByName('left_btn');
    this.rightButton = this.slidesBlock.getChildByName('right_btn');
    this.leftButton.buttonMode = true;
    this.rightButton.buttonMode = true;
    this.leftButton.interactive = true;
    this.rightButton.interactive = true;
    this.buttonStart.on('pointertap', this.onButtonStartClicked);
    this.leftButton.on('pointertap', this.decrementSlide);
    this.rightButton.on('pointertap', this.incrementSlide);
    const buttonLabel = this.buttonStart.getChildByName('welcome_button_start');
    buttonLabel.style = {
      ...buttonLabel.style,
      ...yellowStrokeGradient,
      stroke: '#793a14',
      strokeThickness: 12,
      letterSpacing: 1,
    };

    this.dots = [];
    this.activeFrame = 1;
    this.dotsContainer = this.startScreen.getChildByName('dots_container');
    for (let i = 0; i < 3; i++) {
      const dot = this.dotsContainer.getChildByName(`dot_${i}`);
      dot.onClick = ()=>this.onClickDot(i);
      this.dots.push(dot);
    }
    this.onClickDot(0);

    this.defaultPositions = {
      slidesBlock: {
        x: this.slidesBlock.x,
        y: this.slidesBlock.y
      }
    };

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
    this.background.interactive = true;
    this.background.pointerdown = this.onPointerDown;
    // document.documentElement.addEventListener('touchstart', (e)=>console.log("start", e));
    // document.documentElement.addEventListener('touchend', (e)=>console.log("end", e));
  }

  onPointerDown = (e) => {
    const startTouchPoint = e.data.getLocalPosition(this.background);
    this.background.pointerup = (event)=>{
      const endTouchPoint = event.data.getLocalPosition(this.background);
      this.checkSwipe(startTouchPoint, endTouchPoint);
    };

  }
  checkSwipe = (startPoint, endPoint) => {
    const distance = endPoint.x - startPoint.x;
    if (distance > 20) {
      this.decrementSlide();
    } else if (distance < -20) {
      this.incrementSlide();
    }
  }

  onResize = (data) => {
    if (PIXI.utils.isMobile.any && data.isLandscape) {
      this.startScreen.goToFrame(1);
      this.slidesBlock = this.startScreen.getChildByName('slides');
      this.slidesBlock.goToFrame(this.activeFrame + 1);
      this.setTexts();

      this.slidesBlock.pivot.x = 0;
      this.slidesBlock.pivot.y = 0;

      this.slidesBlock.x = this.slidesBlock.displayData.x;
      this.slidesBlock.y = this.slidesBlock.displayData.y;
      this.slidesBlock.scale.set(this.slidesBlock.displayData.scaleX);
      this.dotsContainer.scale.set(this.dotsContainer.displayData.scaleX);

      this.buttonStart.scale.set(1);
      this.buttonStart.pivot.y = 0;
      this.dotsContainer.pivot.y = 0;
      this.buttonStart.y = this.buttonStart.displayData.y;
      this.dotsContainer.y = this.dotsContainer.displayData.y;
      this.startScreen.x = this.startScreen.displayData.x;
      this.startScreen.y = this.startScreen.displayData.y;
      this.startScreen.scale.set(this.startScreen.displayData.scaleX);
    } else if (PIXI.utils.isMobile.any) {
      const portraitScale = 0.45;
      this.startScreen.goToFrame(2);
      this.slidesBlock = this.startScreen.getChildByName('slides');
      this.slidesBlock.goToFrame(this.activeFrame + 1);
      this.setTexts();

      this.slidesBlock.pivot.x = this.slidesBlock.displayData.width / 2;
      this.slidesBlock.pivot.y = this.slidesBlock.displayData.height / 2;

      this.slidesBlock.x = this.startScreen.displayData.width / 2;
      this.slidesBlock.y = this.startScreen.displayData.height / 2 - 50;
      this.slidesBlock.scale.set(portraitScale);

      this.buttonStart.pivot.x = this.buttonStart.displayData.width / 2;
      this.buttonStart.pivot.y = this.buttonStart.displayData.height / 2;

      this.buttonStart.x = this.buttonStart.displayData.x + this.buttonStart.displayData.width / 2;
      this.buttonStart.y = this.buttonStart.displayData.y + this.buttonStart.displayData.height / 2 - 120;
      this.buttonStart.scale.set(portraitScale);

      this.dotsContainer.pivot.x = this.dotsContainer.displayData.width / 2;
      this.dotsContainer.pivot.y = this.dotsContainer.displayData.height / 2;

      this.dotsContainer.x = this.dotsContainer.displayData.x + this.dotsContainer.displayData.width / 2;
      this.dotsContainer.y = this.dotsContainer.displayData.y + this.dotsContainer.displayData.height / 2 - 80;
      this.dotsContainer.scale.set(portraitScale);

      this.startScreen.x = this.startScreen.displayData.x - 630 * 2.166666666666667;
      this.startScreen.y = this.startScreen.displayData.y - 630;
      this.startScreen.scale.set(2.166666666666667);
    }
  }

  onClickDot = (index) => {
    this.setActiveSlide(index);
  }

  setActiveSlide = (frame) => {
    this.activeFrame = frame;
    this.slidesBlock.goToFrame(this.activeFrame + 1);
    this.setTexts();
    for (let i = 0; i < this.dots.length; i++) {
      this.dots[i].enabled = i !== frame ;
    }
  }

  incrementSlide = () => {
    this.activeFrame = (this.activeFrame + 1) % this.dots.length;
    this.setActiveSlide(this.activeFrame);
  }

  decrementSlide = () => {
    this.activeFrame = Math.abs((this.activeFrame - 1 + this.dots.length) % this.dots.length);
    this.setActiveSlide(this.activeFrame);
  }

  setTexts = () => {
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        const text = this.slidesBlock.getChildByName(`start_screen_${i}_${j}`);
        if (text) {
          text.style = {
            ...text.style,
            ...yellowStrokeGradient,
            stroke: '#793a14',
            strokeThickness: 12,
            letterSpacing: 1,
          };
        }
      }
    }
  }

  onButtonStartClicked = () => {
    this.startScreen.visible = false;
    ControllerSounds.playSFX();
    ControllerSounds.onStartScreenHide();
    window.OPWrapperService.freeBetsController.show();
    const ControllerUi = window.OPWrapperService.initUi(uiConfig);
    new UiAdapter(ControllerUi).init({
      bonusLabelText: 'FREESPINS',
      clickSound: { soundName: eSoundType.EST_CLICK }
    });
    store.dispatch(actions.setCanSpin(true));
    store.dispatch(actions.setScreenMode(eScreenMode.GAME));
  };
}
