import ModalBase from '../../base/modalBase';
import eModalType from '../eModalType';
import { store } from '../../../index';
import actionsModals from '../../../redux/modals/actions';
import animationsLoader from '../../animations/animationsLoader';
import eAnimationType from '../../animations/eAnimationType';
import ePopupBonusPurchaseAnimation from './ePopupBonusPurchaseAnimation';
import ControllerBetSelector from '../../ui/controllerBetSelector';
import * as PIXI from 'pixi.js';
import ControllerBonusPurchaseCard from './ControllerBonusPurchaseCard';
import eBonusType from '../../BonusGame/eBonusType';

export default class PopupBonusPurchase extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.type = eModalType.EMT_BONUS_PURCHASE;
    this.defaultPositions = {};

    this.wrapper = this.getChildByName('wrapper');
    this.btnClose = this.wrapper.getChildByName('close');
    this.defaultPositions.btnClose = { x: 1588, y: this.btnClose.y };
    this.btnClose.x = this.defaultPositions.btnClose.x;
    this.btnClose.addListener('pointertap', this.onCloseClicked);
    this.betSelector = this.wrapper.getChildByName('bet_selector_container');
    new ControllerBetSelector(this.betSelector.getChildByName('cashBet'));
    this.cards = [];
    const bonusTypes = Object.keys(eBonusType);
    for (let i = 0; i < bonusTypes.length; i++) {
      const type = bonusTypes[i];
      this.cards.push(new ControllerBonusPurchaseCard(this, eBonusType[type], i));
    }
    this.initBuyButtons();
    this.initAnimationButtons();
    this.initAnimationTitle();
    this.initListener();
    this.setTextStyle();
    this.showBlackout();

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );

  }

  onResize = (data) => {
    if (PIXI.utils.isMobile.any && data.isLandscape) {
      this.wrapper.pivot.x = 0;
      this.wrapper.x = this.wrapper.displayData.x;
      this.wrapper.pivot.y = 0;
      this.wrapper.y = this.wrapper.displayData.y;
      this.wrapper.scale.set(1);

      this.titleAnimation.y = this.defaultPositions.titleAnimation.y;

      this.btnClose.x = this.defaultPositions.btnClose.x;
      this.btnClose.y = this.defaultPositions.btnClose.y;

      this.betSelector.y = this.betSelector.displayData.y;

      this.overlay.x = 0;
      this.overlay.y = 0;
      this.overlay.scale.set(1);
    } else if (PIXI.utils.isMobile.any) {
      const scaleGame = 0.45;

      this.wrapper.pivot.x = this.wrapper.displayData.width / 2;
      this.wrapper.x = this.displayData.width / 2;
      this.wrapper.pivot.y = this.wrapper.displayData.height / 2;
      this.wrapper.y = this.displayData.height / 2 - 124;
      this.wrapper.scale.set(scaleGame * 1.8);

      this.titleAnimation.y = -116;

      this.btnClose.x = 1122;
      this.btnClose.y = -296;

      this.betSelector.y = this.betSelector.displayData.y + 368;

      this.overlay.x = - 630 * 2.166666666666667;
      this.overlay.y = - 630;
      this.overlay.scale.set(2.166666666666667);
    }

  }

  onModalDataChanged = (data) => {
    if (this.visible === data.visible) return;

    this.visible = data.visible;
    if (data.visible) {
      this.cards.forEach(card => card.start());
      this.showTitleStartAnimation();
    } else {
      this.stopAnimations();
    }
  }
  initBuyButtons = () => {
    for (let i = 0; i < 3; i++) {
      this[`buyButton${i}`] = this.wrapper.getChildByName(`card_${i}`).getChildByName(`buy_${i}`);
      const button = this[`buyButton${i}`];
      const hitArea = new PIXI.Rectangle(
        0,
        0,
        button.width,
        button.height,
      );
      button.hitArea = hitArea;
      button.addListener('pointertap', () => this.onBuyButtonClick(i));
      button.addListener('mouseover', () => this.onBuyButtonOver(i));
      button.addListener('mouseout', () => this.onBuyButtonOut(i));

    }

  }
  onBuyButtonClick = (index) => {
    this.cards[index].onAnimationClick();
  }
  onBuyButtonOver = (index) => {
    this.cards[index].onAnimationOver();
  }
  onBuyButtonOut = (index) => {
    this.cards[index].onAnimationOut();
  }

  initAnimationButtons = () => {
    const xButtonBuyDefault = 123;
    const yButtonBuyDefault = 48;
    for (let i = 0; i < 3; i++) {
      this[`animationButtonBuy${i}`] = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_PURCHASE);
      const animation = this[`animationButtonBuy${i}`];
      animation.x = xButtonBuyDefault;
      animation.y = yButtonBuyDefault;
      animation.visible = false;
      animation.state.addListener({
        complete: () => {
          animation.visible = false;
          if (i === 2) this.showBuyButtonAnimation();
        }
      });
      // animation.state.setAnimation(0, ePopupBonusPurchaseAnimation.EPBPA_BUY_BUTTON_SIDE, true);
      this[`buyButton${i}`].addChildAt(animation, 1);
    }
    this.showBuyButtonAnimation();
  }

  initAnimationTitle = () => {
    this.titleAnimation = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_PURCHASE);
    this.defaultPositions.titleAnimation = {
      x: this.wrapper.displayData.width / 2 + 10,
      y: 60,
    };
    this.titleAnimation.x = this.defaultPositions.titleAnimation.x;
    this.titleAnimation.y = this.defaultPositions.titleAnimation.y;
    this.titleAnimation.state.addListener({
      complete: () => {
        this.titleAnimation.state.setAnimation(0, ePopupBonusPurchaseAnimation.EPBPA_TITLE_IDLE, true);
      }
    });
    this.titleAnimation.visible = false;
    this.wrapper.addChild(this.titleAnimation);
  }

  showBuyButtonAnimation = () => {
    const delay = 3000 + 0 * Math.random();
    const offset = 1.5 * 1000;
    for (let i = 0; i < 3; i++) {
      setTimeout(() => {
        if (this.visible) {
          this[`animationButtonBuy${i}`].visible = true;
          this[`animationButtonBuy${i}`].state.setAnimation(0, ePopupBonusPurchaseAnimation.EPBPA_BUY_BUTTON_SIDE, false);
        }
      }, delay + offset * i);
    }
  }
  showTitleStartAnimation = () => {
    this.titleAnimation.visible = true;
    this.titleAnimation.state.setAnimation(0, ePopupBonusPurchaseAnimation.EPBPA_TITLE_START, false);
  }

  showBlackout() {
    this.overlay = new PIXI.Graphics();
    this.overlay.beginFill(0x000000, 0.6);
    this.overlay.drawRect(0, 0, 2340, 1080);
    this.overlay.endFill();
    this.addChildAt(this.overlay, 0);
  }

  setTextStyle() {
    for (let i = 0; i < 3; i++) {
      const text = this[`buyButton${i}`].getChildByName('popup_bonus_purchase_btn_buy');
      text.style = {
        ...text.style,
        dropShadow: true,
        dropShadowAlpha: 0.8,
        dropShadowBlur: 4
      };
    }
  }

  onCloseClicked = () => {
    store.dispatch(actionsModals.closeModal({ type: this.type }));
  }
  stopAnimations = () => {
    this.titleAnimation.visible = false;
    this.cards.forEach(card => card.stop());
    for (let i = 0; i < 3; i++) {
      this[`animationButtonBuy${i}`].visible = false;
    }
  }

}
