import { Back, TimelineMax } from 'gsap';
import ReelAbstractState from './reelAbstractState';

export default class ReelStopSpin extends ReelAbstractState {

  constructor(reel) {
    super(reel);

    this.init();
  }

  init() {
    this.animation = new TimelineMax({ paused: true });

    this.animation.to(this.reel.symbols, this.reel.startSpeed * 2, {
      y: `+=${this.reel.config.symbols.height}`,
      onComplete: this.onComplete.bind(this),
      ease: Back.easeOut.config(5)
    });
  }

  spin() {
    this.animation.invalidate();
    this.animation.restart();
    this.reel.symbols[1].setSymbol(this.reel.stopSymbol);
    this.reel.stopped = true;

    // ControllerSounds.playSound(eSoundType.EST_REEL_STOP);
  }

  onComplete() {
    this.reel.stopCallback(this.reel);
  }

}
