import FlashLib from 'flashlib';
import Constants from '../../constants';
import serverClientSymbols from '../symbol/serverClientSymbols';
import { storeObserver, store } from '../../index';
import selectors from '../../redux/game/selectors';
import { eGameState } from '../../redux/game/reducer';
import ControllerSlotNormalBehaviour from './behaviour/controllerSlotNormalBehaviour';
import ControllerSlotStartFreespinsBehaviour from './behaviour/controllerSlotStartFreespinsBehaviour';
import ControllerSlotFreespinsBehaviour from './behaviour/controllerSlotFreespinsBehaviour';
import ControllerSlotLastFreespinBehaviour from './behaviour/controllerSlotLastFreespinBehaviour';
import { sleep } from '../../utils/sleep';
import ControllerSounds, { eSoundType } from '../sounds/controllerSounds';
import { indexesOf } from '../../utils/array';
import ControllerSymbolParticles from '../controllerSymbolParticles';

export default class ControllerSlot extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.reels = [];
    this.forceStopped = false;
    this.firstStartWinAnimation = true;
    this.logo = this.getChildByName('logo');
    const mask = this.getChildByName('maskArea');
    const reels = this.getChildByName('reelsArea');
    reels.mask = mask;

    // this.anticipators = this.getChildByName('anticipators');
    this.anticipatorIndexes = [];
    // this.anticipatorShowed = false;

    for (let i = 0; i < Constants.REELS_COUNT; i++) {
      const item = reels.getChildByName(`reel_${i}`);
      item.visible = !(i > 4);
      this.reels.push(item);
    }

    this.vines = this.getChildByName('vines');
    this.vines.onAnimationShowStart.add(() => {
      this.reels[5].visible = false;
      this.reels[6].visible = false;
      this.freespinsPanel.hide();
    });
    this.vines.onAnimationHideStart.add(() => {
      this.reels[5].visible = true;
      this.reels[6].visible = true;
    });
    this.preBonusGame = this.getChildByName('preBonusGame');
    this.bonusGameCrocodile = this.getChildByName('bonus_game_crocodile');
    this.freespinsPanel = this.getChildByName('freespinsPanel');
    this.bonusGameScorpion = this.getChildByName('bonusGameScorpion');
    this.frame = this.getChildByName('frame');
    this.animationTimeouts = [];
    this.lineIndex = 0;
    storeObserver.addListener(selectors.getGameState, this.onGameStateChanged);

    this.freespinEnable = selectors.getFreespinsEnabled(store.getState());
    if (this.freespinEnable) {
      this.vines.hide(0);
      this.freespinsPanel.show();
      this.freespinsPanel.update();
      this.logo.hide();
    }

    this.defaultPositions = {
      y: this.y,
      x: this.x
    };

    this._behaviour = new ControllerSlotNormalBehaviour(this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );

    this.controllerSymbolParticles = new ControllerSymbolParticles(this);
  }

  onResize = (data) => {
    const widthGame = data.gameWidth;

    if (PIXI.utils.isMobile.any && data.isLandscape) {
      this.scale.set(1);
      this.pivot.x = 0;
      this.y = this.defaultPositions.y;
      this.x = this.defaultPositions.x;

    } else if (PIXI.utils.isMobile.any) {
      const slotCenterX = this.frame.displayData.x + this.frame.displayData.width / 2;
      this.pivot.x = slotCenterX;
      this.x = widthGame / 2;
      this.scale.set(0.49);
    }
  };

  onGameStateChanged = (state) => {
    if (state === eGameState.EGS_WAITING_FOR_RESPONSE) {
      this.spin();
    } else if (state === eGameState.EGS_CAN_STOP) {
      this.checkAnticipator();
      this.stop();
    } else if (state === eGameState.EGS_STOP_SPIN && !store.getState().game.autospin) {
      this.forceStop();
    }
  };

  spin() {
    this.forceStopped = false;
    this.stopped = false;
    this.isWaitParticle = false;
    this.lineIndex = 0;
    // this.anticipatorIndexes = [];
    // this.anticipatorShowed = false;
    this.firstStartWinAnimation = true;
    this.reels.forEach(reel => reel.isAnticipator = false);
    // this.anticipators.hideAll();

    this.clearTimeouts();
    this.freespinEnable = selectors.getBonusGameShowed(store.getState());
    const reelsCount = this.freespinEnable ? 7 : 5;

    for (let i = 0; i < reelsCount; i++) {
      this.reels[i].spin();
    }

    const state = store.getState().game;
    if (state.freeSpinsMax > 0 && state.freespinsCount !== 0) {
      this.freespinsPanel.decrease();
    }

    // this.controllerSymbolParticles.stop();
  }

  async stop() {
    if (this.forceStopped === true) return;
    this.clearTimeouts();
    const state = store.getState();
    const basicBonusGameReal = selectors.getBasicBonusGameReels(state);

    const line = state.game.freespinsEnabled && basicBonusGameReal.length > 0 ? basicBonusGameReal : selectors.getStopReels(state);
    const symbolId = line[this.lineIndex];
    const symbolType = serverClientSymbols[symbolId];
    const callback = this.lineIndex === line.length - 1 ? this.onLastReelStop.bind(this) : this.onReelStop.bind(this);

    this.reels[this.lineIndex].stop(symbolType, symbolId, callback);

    const preBonusGameType = selectors.getPreBonusGameType(state);
    const preBonusGameShowed = selectors.getPreBonusGameShowed(state);
    const bonusGameType = selectors.getBonusGameType(state);
    if ((preBonusGameShowed || bonusGameType === 3) && line[this.lineIndex] === preBonusGameType){
      ControllerSounds.playSound(eSoundType.EST_SFX_REEL_STOP_WIDTH_PARTICLES);
    } else {
      ControllerSounds.playSound(eSoundType.EST_REEL_STOP);
    }

    if (!this.anticipatorIndexes[0]) return;

    if (this.reels[this.lineIndex + 1]) {
      if (this.anticipatorIndexes.find(aIndex => aIndex === this.reels[this.lineIndex + 1].index)) {
        this.reels[this.lineIndex + 1].isAnticipator = true;
      }
    }

    if (this.lineIndex + 1 === this.anticipatorIndexes[0]) {
      // if (this.anticipatorShowed) return;
      // this.anticipatorShowed = true;
      await sleep(500);
      if (this.forceStopped == true) return;
      // this.anticipators.showAnticipators(this.lineIndex, 5);
      // this.anticipators.hideAnticipator(0);
      ControllerSounds.playSound(eSoundType.EST_ANTICIPATOR);
    }
  }

  forceStop() {
    if (this.stopped || this.forceStopped) return;
    this.stopped = true;
    this.forceStopped = true;
    this.clearTimeouts();
    this.lineIndex = 0;

    ControllerSounds.stopSound(eSoundType.EST_ANTICIPATOR);

    const state = store.getState();
    const basicBonusGameReal = selectors.getBasicBonusGameReels(state);

    const line = state.game.freespinsEnabled && basicBonusGameReal.length > 0 ? basicBonusGameReal : selectors.getStopReels(state);
    for (let i = 0; i < line.length; i++) {
      const symbolType = serverClientSymbols[line[i]];
      const callback = i === line.length - 1 ? this.onLastReelStop.bind(this) : () => {
        // ControllerSounds.playSound(eSoundType.EST_REEL_STOP);
      };

      this.reels[i].forceStop(symbolType, callback);
      this.reels[i].isAnticipator = false;
    }

    // this.anticipators.hideAll();
  }

  onReelStop() {
    // ControllerSounds.playSound(eSoundType.EST_REEL_STOP);
    // this.anticipators.hideAnticipator(this.lineIndex);
    this.reels[this.lineIndex].isAnticipator = false;

    this._tryShowParticleForIndex(this.lineIndex);

    this.lineIndex += 1;
    this.stop();
  }

  trySetRealBonusReel = () => {
    const state = store.getState();
    if (state.game.bonusGameCrocodileEnabled) {
      const realReels = selectors.getRealBonusGameReels(state);
      const baseReels = selectors.getBasicBonusGameReels(state);
      for (let i = 0; i < realReels.length; i++) {
        if (baseReels[i] !== realReels[i]) {
          const symbolId = realReels[i];
          const symbolType = serverClientSymbols[symbolId];
          this.reels[i].symbols[1].setSymbol(symbolType);
          this.reels[i].symbols[1].needChangeToWihld = true;
        }
      }
    }
  };

  onLastReelStop = async () => {
    this.stopped = true;

    const state = store.getState();
    if (state.game.freespinsEnabled && !this.freespinEnable) {
      console.log('StartFreespins');
      this._behaviour = new ControllerSlotStartFreespinsBehaviour(this);
    } else if (state.game.freespinsEnabled && (state.game.freespinsCount === 0 || state.game.bonusGameCobraLives === 0)) {
      console.log('LastFreespin');
      this._behaviour = new ControllerSlotLastFreespinBehaviour(this);
    } else if (state.game.freespinsEnabled) {
      console.log('NoramalFreespin');
      this._behaviour = new ControllerSlotFreespinsBehaviour(this);
    } else {
      console.log('NormalBehaviour');
      this._behaviour = new ControllerSlotNormalBehaviour(this);
    }

    this.anticipatorIndexes = [];
    this.reels.forEach(reel => reel.isAnticipator = false);
    // this.anticipators.hideAll();

    this.isWaitParticle = this._showParticle(state);

    // ControllerSounds.playSound(eSoundType.EST_REEL_STOP);
    ControllerSounds.stopSound(eSoundType.EST_ANTICIPATOR);
    await sleep(200);
    ControllerSounds.stopSound(eSoundType.EST_ANTICIPATOR);
    this._behaviour.endSpin();
  };

  checkAnticipator() {
    const line = selectors.getStopReels(store.getState());
    const scatterIndexes = indexesOf(line, 8);

    if (scatterIndexes.length === 1 && scatterIndexes[0] < 3) {
      this.anticipatorIndexes = this.fillAnticipatorReels(scatterIndexes[0] + 1, 4);
    }

    if (scatterIndexes.length === 2 && scatterIndexes[1] < 4) {
      this.anticipatorIndexes = this.fillAnticipatorReels(scatterIndexes[0] + 1, 5);
    }

    if (scatterIndexes.length === 2 && scatterIndexes[1] === 4) {
      this.anticipatorIndexes = this.fillAnticipatorReels(scatterIndexes[0] + 1, scatterIndexes[1]);
    }

    if (scatterIndexes.length >= 3) {
      this.anticipatorIndexes = this.fillAnticipatorReels(scatterIndexes[0] + 1, 5);
    }
  }

  fillAnticipatorReels(from, to) {
    const anticipatorReels = [];

    for (let i = from; i < to; i++) {
      anticipatorReels.push(i);
    }

    return anticipatorReels;
  }

  async startWinAnimation(winLines, firstStart = true) {
    let delay = 0;
    const state = store.getState();
    const bonusGameCrocodileEnable = selectors.getBonusGameCrocodileEnabled(state);
    const bonusGameType = selectors.getBonusGameType(state);
    const baseReels = selectors.getBasicBonusGameReels(state);
    if (bonusGameCrocodileEnable && firstStart) {
      const realReels = selectors.getRealBonusGameReels(state);
      this.firstStartWinAnimation = false;
      for (let i = 0; i < baseReels.length; i++) {
        if (baseReels[i] !== realReels[i] && baseReels[i] === 1) {
          this.reels[i].playSymbolAnimation();
          delay = 2000;
        }
      }

    } else if (bonusGameType === 2) {
      for (let i = 0; i < baseReels.length; i++) {
        if (firstStart) delay += 200;
        const timeout = setTimeout(() => {
          this.reels[i].playSymbolAnimation();
        }, delay);
        this.animationTimeouts.push(timeout);
      }
      delay += 1800;

    } else if (bonusGameType === 3 && firstStart) {
      for (let i = 0; i < baseReels.length; i++) {
        let count = 0;
        if (baseReels[i] === 3) {
          this.reels[i].playSymbolAnimation();
          count += 1;
          delay = 2000;
        }
        if (count > 0) this.bonusGameScorpion.updateBarPosition();
      }

    } else {
      winLines.forEach(line => {
        const timeout = setTimeout(() => {
          line.reel.forEach(index => this.reels[index].playSymbolAnimation());
        }, delay);
        delay += 2000;
        this.animationTimeouts.push(timeout);
      });

    }
    const timeout = setTimeout(() => {
      this.trySetRealBonusReel();
      this.startWinAnimation(winLines, false);
    }, delay);
    this.animationTimeouts.push(timeout);

    if (bonusGameType === 1 || bonusGameType === 3) delay = delay + winLines.length * 2000;
    return delay;
  }

  clearTimeouts() {
    for (let i in this.animationTimeouts) {
      clearTimeout(this.animationTimeouts[i]);
      delete this.animationTimeouts[i];
    }
    this.animationTimeouts.length = 0;
  }

  _showParticle(state) {
    let result = false;
    this.controllerSymbolParticles.pause();
    const preBonusGameShowed = selectors.getPreBonusGameShowed(state);
    const bonusGameType = selectors.getBonusGameType(state);
    if (!preBonusGameShowed && bonusGameType !== 3) return;
    const preBonusGameType = selectors.getPreBonusGameType(state);
    const bonusGameShowed = selectors.getBonusGameShowed(state);
    const reel = selectors.getStopReels(state);
    for (let i = 0; i < reel.length; i++) {
      const item = reel[i];
      if (item === preBonusGameType) {
        this.controllerSymbolParticles.showParticlesOnIndex(i, true, 1, bonusGameShowed && bonusGameType === 3);
        result = true;
      }
    }
    return result;
  }

  _tryShowParticleForIndex(i) {
    const state = store.getState();
    const preBonusGameShowed = selectors.getPreBonusGameShowed(state);
    const bonusGameType = selectors.getBonusGameType(state);
    if (!preBonusGameShowed && bonusGameType !== 3) return;
    const preBonusGameType = selectors.getPreBonusGameType(state);
    const bonusGameShowed = selectors.getBonusGameShowed(state);
    const reel = selectors.getStopReels(state);
    const item = reel[i];
    if (item === preBonusGameType) {
      this.controllerSymbolParticles.showParticlesOnIndex(i, false, 2, bonusGameShowed && bonusGameType === 3);
    }
  }
}
