export default class FontLoader {

  /**
   * @param fontsData - array of objects {name: fontName, path: pathToFont}
   * @return Promise when all fonts loaded
   */
  static load(fontsData) {
    const fontLoaders = fontsData.map(data => FontLoader.loadOnce(data.name, data.path));
    return Promise.all(fontLoaders);
  }

  static loadOnce(name, path) {
    return new Promise((resolve, reject) => {
      const font = new FontFace(name, `url(${path})`);

      font.load()
        .then((loaded_face) => {
          document.fonts.add(loaded_face);
          document.body.style.fontFamily += `,${name}`;
          resolve();
        })
        .catch((err) => {
          reject(err);
          throw new Error(`Font "${name}" can't be loaded from "${path}"`);
        });
    });
  }
}
