import '../../scss/styles.scss';
import * as PIXI from 'pixi.js';
import FlashLib from 'flashlib';
import { requestConfig } from '../api/rest';
import AnimationsLoader from './animations/animationsLoader';
import ControllerStartScreen from './controllerStartScreen';
import LocalizeTextField from '../i18n/LocalizeTextField';
// import assetsCombined from '../../assets/flashlib/exported/desktop/FlashLibAssets.json';
import assetsCombined from '../../assets/flashlib/exported/desktop/AssetsCombined.json';
import flashLibMetaDesktop from '../../assets/flashlib/exported/desktop/FlashLib.json';
import SoundManager from './sounds/SoundManager';
import SoundsPreloader from './sounds/SoundsPreloader';
import FontLoader from '../utils/fontLoader';
import EMAdapter from '../platforms/everyMatrix/adapter';
import starsParticle from '../../assets/images/star_particle_fred_small.png';
import Config from './Config';

export default class Game {
  constructor() {
    this.app = null;
    this.compiled = false;

    this.fontsLoaded = false;
    this.assetsLoaded = false;
    this.configReceived = false;
    this.animationsLoaded = false;

    PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false;

    this.start();
  }

  start() {
    this.container = document.getElementById('container');
    this.gameSize = {
      width: 2340,
      height: 1080
    };

    this.applicationOptions = {
      // transparent: true,
      resolution: 1, // this.isRetinaDisplay() ? 1 : 1,
      // autoResize: true
    };
    this.app = new PIXI.Application(this.applicationOptions);

    this.scaleData = {
      app: this.app,
      // scaleRatio: 2,
      gameSize: this.gameSize
    };

    FlashLib.TextField = LocalizeTextField;

    this.app.view.id = 'gameCanvas';
    this.container.appendChild(this.app.view);

    SoundsPreloader.startLoading();
    this.loadAssets();
    this.loadFonts();

    AnimationsLoader.startLoading(this.app, () => {
      console.log('Animations loaded');
      this.animationsLoaded = true;
      this.tryInitGame();
    });

    requestConfig()
      .then(() => {
        console.log('Config received');
        this.configReceived = true;
        this.tryInitGame();
      });

    EMAdapter.init();

  }

  loadAssets() {
    PIXI.Loader.shared.baseUrl = './';
    PIXI.Loader.shared.add('FlashLib', flashLibMetaDesktop, 'json');
    PIXI.Loader.shared.add('FlashLibAssets', assetsCombined, 'json');
    PIXI.Loader.shared.add('starsParticle', starsParticle, 'json');
    PIXI.Loader.shared.add('BastetFont', 'assets/fonts/BastetFont.xml', 'font');
    PIXI.Loader.shared.once('complete', () => {
      console.log('Assets loaded');
      this.assetsLoaded = true;
      this.tryInitGame();
    }, this);
    PIXI.Loader.shared.load();

    PIXI.Loader.shared.on('progress', (data) => {
      OPPreloader.setProgress(data.progress);
      EMAdapter.loadProgress(data.progress);
    });
    PIXI.Loader.shared.on('error', this.onLoadingError.bind(this));
  }

  loadFonts() {
    FontLoader.load([
      { name: 'Ubuntu bold', path: 'assets/fonts/UBUNTU-B.TTF' },
      { name: 'Ubuntu regular', path: 'assets/fonts/UBUNTU-R.TTF' },
      { name: 'ObelixPro', path: 'assets/fonts/ObelixPro-cyr.ttf' },
      { name: 'Myriad Pro', path: 'assets/fonts/MYRIADPRO-BOLDCOND.OTF' },
      { name: 'Inter Semi Bold', path: 'assets/fonts/Inter/Inter-SemiBold.ttf' },
      { name: 'Inter Extra Bold', path: 'assets/fonts/Inter/Inter-ExtraBold.ttf' },
    ])
      .then(() => {
        console.log('Fonts loaded');
        this.fontsLoaded = true;
        this.tryInitGame();
      })
      .catch(this.onLoadingError.bind(this));
  }

  onLoadingError(err) {
    console.error(err);
    window.OPWrapperService.showError(window.OPWrapperService.errors.ASSETS_ERROR.CODE);
    PIXI.Loader.shared.reset();
  }

  tryInitGame() {
    if (this.fontsLoaded &&
      this.assetsLoaded &&
      this.configReceived &&
      this.animationsLoaded) {
      // OrientationBlocker.init(OrientationBlocker.orientationType.LANDSCAPE);
      window.OPPreloader.hide();
      this.initGame();
    }
  }

  initGame() {
    EMAdapter.loadCompleted();
    SoundManager.init();
    const main = FlashLib.createItemFromLibrary('main', 'FlashLib');
    main.name = 'main';

    this.app.stage.addChild(main);
    this.mainContainer = main;

    const slot = main.getChildByName('controllerSlot');
    const sceneMask = main.getChildByName('scene_mask');

    slot.mask = sceneMask;

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.drawMask,
      this
    );

    new ControllerStartScreen(main);
    window.OPWrapperService.ScaleManager.init(this.scaleData, Config);
  }

  drawMask(data) {
    const y = data.isMobile && data.isPortrait ? -630: 0;
    const gameWidth = data.gameWidth;
    const gameHeight = data.isMobile && data.isPortrait ? 2340 : data.gameHeight;
    if (!this.gameMask) {
      this.gameMask = new PIXI.Graphics();
    } else {
      this.gameMask.clear();
    }
    this.gameMask.beginFill(0xFF3300);
    this.gameMask.drawRect(
      0,
      y,
      gameWidth,
      gameHeight
    );
    this.gameMask.endFill();
    this.app.stage.addChild(this.gameMask);
    this.app.stage.mask = this.gameMask;
  }
}
