import Particle from './particle';

const defaultConfig = {
  x0: 0, // px
  y0: 0, // px
  innerRadius: 100,
  outerRadius: 105,
  scaleStart: 0.01,
  scaleEnd: 1,
  angle0From: 0,
  angle0To: 0,
  angleVelocityMin: 0,
  angleVelocityMax: 0,
  v0min: 0, // px / milliseconds
  v0max: 0, // px / milliseconds
  gravityX: 0,
  gravityY: 0,
  mass: 0.05, // ~kg
  drag: 1 / 50,
  lifetimeMin: 800, // milliseconds
  lifetimeMax: 900, // milliseconds
  count: 300,
  box: {
    x: 245,
    y: 230,
  },
  leftBound: false,
  topBound: false,
  rightBound: false,
  bottomBound: false,
  cycleCount: 2
};

const defaultParticleInfo = {
  texture: undefined,
  animationType: undefined,
  animationName: undefined,
};
export default class ParticleSystem {
  constructor(container, particleInfo = defaultParticleInfo, config = defaultConfig) {
    this.container = container;
    this.particleInfo = particleInfo;
    this.config = {
      ...defaultConfig,
      ...config,
      box: {
        ...defaultConfig.box,
        width: this.container.width,
        height: this.container.height,
        ...config.box,
      },
    };
    if (this.particleInfo.texture) {
      this.particleContainer = new PIXI.ParticleContainer(config.count, {
        position: true,
        scale: true,
        rotation: true,
      });
    } else {
      this.particleContainer = new PIXI.Container();
    }

    this.particleContainer.x = this.config.box.x;
    this.particleContainer.y = this.config.box.y;
    this.particleContainer.width = this.config.box.width;
    this.particleContainer.height = this.config.box.height;
    this.container.addChild(this.particleContainer);
    this.particles = [];
    this.start();
  }

  start = () => {
    if (this.isStarted) return;

    let step = 0;
    this.interval = setInterval(() => {
      this.spawnCircleParticles(step);
      step++;
      if (step > 20 * this.config.cycleCount ) {
        this.pause();
        if (this.config.bezier) this.spawnParabolaParticles(step);
        clearInterval(this.interval);
      }
      // console.log(this.particles.length);
    }, 50);

    this.isStarted = true;
  };

  spawnCircleParticles = (step) => {
    this.timeStart = Date.now();
    for (let index = 0; index <= this.config.count / 20; index++) {
      const angle = (2 * step / 20 % 2) * 3.14 + 0.7 * Math.random() - Math.PI / 180 * 150;
      const x0min = this.config.x0 + this.config.innerRadius * Math.cos(angle);
      const y0min = this.config.y0 + this.config.innerRadius * Math.sin(angle);
      const x0max = this.config.x0 + this.config.outerRadius * Math.cos(angle);
      const y0max = this.config.y0 + this.config.outerRadius * Math.sin(angle);

      const particle = new Particle(this.particleContainer,
        {
          ...this.config,
          x0min,
          x0max,
          y0min,
          y0max,
          lifetimeMin: 400,
          lifetimeMax: 400
        }, this.timeStart, this.particleInfo);
      particle.pause();
      this.particles.push(particle);
    }
  };

  spawnParabolaParticles = (step) => {
    this.timeStartBezier = Date.now();
    this.timeUpdate = this.timeStartBezier;
    this.duration = 500;
    const angle = (2 * step / 20 % 2) * 3.14 + 0.7 * Math.random() - Math.PI / 180 * 150;
    this.configBezier = {
      P0x0min: this.config.bezier.p0.x + this.config.innerRadius * Math.cos(angle),
      P0x0max: this.config.bezier.p0.x + this.config.outerRadius * Math.cos(angle),
      P0y0min: this.config.bezier.p0.y+ this.config.innerRadius * Math.sin(angle),
      P0y0max: this.config.bezier.p0.y + this.config.outerRadius * Math.sin(angle),

      P1x0min: this.config.bezier.p1.x + this.config.innerRadius,
      P1x0max: this.config.bezier.p1.x + this.config.outerRadius,
      P1y0min: this.config.bezier.p1.y + this.config.innerRadius,
      P1y0max: this.config.bezier.p1.y + this.config.outerRadius,

      P2x0min: this.config.bezier.p2.x + this.config.innerRadius,
      P2x0max: this.config.bezier.p2.x + this.config.outerRadius,
      P2y0min: this.config.bezier.p2.y + this.config.innerRadius,
      P2y0max: this.config.bezier.p2.y + this.config.outerRadius,
    };
    this.frameDuration = this.duration / 1000;

    requestAnimationFrame(this.updateBezier);

  };

  updateBezier = () => {
    const nowDate = Date.now();
    const differenceTimeFromStart = nowDate - this.timeStartBezier;
    const differenceTime = nowDate - this.timeUpdate;
    if (this.duration <= differenceTimeFromStart) {
      this.pause();
      return;
    } else if (differenceTime >= this.frameDuration) {
      this.timeUpdate = nowDate;
      const progress = differenceTimeFromStart / this.duration;
      const x0min = (1 - progress) * (1 - progress) * this.configBezier.P0x0min + 2 * (1 - progress) * progress * this.configBezier.P1x0min + progress * progress * this.configBezier.P2x0min;
      const x0max = (1 - progress) * (1 - progress) * this.configBezier.P0x0max + 2 * (1 - progress) * progress * this.configBezier.P1x0max + progress * progress * this.configBezier.P2x0max;
      const y0min = (1 - progress) * (1 - progress) * this.configBezier.P0y0min + 2 * (1 - progress) * progress * this.configBezier.P1y0min + progress * progress * this.configBezier.P2y0min;
      const y0max = (1 - progress) * (1 - progress) * this.configBezier.P0y0max + 2 * (1 - progress) * progress * this.configBezier.P1y0max + progress * progress * this.configBezier.P2y0max;

      for (let i = 0; i < 3; i++) {
        const particle = new Particle(this.particleContainer,
          {
            ...this.config,
            x0min,
            x0max,
            y0min,
            y0max,
            lifetimeMin: this.duration,
            lifetimeMax: this.duration + 50,
          }, this.timeStartBezier, this.particleInfo);

        this.particles.push(particle);
      }
      requestAnimationFrame(this.updateBezier);
    } else {
      requestAnimationFrame(this.updateBezier);
    }
  }

  pause = () => {
    clearInterval(this.interval);
    for (let particle of this.particles) {
      particle.pause();
    }
    this.particles = [];
    this.isStarted = false;
  };
  stop = () => {
    clearInterval(this.interval);
    for (let particle of this.particles) {
      particle.stop();
    }
    this.particles = [];
    this.isStarted = false;
  };
}

