import { store, storeObserver } from '../..';
import selectors from '../../redux/game/selectors';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import eBonusGameAnimation from './eBonusGameAnimation';
import { sleep } from '../../utils/sleep';
import constants from '../../constants';

export default class ControllerAnimals {
  constructor(container) {
    this.container = container;

    this.positionDefaultX = 490;
    this.positionDefaultY = 350;
    this.distance = 1000;

    this.moveSpeed = 0.05;

    this.betAmount = storeObserver.addListenerAndGetValue(selectors.getBetAmount, this.onBetAmount);
    const step = selectors.getPreBonusGameProgress(store.getState());
    this.setProgress(step);
    this.setTargetDistance();

    this.startPositionX = this.targetDistanceX;
    this.startPositionY = this.positionDefaultY;

    this.movingStarted = false;

    this.initAnimation();
    this.updateProgress();
  }

  show = () => {
    this.animation.visible = true;
    this.animation.state.setAnimation(0, eBonusGameAnimation[`EBGA_${this.container.type}_IDLE`], true);
  }

  initAnimation = () => {
    this.animation = animationsLoader.createAnimation(eAnimationType.EAT_ANIMALS_BONUS_GAME);
    this.animation.x = this.startPositionX;
    this.animation.y = this.startPositionY;
    this.animation.stateData.defaultMix = 0.05;
    this.container.addChild(this.animation);
    this.animation.visible = false;
  }

  async updateProgress() {
    const state = store.getState();
    const step = selectors.getPreBonusGameProgress(state);
    await sleep(constants.TIME_SLEEP_PRE_BONUS_GAME_PROGRESS);
    const preBonusGameShowed = selectors.getPreBonusGameShowed(state);
    if (!preBonusGameShowed) return;
    this.setProgress(step);
    if (!this.movingStarted) this.showAnimationMoving();
  }

  setProgress = (step) => {
    this.progress = step / 7;
  }

  onBetAmount = (value) => {
    const state = store.getState();
    const progress = selectors.getPreBonusGameProgress(state);
    this.progress = progress / 7;
    this.betAmount = value;
    this.jump();
  }

  jump = () => {
    const step = selectors.getPreBonusGameProgress(store.getState());
    this.setProgress(step);
    this.setTargetDistance();
    this.animation.x = this.targetDistanceX;
  }

  setTargetDistance = () => {
    this.targetDistanceX = this.positionDefaultX + this.distance * this.progress;
  }

  showAnimationMoving = () => {
    this.setTargetDistance();
    if (this.animation.x >= this.targetDistanceX) return;
    this.movingStarted = true;

    this.animation.state.setAnimation(0, eBonusGameAnimation[`EBGA_${this.container.type}_MOVING`], true);
    requestAnimationFrame(this.update);
  }

  drawFunction = (deltaTime) => {
    this.animation.x += this.moveSpeed * deltaTime;
  };

  update = () => {
    if (!this.prevTime) {
      this.prevTime = Date.now();
    }
    const nowTime = Date.now();
    let deltaTime = nowTime - this.prevTime ;
    if (deltaTime > 100) deltaTime = 0;
    this.prevTime = nowTime;
    this.setTargetDistance();

    if (this.animation.x < this.targetDistanceX) {
      this.drawFunction(deltaTime);
      requestAnimationFrame(this.update);
    } else {
      this.animation.state.setAnimation(0, eBonusGameAnimation[`EBGA_${this.container.type}_IDLE`], true);
      this.movingStarted = false;
      return;
    }
  };

  hide = () => {
    this.animation.x = this.positionDefaultX;
    this.animation.visible = false;
  }
}
