const leftFontan = {
  x0min: 615 , // px
  x0max: 615, // px
  y0min: 175, // px
  y0max: 275, // px
  scaleStart: 0.5,
  scaleEnd: 1,
  angle0From: -Math.PI / 180 * 135,
  angle0To: -Math.PI / 180 * 105,
  angleVelocityMin: 0.005,
  angleVelocityMax: 0.006,
  v0min: 2, // px / milliseconds
  v0max: 2.5, // px / milliseconds
  gravityX: 0,
  gravityY: 1.2,
  mass: 0.06, // ~kg
  drag: 1 / 90,
  lifetimeMin: 2000, // milliseconds
  lifetimeMax: 4000, // milliseconds
  count: 60,
  box: {
    x: 0,
    y: 0,
    height: 1000
  },
  leftBound: false,
  topBound: false,
  rightBound:false,
  bottomBound: false,
};

const rightFontan = {
  x0min: 615 , // px
  x0max: 615, // px
  y0min: 175, // px
  y0max: 275, // px
  scaleStart: 0.5,
  scaleEnd: 1,
  angle0From: Math.PI / 180 * -75,
  angle0To: Math.PI / 180 * -45,
  angleVelocityMin: 0.005,
  angleVelocityMax: 0.006,
  v0min: 2, // px / milliseconds
  v0max: 2.5, // px / milliseconds
  gravityX: 0,
  gravityY: 1.2,
  mass: 0.06, // ~kg
  drag: 1 / 90,
  lifetimeMin: 2000, // milliseconds
  lifetimeMax: 4000, // milliseconds
  count: 60,
  box: {
    x: 0,
    y: 0,
    height: 1000
  },
  leftBound: false,
  topBound: false,
  rightBound:false,
  bottomBound: false,
};

export default {
  leftFontan,
  rightFontan
};
