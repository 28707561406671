import { store } from '../../../index';
import selectors from '../../../redux/game/selectors';
import { sleep } from '../../../utils/sleep';
import actionsModals from '../../../redux/modals/actions';
import actions from '../../../redux/game/actions';
import { eGameState } from '../../../redux/game/reducer';
import eModalType from '../../popups/eModalType';
import ControllerBehaviorBase from './controllerBehaviorBase';

export default class ControllerSlotStartFreespinsBehaviour extends ControllerBehaviorBase {
  constructor(slotController) {
    super(slotController);
    this._slotController = slotController;
  }

  endSpin() {
    const state = store.getState();
    const winLines = selectors.getWinLine(state);

    this._slotController.startWinAnimation(winLines, true).then(async delay => {
      this._slotController.preBonusGame.updateProgress();
      await sleep(1500);
      store.dispatch(actionsModals.showModal({ type: eModalType.EMT_TRANSITION }));

      await sleep(3600);

      this._slotController.vines.hide(delay);

      store.dispatch(actions.closePreBonusGame());
      await sleep(1000);
      store.dispatch(actions.tryShowBonusGame());

      this._slotController.freespinsPanel.update();

      await sleep(2000);

      this.updateFreeBets();
      store.dispatch(actions.setGameState(eGameState.EGS_READY_TO_PLAY));

    });
  }
}
