import selectors from '../../redux/game/selectors';
import { storeObserver } from '../../index';
import { store } from '../../index';
import actions from '../../redux/game/actions';
import { eGameState } from '../../redux/game/reducer';
import { formatCurrency } from '../../utils/math';

export default class ControllerBetSelector {

  constructor(container) {
    this.buttonMinus = container.getChildByName('buttonMinus');
    this._setClickableArea(this.buttonMinus);
    this.buttonMinus.addListener('pointertap', this.onMinusClicked);
    this.buttonPlus = container.getChildByName('buttonPlus');
    this._setClickableArea(this.buttonPlus);
    this.buttonPlus.addListener('pointertap', this.onPlusClicked);

    this.labelBet = container.getChildByName('label');

    this.dispatch = store.dispatch;

    storeObserver.addListener(selectors.getGameState, this.onGameStateChanged);
    this.currencyInfo = storeObserver.addListenerAndGetValue(selectors.getCurrencyInfo, this.currencyInfoChanged);
    const betAmount = storeObserver.addListenerAndGetValue(selectors.getBetAmount, this.onBetAmountChanged);
    this.onBetAmountChanged(betAmount);

    const freespinsEnabled = selectors.getFreespinsEnabled(store.getState());
    this.setButtonsEnable(
      !freespinsEnabled
      && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet
    );
  }

  setButtonsEnable = (value) => {
    this.buttonMinus.enabled = value;
    this.buttonPlus.enabled = value;
  };

  onGameStateChanged = (gameState) => {
    const state = store.getState().game;
    this.setButtonsEnable(
      gameState === eGameState.EGS_READY_TO_PLAY
      && !state.autospin
      && !state.bonusGameShowed
      && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet
    );
  };

  onBetAmountChanged = (value) => {
    this.labelBet.text = this.normalizeValue(value);
  };

  currencyInfoChanged = (value) => {
    this.currencyInfo = value;
  };

  onPlusClicked = () => {
    this.dispatch(actions.incrementBet());
  };

  onMinusClicked = () => {
    this.dispatch(actions.decrementBet());
  };

  normalizeValue(value) {
    const decimals = Math.min(value.getPrecision(), this.currencyInfo.decimal);
    return formatCurrency(value, decimals);
  }
  onChangeActiveBetInOPWrapper = (index) => {
    this.dispatch(actions.setBet(index));

  }

  _setClickableArea = (pixiObj) => {
    const x = pixiObj.displayData.width / 2;
    const y = pixiObj.displayData.height / 2;
    const radius = pixiObj.displayData.width;
    const hitArea = new PIXI.Circle(x, y,radius );

    pixiObj.hitArea = hitArea;
  }
}
