import FlashLib from 'flashlib';
import { TweenMax } from 'gsap';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import { yellowToOrangeAndStroke } from '../../fontStyles';
import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import eBonusGameScorpionAnimations from './eBonusGameScorpionAnimations';
import ControllerSounds, { eSoundType } from '../sounds/controllerSounds';
import { sleep } from '../../utils/sleep';

export default class ControllerBonusGameScorpion extends FlashLib.MovieClip {
  constructor(data, $displayItemData) {
    super(data, $displayItemData);
    this.progressBlock = this.getChildByName('progress_block');
    this.ruleCloud = this.getChildByName('cloud');
    this.maskArea = this.progressBlock.getChildByName('maskArea');
    this.barOffset = 50;
    this.barWidth = 540;
    this.alpha = 0;

    this.initAnimations();
    this.setDefaultState();

    for (let i = 0; i < 3; i++) {
      const textBlock = this.progressBlock.getChildByName(`text_block_${i}`);
      const amount = textBlock.getChildByName('bonus_game_scorp_freespin_amount');
      const text = textBlock.getChildByName('bonus_game_scorp_freespin_text');

      amount.style = {
        ...amount.style,
        ...yellowToOrangeAndStroke
      };
      text.style = {
        ...text.style,
        ...yellowToOrangeAndStroke
      };

      text.fitSize(true, true);
    }
    this.step = storeObserver.addListenerAndGetValue(selectors.getBonusGameScorpionProgress, this.onProgress);
    const BonusGameShowed = storeObserver.addListenerAndGetValue(selectors.getBonusGameShowed, this.onShowed );
    this.onShowed(BonusGameShowed);
    this.onProgress(this.step);
    this.updateBarPosition();
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {

    if (PIXI.utils.isMobile.any && data.isLandscape) {
      this.ruleCloud.pivot.x = 0;
      this.ruleCloud.x = this.ruleCloud.displayData.x;
      this.ruleCloud.scale.set(1);
      this.ruleCloud.y = this.ruleCloud.displayData.y;

      this.progressBlock.pivot.x = 0;
      this.progressBlock.x = this.progressBlock.displayData.x;
      this.progressBlock.scale.set(1);
      this.progressBlock.y = this.progressBlock.displayData.y;

    } else if (PIXI.utils.isMobile.any) {
      this.ruleCloud.pivot.x = this.ruleCloud.displayData.width / 2;
      this.ruleCloud.x = this.displayData.width / 2 + 100;
      this.ruleCloud.scale.set(2.1);
      this.ruleCloud.y = -1090;

      this.progressBlock.pivot.x = this.progressBlock.displayData.width / 2;
      this.progressBlock.x = this.displayData.width / 2 + 228;
      this.progressBlock.scale.set(1.8);
      this.progressBlock.y = -580;
    }
  }

  initAnimations = () => {
    this.animationFrame = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_GAME_SCORPION);
    this.animationBar = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_GAME_SCORPION);
    this.animationX2 = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_GAME_SCORPION);
    this.animationX3 = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_GAME_SCORPION);
    this.animationX10 = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_GAME_SCORPION);

    this.animationBar.mask = this.maskArea;

    const defaultX = 413;
    const defaultY = 155;

    const defaultIndicatorY = 108;

    this.animationFrame.x = defaultX;
    this.animationFrame.y = defaultY;
    this.animationBar.x = defaultX;
    this.animationBar.y = defaultY;
    this.animationX2.x = 274;
    this.animationX2.y = defaultIndicatorY;
    this.animationX3.x = 438;
    this.animationX3.y = defaultIndicatorY;
    this.animationX10.x = 670;
    this.animationX10.y = defaultIndicatorY;

    this.barDefoltConfig ={
      x: this.animationBar.x - this.barWidth + this.barOffset,
      distance: this.barWidth - this.barOffset,
    };

    this.progressBlock.addChild(this.animationBar, this.animationFrame, this.animationX2, this.animationX3, this.animationX10);
  }

  setDefaultState = () => {
    this.visible = false;
    this.activeMultipliers = {
      x2: false,
      x3: false,
      x10: false,
    };

    this.animationFrame.visible = false;
    this.animationBar.visible = false;
    this.animationX2.visible = false;
    this.animationX3.visible = false;
    this.animationX10.visible = false;

    this.animationFrame.state.setAnimation(0, eBonusGameScorpionAnimations.BGSA_FRAME_STATIC, false);
    this.animationBar.state.setAnimation(0, eBonusGameScorpionAnimations.BGSA_BAR_STATIC, false);
    this.animationX2.state.setAnimation(0, eBonusGameScorpionAnimations.BGSA_X2_STATIC, false);
    this.animationX3.state.setAnimation(0, eBonusGameScorpionAnimations.BGSA_X3_STATIC, false);
    this.animationX10.state.setAnimation(0, eBonusGameScorpionAnimations.BGSA_X10_STATIC, false);
  }

  onShowed = (isShowed) => {
    isShowed ? this.show() : this.hide();
  }

  show = () => {
    const bonusGameType = selectors.getBonusGameType(store.getState());
    if (this.alpha === 1 || bonusGameType !== 3) return;
    this.updateBarPosition();
    this.visible = true;
    this.showAnimations();
    this.isFirstRun = true;
    TweenMax.to(this, 0.5, { alpha: 1 } );
    ControllerSounds.replaceSFX(eSoundType.EST_SFX_BONUS_GAME_SCORPION);
  }

  showAnimations = () => {
    this.animationFrame.visible = true;
    this.animationBar.visible = true;
    this.animationX2.visible = true;
    this.animationX3.visible = true;
    this.animationX10.visible = true;
  }

  hide = () => {
    if (this.alpha === 0) return;
    TweenMax.to(this, 0.5, { alpha: 0, onComplete: this.setDefaultState });
  }

  onProgress = (step) => {
    this.step = step;
    if (step > 30) this.step = 30;
    this.progress = this.step / 30;
    // this.updateBarPosition();
  }

  async updateBarPosition() {
    if (!this.isFirstRun) await sleep(1200);
    this.isFirstRun = false;
    this.animationBar.state.setAnimation(0, eBonusGameScorpionAnimations.BGSA_BAR_STEP, false);
    this.animationBar.state.setAnimation(0, eBonusGameScorpionAnimations.BGSA_BAR_STATIC, false, 0);
    this.animationFrame.state.setAnimation(0, eBonusGameScorpionAnimations.BGSA_FRAME_BLINK, false);
    this.animationFrame.state.addAnimation(0, eBonusGameScorpionAnimations.BGSA_FRAME_STATIC, false, 0);

    const xPosition = this.barDefoltConfig.x + this.barDefoltConfig.distance * this.progress;
    TweenMax.to(this.animationBar, 0.5, { x: xPosition, onComplete: this.updateMultipliersAnimation });
  }

  updateMultipliersAnimation = () => {
    const multiplier = selectors.getBonusGameScorpionMultiplier(store.getState());

    if (multiplier >= 2 && !this.activeMultipliers.x2) {
      this.activeMultipliers.x2 = true;
      this.animationX2.state.setAnimation(0, eBonusGameScorpionAnimations.BGSA_X2_START, false);
      this.animationX2.state.addAnimation(0, eBonusGameScorpionAnimations.BGSA_X2_IDLE, true, 0);

    }
    if (multiplier >= 3 && !this.activeMultipliers.x3) {
      this.activeMultipliers.x3 = true;
      this.animationX3.state.setAnimation(0, eBonusGameScorpionAnimations.BGSA_X3_START, false);
      this.animationX3.state.addAnimation(0, eBonusGameScorpionAnimations.BGSA_X3_IDLE, true, 0);

    }
    if (multiplier === 10 && !this.activeMultipliers.x10) {
      this.activeMultipliers.x10 = true;
      this.animationX10.state.setAnimation(0, eBonusGameScorpionAnimations.BGSA_X10_START, false);
      this.animationX10.state.addAnimation(0, eBonusGameScorpionAnimations.BGSA_X10_IDLE, true, 0);

    }
  }

}
