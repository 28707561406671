import FlashLib from 'flashlib';
import selectors from '../../redux/game/selectors';
import { store } from '../../index';

export default class ControllerHeart extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.index = -1;
  }
  show = () => {
    this.visible = true;
  }
  hide = () => {
    this.visible = false;
  }
  update = ()=> {
    const lives = selectors.getBonusGameCobraLives(store.getState());
    lives > this.index ? this.show() : this.hide();
  }
}
