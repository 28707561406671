import FlashLib from 'flashlib';

export default class LocalizeTextField extends FlashLib.TextField {
  constructor(data, displayData) {
    super(data, displayData);
    this.LocalizationManager = window.OPWrapperService.localizations;
    this.LocalizationManager.addTextField(this);

    const prefferedFontFamily = this.style.fontFamily;
    const fallbacks = ['Ubuntu bold'];

    if (/regular|medium/i.test(prefferedFontFamily)) {
      fallbacks.push('Inter Semi Bold');
    } else {
      fallbacks.push('Inter Extra Bold');
    }

    this.style.fontFamily = [ prefferedFontFamily, ...fallbacks ];
  }

  setTextParams(...params) {
    this.text = this.LocalizationManager.getLocalizedText(this.name, params);
  }

  destroy(options) {
    this.LocalizationManager.removeTextField(this);
    super.destroy(options);
  }

  fitSize() {
    super.fitSize(true, true);
  }
}
