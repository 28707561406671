import animationsLoader from '../../animations/animationsLoader';
import eAnimationType from '../../animations/eAnimationType';
import ePopupBonusPurchaseAnimation from './ePopupBonusPurchaseAnimation';
import * as PIXI from 'pixi.js';
import TweenMax, { Elastic } from 'gsap';
import { whiteYeelowOrange } from '../../../fontStyles';
import { store, storeObserver } from '../../../index';
import selectors from '../../../redux/game/selectors';
import { formatCurrency } from '../../../utils/math';
import ParticleSystem from '../../../utils/particles/particleSystem';
import particleConfigBonusPurchase from './particleConfigBonusPurchase';

export default class ControllerBonusPurchaseCard {
  constructor(container, type, index) {
    this.container = container;
    this.index = index;
    this.card = this.container.getChildByName('wrapper').getChildByName(`card_${this.index}`);
    this.pricePanel = this.card.getChildByName(`pricePanel${this.index}`);
    this.modal = this.container.getChildByName('modal');
    this.priceText = this.pricePanel.getChildByName('text');
    this.pricePanel.pivot.set(this.pricePanel.width / 2, this.pricePanel.height / 2);
    this.pricePanel.x += this.pricePanel.width / 2;
    this.pricePanel.y += this.pricePanel.height / 2;
    this.clicked = false;

    this.bonusGameType = type;

    this.setTextStyle();
    this.initAnimations();
    this.scaledItems = [this.animation.scale, this.text.scale, this.pricePanel.scale];

    storeObserver.addListener(selectors.getBetAmount, this.updatePrice);
    this.updatePrice();
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );

  }

  onResize = (data) => {
    if (!PIXI.utils.isMobile.any) return;

    const offsets = {
      landscape: {
        [0]: { x: 0, y: 0 },
        [1]: { x: 0, y: 0 },
        [2]: { x: 0, y: 0 }
      },
      portrait: {
        [0]: { x: 60, y: 0 },
        [1]: { x: 0, y: 200 },
        [2]: { x: -60, y: 0 }
      }
    };
    const view = data.isLandscape ? 'landscape' : 'portrait';
    this.card.x = this.card.displayData.x + offsets[view][this.index].x;
    this.card.y = this.card.displayData.y + offsets[view][this.index].y;
  }

  updatePrice = () => {
    const state = store.getState();
    const amount = selectors.getBonusPrices(state)[this.bonusGameType];
    const currencyInfo = selectors.getCurrencyInfo(state);

    this.priceText.text = formatCurrency(amount, currencyInfo.decimal) + ` ${currencyInfo.currency}`;
  };

  initAnimations = () => {
    this.animation = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_PURCHASE);
    this.animation.x = this.card.displayData.width / 2;
    this.animation.y = this.card.displayData.height / 2 - 144;
    this.animation.buttonMode = true;

    this.animation.state.addListener({
      complete: () => {
        this.animation.state.setAnimation(0, ePopupBonusPurchaseAnimation[`EPBPA_HIGH_${this.index + 1}_CARD_IDLE`], true);
      }
    });
    this.animation.state.setAnimation(0, ePopupBonusPurchaseAnimation[`EPBPA_HIGH_${this.index + 1}_CARD_IDLE`], true);
    this.animation.visible = false;
    this.card.addChildAt(this.animation, 0);
    const rect = new PIXI.Rectangle(
      -202,
      -310,
      404,
      510
    );

    this.animation.hitArea = rect;
    this.animation.interactive = true;

    this.animation.on('mouseover', this.onAnimationOver, this);
    this.animation.on('mouseout', this.onAnimationOut, this);
    this.animation.on('pointertap', this.onAnimationClick, this);

  };
  onAnimationOver = () => {
    if (this.clicked) return;
    TweenMax.killTweensOf(this.scaledItems);
    const duration = 0.4;
    TweenMax.to(this.scaledItems, duration, {
      x: 1.08,
      y: 1.08
    });
  };
  onAnimationOut = () => {
    if (this.clicked) return;
    TweenMax.killTweensOf(this.scaledItems);
    const duration = 0.4;
    TweenMax.to(this.scaledItems, duration, { x: 1, y: 1 });
  };
  onAnimationClick = () => {
    this.animation.state.setAnimation(0, ePopupBonusPurchaseAnimation[`EPBPA_HIGH_${this.index + 1}_CARD_BUY`], true);
    TweenMax.killTweensOf(this.scaledItems);

    this._animateClickIn();
  };

  setTextStyle() {
    this.text = this.card.getChildByName(`popup_bonus_purchase_0_${this.index + 1}`);
    this.text.pivot.set(this.text.width / 2, this.text.height / 2);
    this.text.x += this.text.width / 2;
    this.text.y += this.text.height / 2;
    this.text.style = { ...this.text.style, ...whiteYeelowOrange };
  }

  _animateClickIn = () => {
    this.clicked = true;
    const scale = 0.16;
    const timeStart = Date.now();
    TweenMax.to(this.scaledItems, 0.1, {
      x: this.animation.scale.x - scale,
      y: this.animation.scale.y - scale,
      onComplete: () => {
        this._animateClickOut(scale, timeStart);
      },
    });
  };

  _animateClickOut = (scale, timeStart) => {
    TweenMax.to(this.scaledItems, 1, {
      ease: Elastic.easeOut.config(1, 0.3),
      x: this.animation.scale.x + scale / 2,
      y: this.animation.scale.y + scale / 2,
      onUpdate: () => {
        if (Date.now() - timeStart >= 0.6 * 1000) {
          this._onClickCallback();
        }
      },
      onComplete: this.onAnimationOut,
    });
  };

  _onClickCallback() {
    this.clicked = false;
    this.modal.show(this.bonusGameType);
  }

  start = () => {
    this.animation.visible = true;
    this.showPartcles();
  }

  stop = () => {
    this.animation.visible = false;
    this.stopParticles();
  }

  showPartcles = () => {
    const texture = PIXI.Texture.from('starsParticle');
    const width = 200;
    for (let i = 0; i < 3; i++) {
      const container = new PIXI.Container();
      container.pivot.x = width / 2;
      container.x = this.card.displayData.width / 2;
      container.y = -266;
      if (!this[`particle${i}`]) {
        this[`particle${i}`] = new ParticleSystem(container, { texture: texture }, {
          ...particleConfigBonusPurchase,
          x0max: width, // px
        });
        this.card.addChildAt(container, 0);
      } else {
        this[`particle${i}`].start();
      }
    }
  }

  stopParticles = () => {
    for (let i = 0; i < 3; i++) {
      this[`particle${i}`].stop();
    }
  }
}
