import FlashLib from 'flashlib';
import { store, storeObserver } from '../../index';
import { TweenMax } from 'gsap';
import { freeSpinsPanelValue, yellowToOrangeAndStroke } from '../../fontStyles';
import selectors from '../../redux/game/selectors';
import { formatCurrency } from '../../utils/math';
import ControllerSounds, { eSoundType } from '../sounds/controllerSounds';

export default class FreespinsPanel extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.alpha = 0;
    this.isHide = true;

    this._totalWin = 0;

    this.setFrameState(1);

    storeObserver.addListener(selectors.getBonusGameShowed, this.onShowed );
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
    this.init();
  }

  onResize = (data) => {

    const bonusGameType = selectors.getBonusGameType(store.getState());
    const capitals = this.getChildByName('capitals');
    const portretCapitals = this.getChildByName('mobile_capitals');

    if (capitals) {
      capitals.visible = true;
      portretCapitals.visible = false;
    }

    if (PIXI.utils.isMobile.any && data.isLandscape) {
      this.setMobileLandscapePositions();

    } else if (PIXI.utils.isMobile.any) {
      switch (bonusGameType) {
        case 1:
          this.setMobilePortretCrocodilePositions();
          break;
        case 2:
          this.setMobilePortretCobraPositions();
          break;
        case 3:
          if (capitals) {
            capitals.visible = false;
            portretCapitals.visible = true;
          }
          this.setMobilePortretScorpionPositions();
          break;
      }
    }
  }

  setMobileLandscapePositions = () => {
    if (this.freespinsPanel) {
      this.freespinsPanel.scale.set(1);
      this.freespinsPanel.pivot.x = 0;
      this.freespinsPanel.x = this.freespinsPanel.displayData.x;
      this.freespinsPanel.y = this.freespinsPanel.displayData.y;
    }

    this.totalWinPanel.scale.set(1);
    this.totalWinPanel.pivot.x = 0;
    this.totalWinPanel.x = this.totalWinPanel.displayData.x;
    this.totalWinPanel.y = this.totalWinPanel.displayData.y;

    if (this.heartsPanel) {
      this.heartsPanel.scale.set(1);
      this.heartsPanel.pivot.x = 0;
      this.heartsPanel.x = this.heartsPanel.displayData.x;
      this.heartsPanel.y = this.heartsPanel.displayData.y;
    }
    if (this.cobraCloud) {
      this.cobraCloud.pivot.x = 0;
      this.cobraCloud.x = this.cobraCloud.displayData.x;
      this.cobraCloud.scale.set(1);
      this.cobraCloud.y = this.cobraCloud.displayData.y;
    }
  }

  setMobilePortretCrocodilePositions = () => {
    const scale = 1.3;
    const offsetY = -35;
    this.freespinsPanel.scale.set(scale);
    this.freespinsPanel.y = this.freespinsPanel.displayData.y + offsetY;

    this.totalWinPanel.pivot.x = this.totalWinPanel.displayData.width;
    this.totalWinPanel.x = this.totalWinPanel.displayData.x + this.totalWinPanel.displayData.width;
    this.totalWinPanel.scale.set(scale);
    this.totalWinPanel.y = this.totalWinPanel.displayData.y + offsetY;
  }

  setMobilePortretScorpionPositions = () => {
    const scale = 1.67;
    const offsetY = -90;
    this.freespinsPanel.scale.set(scale);
    this.freespinsPanel.x = -325;
    this.freespinsPanel.y = this.freespinsPanel.displayData.y + offsetY;

    this.totalWinPanel.pivot.x = 0;
    this.totalWinPanel.x = -1280;
    this.totalWinPanel.scale.set(scale);
    this.totalWinPanel.y = this.totalWinPanel.displayData.y + offsetY;
  }

  setMobilePortretCobraPositions = () => {
    const scale = 2;
    const offsetY = -110;
    this.heartsPanel.scale.set(scale);
    this.heartsPanel.x = -1340;
    this.heartsPanel.y = this.heartsPanel.displayData.y + offsetY;

    this.totalWinPanel.pivot.x = 0;
    this.totalWinPanel.x = -430;
    this.totalWinPanel.scale.set(scale);
    this.totalWinPanel.y = this.totalWinPanel.displayData.y + offsetY;

    this.cobraCloud.pivot.x = this.cobraCloud.displayData.width / 2;
    this.cobraCloud.x = this.cobraCloud.displayData.x + this.cobraCloud.displayData.width / 2;
    this.cobraCloud.scale.set(scale);
    this.cobraCloud.y = -1200;
  }

  init() {
    const state = store.getState().game;

    if (!state.freespinsEnabled) {
      this._totalWin = 0;
    }

    this._totalWin = state.totalWinFS || 0;

    this.label.style = this.labelTW.style = freeSpinsPanelValue;
  }

  update() {
    const state = store.getState().game;

    const freeSpinsCount = state.freespinsCount || 0;
    if (this.label) this.label.text = freeSpinsCount.toString();

    this._totalWin = state.totalWinFS || 0;
    this._totalWin = this.normalizeValue(this._totalWin);
    this.labelTW.text = this._totalWin.toString();
  }

  setValue() {
    const state = store.getState().game;

    // if (!state.freeSpinsMax) return;

    this._totalWin = state.totalWinFS || 0;
    this._totalWin = this.normalizeValue(this._totalWin);
    this.labelTW.text = this._totalWin.toString();
  }

  decrease() {
    const freeSpinsCount = store.getState().game.freespinsCount - 1;
    this.label.text = (freeSpinsCount).toString();
  }

  resetTotalWin() {
    this._totalWin = 0;
    this.labelTW.text = this._totalWin.toString();
  }

  tryUpdateHearts = () => {
    const bonusGameType = selectors.getBonusGameType(store.getState());
    if (bonusGameType !== 2) return;
    if ( !this.hearts || this.hearts.length === 0) {
      this.hearts = [];
      for (let i = 0; i < 3; i++) {
        const heart = this.heartsPanel.getChildByName(`heart_${i}`);
        heart.index = i;
        this.hearts.push(heart);
      }
    }
    for (let i = 0; i < 3; i++) {
      this.hearts[i].update();
    }
  }

  onShowed = (value) => {
    value ? this.show() : this.hide();
  }

  show() {
    const bonusGameType = selectors.getBonusGameType(store.getState());
    const frame = bonusGameType;
    this.goToFrame(bonusGameType);
    this.setFrameState(frame);
    const scaleData = window.OPWrapperService.ScaleManager.currentData;
    if (scaleData) this.onResize(scaleData);
    this.update();
    this.tryUpdateHearts();
    if (!this.isHide) return;
    this.isHide = false;
    TweenMax.to(this, 0.5, { alpha: 1 });
    if (bonusGameType === 2 /*cobra*/) ControllerSounds.replaceSFX(eSoundType.EST_SFX_BONUS_GAME_COBRA);
  }

  setFrameState = (frame) => {
    if (this.currentFrame === frame) return;
    this.currentFrame = frame;
    this.freespinsPanel = this.getChildByName('freespin_panel');
    this.totalWinPanel = this.getChildByName('total_win_panel');
    this.heartsPanel = this.getChildByName('hearts_panel');
    this.cobraCloud = this.getChildByName('cobra_cloud');

    this.label = this.freespinsPanel?.getChildByName('label');
    if (this.label) this.label.style = { ...this.label.style, ...yellowToOrangeAndStroke };
    this.labelTW = this.totalWinPanel?.getChildByName('label_total_win');
    this._freespinsHeadline = this.freespinsPanel?.getChildByName('headline_freespins');
    const LocalizationManager = window.OPWrapperService.localizations;
    if (this._freespinsHeadline) {
      this._freespinsHeadline.style = { ...this._freespinsHeadline.style, ...yellowToOrangeAndStroke };
      this._freespinsHeadline.text = LocalizationManager.getLocalizedText('game_spins_left');
    }
    this.heartsPanel = this.getChildByName('hearts_panel');
    if (this.heartsPanel) {
      this.livesHeadLine = this.heartsPanel.getChildByName('headline_lives');
      this.livesHeadLine.style = { ...this.livesHeadLine.style, ...yellowToOrangeAndStroke };
      this.livesHeadLine.text = LocalizationManager.getLocalizedText('game_total_lives');
    }
    this.labelTW.style = { ...this.labelTW.style, ...yellowToOrangeAndStroke };
    this._totalWinHeadline = this.totalWinPanel?.getChildByName('headline_total_win');
    this._totalWinHeadline.style = { ...this._totalWinHeadline.style, ...yellowToOrangeAndStroke };
    this._totalWinHeadline.text = LocalizationManager.getLocalizedText('game_total_win');

  }

  hide() {
    if (this.isHide) return;
    this.isHide = true;
    this.hearts = [];
    TweenMax.to(this, 0.5, { alpha: 0, onComplete: () => this.resetTotalWin() });
  }

  normalizeValue(value) {
    const currencyInfo = selectors.getCurrencyInfo(store.getState());
    const decimals = Math.min(value.getPrecision(), currencyInfo.decimal);
    return formatCurrency(value, decimals);
  }
}
