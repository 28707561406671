import ModalBase from '../base/modalBase';
import eModalType from './eModalType';
import eSymbolType from '../symbol/eSymbolType';
import selectors from '../../redux/game/selectors';
import { store } from '../../index';
import { yellowToOrangeAndStroke } from '../../fontStyles';
import LocalizeTextField from '../../i18n/LocalizeTextField';
import { formatCurrency } from '../../utils/math';

export default class ModalPaytable extends ModalBase {

  constructor(data, displayData) {
    super(data, displayData);

    this.type = eModalType.EMT_PAYTABLE;
    this.initListener();
    // this.initBlocker();

    this.slideCount = 6;

    this.slides = [];
    this.mobileSlides = [];

    this.currentSlide = 0;
    this.slidesBlock = this.getChildByName('slides');
    this.mobileSlidesBlock = this.getChildByName('slides_mobile');
    this.background = this.getChildByName('bg');

    this.paytable = selectors.getPaytable(store.getState());

    for (let i = 0; i < this.slideCount; i++) {
      const slide = this.slidesBlock.getChildByName(`slide_${i}`);
      slide.visible = i === this.currentSlide;
      this.slides.push(slide);

      slide.children.forEach(child => {
        if (child instanceof LocalizeTextField) {
          child.style = { ...child.style, ...yellowToOrangeAndStroke };
        }
      });
      if ([2, 3, 4].includes(i)) {
        this._setTotalWin(slide, i);
      }
    }

    for (let i = 0; i < this.slideCount; i++) {
      const slide = this.mobileSlidesBlock.getChildByName(`slide_${i}`);
      slide.visible = i === this.currentSlide;
      this.mobileSlides.push(slide);

      let text0 = slide.getChildByName(`page_${i + 1}_0`);
      if (!text0) text0 = slide.getChildByName(`mobile_page_${i + 1}_0`);
      let text1 = slide.getChildByName(`page_${i + 1}_1`);
      if (!text1) text1 = slide.getChildByName(`mobile_page_${i + 1}_1`);

      if (!text0 || !text1) continue;

      text0.style = {
        ...text0.style,
        ...yellowToOrangeAndStroke
      };

      text1.style = {
        ...text1.style,
        ...yellowToOrangeAndStroke,
      };
      if ([2, 3, 4].includes(i)) {
        this._setTotalWin(slide, i);
      }
    }

    this.initSlide2();
    this.initSlide3();

    this.slidesBlock.getChildByName('button_close').addListener('pointertap', this.onCloseClicked);
    this.mobileSlidesBlock.getChildByName('button_close').addListener('pointertap', this.onCloseClicked);

    const buttonNext = this.slidesBlock.getChildByName('button_right');
    buttonNext.on('pointertap', this.onNextClicked);
    buttonNext.interactive = true;
    buttonNext.buttonMode = true;

    const buttonPrev = this.slidesBlock.getChildByName('button_left');
    buttonPrev.on('pointertap', this.onPrevClicked);
    buttonPrev.interactive = true;
    buttonNext.buttonMode = true;

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );

    this.background.interactive = true;
    this.background.pointerdown = this.onPointerDown;
  }

  onPointerDown = (e) => {
    const startTouchPoint = e.data.getLocalPosition(this.background);
    this.background.pointerup = (event)=>{
      const endTouchPoint = event.data.getLocalPosition(this.background);
      this.checkSwipe(startTouchPoint, endTouchPoint);
    };

  }
  checkSwipe = (startPoint, endPoint) => {
    const distance = endPoint.x - startPoint.x;
    if (distance > 20) {
      this.onPrevClicked();
    } else if (distance < -20) {
      this.onNextClicked();
    }
  }

  onResize = (data) => {
    if (PIXI.utils.isMobile.any && !data.isLandscape) {
      this.slidesBlock.visible = false;
      this.mobileSlidesBlock.visible = true;

      this.mobileSlidesBlock.pivot.x = this.mobileSlidesBlock.displayData.width / 2;
      this.mobileSlidesBlock.pivot.y = this.mobileSlidesBlock.displayData.height / 2;

      this.mobileSlidesBlock.x = this.displayData.width / 2 - 10;
      this.mobileSlidesBlock.y = this.displayData.height / 2 - 200;
    } else {
      this.slidesBlock.visible = true;
      this.mobileSlidesBlock.visible = false;
    }
  }

  _setTotalWin(slide, i) {
    const totalWin = slide.getChildByName(`total_win`);
    totalWin.style = {
      ...totalWin.style,
      ...yellowToOrangeAndStroke
    };
    const state = store.getState();
    const currencyInfo = selectors.getCurrencyInfo(state);
    const bets = selectors.getBets(state);
    totalWin.text = `${formatCurrency(bets[(bets.length || 1) - 1] * this.paytable[eSymbolType.EST_HIGH_3][this.paytable[eSymbolType.EST_HIGH_3].length - 3] * i)} ${currencyInfo.currency}`;
    totalWin.correctPosition = function () {
      LocalizeTextField.prototype.correctPosition.call(this, 'center', 'center')
    };
    totalWin.fitSize(true);
  }

  initSlide2() {
    /* eslint-disable no-unused-vars */
    const symbols = [eSymbolType.EST_HIGH_3, eSymbolType.EST_HIGH_2, eSymbolType.EST_HIGH_1];
    //todo: why this line is commented ???
    // this.initCoefsLabels(this.slides[2], symbols);
  }

  initSlide3() {
    /* eslint-disable no-unused-vars */
    const symbols = [
      eSymbolType.EST_LOW_PURPLE,
      eSymbolType.EST_LOW_GREEN,
      eSymbolType.EST_LOW_BLUE,
      eSymbolType.EST_LOW_ORANGE,
    ];

    //todo: why this line is commented ???
    // this.initCoefsLabels(this.slides[3], symbols);
  }

  initCoefsLabels(slide, symbols) {
    for (let type of symbols) {
      const item = slide.getChildByName(`coefs_view_${type}`);
      for (let i = 2; i < 7; i++) {
        let textPair = item.getChildByName(`coef_view_${i - 2}`);
        textPair.getChildByName('viewCount').text = i + 1;
        textPair.getChildByName('viewCoef').text = `×${this.paytable[type][i]}`;
      }
    }
  }

  onPrevClicked = () => {
    this.slides[this.currentSlide].visible = false;
    this.mobileSlides[this.currentSlide].visible = false;
    this.currentSlide = (this.slideCount + this.currentSlide - 1) % this.slideCount;
    this.slides[this.currentSlide].visible = true;
    this.mobileSlides[this.currentSlide].visible = true;

  };

  onNextClicked = () => {
    this.slides[this.currentSlide].visible = false;
    this.mobileSlides[this.currentSlide].visible = false;
    this.currentSlide = (this.currentSlide + 1) % this.slideCount;
    this.slides[this.currentSlide].visible = true;
    this.mobileSlides[this.currentSlide].visible = true;

  };
}
