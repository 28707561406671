import ParticleSystemCircle from '../utils/particles/particleSystemCircle';

export default class ControllerSymbolParticles {
  constructor(container) {
    this.texture = PIXI.Texture.from('starsParticle');
    this.texture.alphaMode = PIXI.ALPHA_MODES.PMA;
    this.particles = [];
    this.count = 7;
    this.container = container;

    for (let i = 0; i < this.count; i++) {
      // this.particles.push(new ParticleSystemCircle(container, { texture: this.texture }, { x0: 250 * i, y0: 0 }));
    }
  }

  showParticlesOnIndex(index, isBezier, cycleCount = 2, isBonusGameScorpion) {
    const x0 = 250 * index;
    const y0 = 0;
    const isMobileAndPortret = PIXI.utils.isMobile.any && window.outerWidth < window.outerHeight;
    const desctopBezierConfigBonusGameScorpion = {
      p0: { x: x0, y: y0 },
      p1: { x: x0, y: -450 },
      p2: { x: -100, y: -360 }
    };
    const mobilePortretBezierConfigBonusGameScorpion = {
      p0: { x: x0, y: y0 },
      p1: { x: x0, y: -450 },
      p2: { x: 250, y: -850 }
    };
    const bezierConfigBonusGameScorpion = isMobileAndPortret ? mobilePortretBezierConfigBonusGameScorpion : desctopBezierConfigBonusGameScorpion;

    const bezierConfigPreBonusGame = {
      p0: { x: x0, y: y0 },
      p1: { x: x0, y: -450 },
      p2: { x: 1080, y: -300 }
    };

    let bezier;
    if (isBezier) {
      bezier = isBonusGameScorpion ? bezierConfigBonusGameScorpion : bezierConfigPreBonusGame;
    }

    const particleSystem = new ParticleSystemCircle(this.container, { texture: this.texture }, {
      x0,
      y0,
      count: 100,
      scaleStart: 1,
      scaleEnd: 0,
      angle0From: 0,
      angle0To: 2 * Math.PI,
      angleVelocityMin: 0.005,
      angleVelocityMax: 0.005,
      v0min: 0.05, // px / milliseconds
      v0max: 0.1, // px / milliseconds
      bezier,
      cycleCount
    });
    const oldParticleSystemIndex = this.particles.findIndex(item => item.index === index);
    if (oldParticleSystemIndex >= 0) {
      this.particles[oldParticleSystemIndex].particleSystem.pause();
      this.particles.splice(oldParticleSystemIndex, 1);
    }
    const data = { index, particleSystem };
    this.particles.push(data);
  }

  stop() {
    this.particles.forEach((item) => {
      item.particleSystem.stop();
    });
    this.particles = [];
  }

  pause() {
    this.particles.forEach((item) => {
      item.particleSystem.pause();
    });
  }
}
