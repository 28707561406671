import FlashLib from 'flashlib';
import { requestNewGame } from '../../api/rest';
import actions from '../../redux/game/actions';
import { eGameState } from '../../redux/game/reducer';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';

export default class ControllerControlPanel extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    storeObserver.addListenerAndGetValue(selectors.getGameState, this.onGameStateChanged);
    this.freeBetsController = window.OPWrapperService.freeBetsController;
  }
  onGameStateChanged = (gameState) => {

    if (this.currentState === gameState) return;
    this.currentState = gameState;
    const state = store.getState().game;
    switch (this.currentState) {
      case eGameState.EGS_READY_TO_PLAY:
        if (state.autospin || (state.bonusGameEnabled && state.freespinsCount !== 0 && state.bonusGameCobraLives !== 0)) {
          setTimeout(() => {
            store.dispatch(actions.setGameState(eGameState.EGS_START_AUTOSPIN));
          }, 1);
        } else {
        }
        break;

      case eGameState.EGS_WAITING_FOR_RESPONSE:
        if (!state.bonusGameShowed) {
          this.freeBetsController.decreaseFreeBets();
          OPWrapperService.ControllerStatistic.bet = state.betAmount;
        }
        OPWrapperService.realityCheck.blockedRealityCheck = true;
        break;

      case eGameState.EGS_CAN_STOP:
        break;

      case eGameState.EGS_START_AUTOSPIN:
        this.startAutoSpin();
        break;

      case eGameState.EGS_STOP_SPIN:
        if (state.autospin) {
          this.disableAutospin();
        }
        break;
    }
  };
  async startGame() {
    store.dispatch(actions.setGameState(eGameState.EGS_WAITING_FOR_RESPONSE));
    await requestNewGame();
  }

  startAutoSpin() {
    const state = store.getState();
    const amount = selectors.getBetAmount(state);
    const balance = selectors.getBalance(state);
    if (balance < amount && !state.game.freespinsEnabled && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet) {
      window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
      setTimeout(() => {
        store.dispatch(actions.stopAutospin());
        store.dispatch(actions.setGameState(eGameState.EGS_READY_TO_PLAY));
      }, 1);
      return;
    } else {
      this.startGame();
    }
  }

  disableAutospin() {
    store.dispatch(actions.stopAutospin());
    store.dispatch(actions.setGameState(eGameState.EGS_STOP_AUTOSPIN));
  }
}
