import { TimelineMax } from 'gsap';
import ReelAbstractState from './reelAbstractState';

export default class ReelStartSpinState extends ReelAbstractState {

  constructor(reel) {
    super(reel);

    this.init();
  }

  init() {
    this.animation = new TimelineMax({ paused: true });
    this.animation.to(this.reel.symbols, this.reel.startSpeed, {
      y: '-=' + (this.reel.config.symbols.height / 2),
      yoyo: true,
      repeat: 1,
      delay: this.reel.index * 0.1,
      onComplete: this.onComplete,
      callbackScope: this
    });
  }

  spin() {
    this.animation.invalidate();
    this.animation.restart();
  }

  onComplete() {
    this.reel.setState(this.reel.states.spin);
    this.reel.cycleSymbols();
    this.reel.state.spin();
  }
}
