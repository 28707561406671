import { getFromStorage, setToStorage } from '../../utils/localStorage';
import SoundManager from './SoundManager';
import Event from '../../utils/event';
import { TweenMax } from 'gsap';

export const eSoundType = {
  EST_BIG_WIN: 'bigWin',
  EST_MEGA_WIN: 'megaWin',
  EST_EPIC_WIN: 'epicWin',
  EST_CLICK: 'click',
  EST_COINS: 'coins',
  EST_SFX: 'sfx',
  EST_WIN: 'win',
  EST_REEL_STOP: 'reelStop',
  EST_SFX_PROGRESS_GAME_COBRA: 'bg_progress_bonus_cobra',
  EST_SFX_PROGRESS_GAME_SCORPION: 'bg_progress_bonus_scorpio',
  EST_SFX_PROGRESS_GAME_CROCODILE: 'bg_progress_bonus_crocodile',
  EST_SFX_BONUS_GAME_COBRA: 'bg_freespins_bonus_cobra',
  EST_SFX_BONUS_GAME_SCORPION: 'bg_freespins_bonus_scorpio',
  EST_SFX_BONUS_GAME_CROCODILE: 'bg_freespins_bonus_crocodile',
  EST_SFX_BIG_WIN: 'sfxWin',
  EST_SFX_REEL_STOP_WIDTH_PARTICLES: 'stopReelsWithParticles'
};

class ControllerSounds {

  constructor() {
    this.SFXVolume = 0.3;
    this.prevAliasSFX = this.aliasSFX = eSoundType.EST_SFX;
    this.musicMuted = getFromStorage('musicMuted');
    if (this.musicMuted === null) {
      setToStorage('musicMuted', 'false');
      this.musicMuted = false;
    }

    this.soundsMuted = getFromStorage('soundsMuted');
    if (this.soundsMuted === null) {
      setToStorage('soundsMuted', 'false');
      this.soundsMuted = false;
    }

    this.onSoundSettingsCahnged = new Event();
  }

  onStartScreenHide = () => {
    this.playSFX();
  };

  playSound(type) {
    if (this.soundsMuted) return;
    return SoundManager.play(type, 0.5, false);
  }

  stopSound(type) {
    SoundManager.stop(type);
  }

  muteSFX() {
    if (this.musicMuted === true) return;
    this.musicMuted = true;
    setToStorage('musicMuted', 'true');
    this.pauseSFX();
    this.onSoundSettingsCahnged.call();
  }

  unmuteSFX() {
    if (this.musicMuted === false) return;
    this.musicMuted = false;
    setToStorage('musicMuted', 'false');
    this.playSFX();
    this.onSoundSettingsCahnged.call();
  }

  replaceSFX(nextAlias) {
    if (this.aliasSFX === nextAlias) return;
    const prevSound = SoundManager.getSound(this.aliasSFX);
    if (!prevSound.isPlaying){
      this.aliasSFX = nextAlias;
      return;
    }

    TweenMax.killTweensOf(prevSound);
    TweenMax.to(prevSound, 0.5, { volume: 0, onComplete: () => {
      SoundManager.stop(this.aliasSFX);
      this._playNextSFX(nextAlias);
    } });
  }

  _playNextSFX(nextAlias) {
    this.aliasSFX = nextAlias;
    if (this.musicMuted) return;
    SoundManager.play(this.aliasSFX, this.SFXVolume, true);
    const sound = SoundManager.getSound(this.aliasSFX);
    TweenMax.killTweensOf(sound);
    sound.volume = 0;
    TweenMax.to(sound, 1.5, { volume: this.SFXVolume });
  }

  playBigWinSFX(volume = this.SFXVolume) {
    const nextAlias = eSoundType.EST_SFX_BIG_WIN;
    if (this.aliasSFX === nextAlias) return;
    this.prevAliasSFX = this.aliasSFX;
    if (this.soundsMuted) return;
    SoundManager.pause(this.prevAliasSFX);
    this.aliasSFX = nextAlias;
    SoundManager.play(this.aliasSFX, volume, true);
  }

  stopBigWinSFX() {
    this.replaceSFX(this.prevAliasSFX);
  }

  setSoundsMuted(value) {
    if (this.soundsMuted === value) return;
    this.soundsMuted = value;
    setToStorage('soundsMuted', value);
    this.onSoundSettingsCahnged.call();
  }

  playSFX = () => {
    if (this.musicMuted) return;
    const sound = SoundManager.getSound(this.aliasSFX);
    if (sound.isPlaying) return;
    this.musicMuted = false;
    setToStorage('musicMuted', 'false');
    SoundManager.play(this.aliasSFX, this.SFXVolume, true);
  };

  pauseSFX() {
    SoundManager.pause(this.aliasSFX, this.SFXVolume, true);
  }
}

export default new ControllerSounds();
