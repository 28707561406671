import ModalBase from '../../base/modalBase';
import eModalType from '../eModalType';
import { winAmountCounterStyle } from '../../../fontStyles';
import actionsModals from '../../../redux/modals/actions';
import ControllerSounds, { eSoundType } from '../../sounds/controllerSounds';
import animationsLoader from '../../animations/animationsLoader';
import constants from '../../../constants';
import eAnimationType from '../../animations/eAnimationType';
import { store } from '../../../index';
import selectors from '../../../redux/game/selectors';
import eAnimationNameBigWins from './eAnimationNameBigWins';
import TweenMax from 'gsap';
import { formatCurrency } from '../../../utils/math';
import ParticleSystem from '../../../utils/particles/particleSystem';
import particalConfigs from './particleConfigsBigWins';

export default class ModalBigWin extends ModalBase {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.type = eModalType.EMT_WIN_BIG;
    this.showed = false;
    this.normalScale = 1;
    this.eWinTypes = {
      EWT_BIG_WIN: 'BIG_WIN',
      EWT_MEGA_WIN: 'MEGA_WIN',
      EWT_EPIC_WIN: 'EPIC_WIN'
    };
    this.container = this.getChildByName('container');
    this.container.pivot.x = this.container.displayData.width / 2;
    this.container.pivot.y = this.container.displayData.height / 2;
    this.container.x += this.container.displayData.width / 2;
    this.container.y += this.container.displayData.height / 2;

    this.amount = new PIXI.BitmapText('0', winAmountCounterStyle);
    this.amount.anchor.set(0.5);
    this.amount.x = this.container.displayData.width / 2 - 20;
    this.amount.y = this.container.displayData.height;
    this.container.addChild(this.amount);

    this.particleContainer = new PIXI.Container();
    this.container.addChildAt(this.particleContainer, 0);

    this.titles = {
      [this.eWinTypes.EWT_BIG_WIN]: this.container.getChildByName('big_win'),
      [this.eWinTypes.EWT_MEGA_WIN]: this.container.getChildByName('mega_win'),
      [this.eWinTypes.EWT_EPIC_WIN]: this.container.getChildByName('epic_win'),
    };

    for (const key in this.titles) {
      this.titles[key].pivot.x = this.titles[key].displayData.width / 2;
      this.titles[key].pivot.y = this.titles[key].displayData.height / 2;
      this.titles[key].x = this.container.displayData.width / 2 - 20;
      // this.titles[key].y = this.container.displayData.height / 4;
      this.titles[key].visible = false;
    }

    this.bigWinIsStarted = false;
    this.megaWinIsStarted = false;
    this.epicWinIsStarted = false;

    this.init();
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );

  }
  onResize = (data) => {
    if (PIXI.utils.isMobile.any && data.isLandscape) {
      this.container.pivot.x = this.container.displayData.width / 2;
      this.container.pivot.y = this.container.displayData.height / 2;
      this.container.x = this.container.displayData.x + this.container.displayData.width / 2;
      this.container.y = this.container.displayData.y + this.container.displayData.height / 2;
      this.normalScale = 1;
      this.container.scale.set(this.normalScale);

      this.overlay.x = this.overlay.displayData.x;
      this.overlay.y = this.overlay.displayData.y;
      this.overlay.scale.set(this.overlay.displayData.scaleX);

    } else if (PIXI.utils.isMobile.any) {
      const scaleGame = 0.45;

      this.container.pivot.x = this.container.displayData.width / 2;
      this.container.pivot.y = this.container.displayData.height / 2;

      this.container.x = this.displayData.width / 2;
      this.container.y = this.displayData.height / 2 - 50;
      this.normalScale = scaleGame * 1.1;
      this.container.scale.set(this.normalScale);

      this.overlay.x = this.overlay.displayData.x - 630 * 2.166666666666667;
      this.overlay.y = this.overlay.displayData.y + 630;
      this.overlay.scale.set(2.166666666666667);

    }
  }

  init() {
    this.overlay = this.getChildByName('blackout');
    this.initAnimation();
    this.initListener();
    // this.on('pointertap', this.onClick);
    // document.addEventListener('keyup', event => this.onKeyDown(event), false);

    this.visible = false;

  }

  destroy() {
    super.destroy();
    this.off('pointertap', this.onClick);
  }

  initAnimation() {
    this.animation = animationsLoader.createAnimation(eAnimationType.EST_MODAL_BIG_WINS);
    this.animation.x = this.container.displayData.width / 2;
    this.animation.y = this.container.displayData.height / 2;
    this.animation.visible = false;

    this.container.addChildAt(this.animation, 0);
  }

  onModalDataChanged = (data) => {
    if (this.visible === data.visible) return;
    this.visible = data.visible;
    for (const key in this.titles) this.titles[key].visible = false;
    if (this.visible) this.show();
  };

  show = () => {
    const state = store.getState();
    this.winAmount = selectors.getWinAmount(state);
    this.betAmount = selectors.getBetAmount(state);

    // this.defaultCounterDuration = 2000;
    // this.lastCounterDuration = 5000;

    ControllerSounds.playBigWinSFX(0.4);

    this.container.scale.set(this.normalScale);
    this.alpha = 1;
    this.winType = this.eWinTypes.EWT_BIG_WIN;
    this.startAmount = 0;
    this.amount.text = 0;
    this.showBlackout();
    this.startCounter();
  }

  hide = () => {
    TweenMax.to(this.container.scale, 0.2, { x: 0, y: 0 });
    TweenMax.to(this, 0.2, { alpha: 0, onComplete: this.stop });
  }

  startCounter = () => {
    const state = store.getState();
    const decimal = selectors.getCurrencyInfo(state).decimal;
    this.decimal = Math.min(this.winAmount.getPrecision(), decimal);

    // this.targetAmount = this.winAmount > this.betAmount * constants[`MULTIPLIER_${this.getNextWinType()}`]
    // && this.winType !== this.eWinTypes.EWT_EPIC_WIN
    //   ? this.betAmount * constants[`MULTIPLIER_${this.getNextWinType()}`]
    //   : this.winAmount;
    if (this.winAmount >= this.betAmount * constants[`MULTIPLIER_${this.getNextWinType()}`]
      && this.winType !== this.eWinTypes.EWT_EPIC_WIN) {
      this.targetAmount = this.betAmount * constants[`MULTIPLIER_${this.getNextWinType()}`];
      // this.counterDuration = this.defaultCounterDuration;
    } else {
      this.targetAmount = this.winAmount;
      // this.counterDuration = this.lastCounterDuration;
    }

    if (this.winType === this.eWinTypes.EWT_EPIC_WIN){
      this.sound = eSoundType.EST_MEGA_WIN;
      setTimeout(()=> {
        ControllerSounds.playSound(eSoundType.EST_EPIC_WIN);
      }, 4000);
      this.counterDuration = 5000;
    } else if (this.winType === this.eWinTypes.EWT_MEGA_WIN) {
      this.sound = eSoundType.EST_BIG_WIN;
      this.counterDuration = 5000;
    } else {
      this.counterDuration = 5000;
      this.sound = null;
    }

    if (this.sound) ControllerSounds.playSound(this.sound);

    this.timeStartCounter = Date.now();
    this.winIsStarted = false;

    requestAnimationFrame(this.counterUpdate);
  }

  counterUpdate = () => {
    const time = Date.now() - this.timeStartCounter;

    if (time <= this.counterDuration) {
      const progress = time/this.counterDuration;
      this.drawFunction(progress);
      requestAnimationFrame(this.counterUpdate);

    } else if (this.winAmount >= this.betAmount * constants[`MULTIPLIER_${this.getNextWinType()}`]
      && this.winType !== this.eWinTypes.EWT_EPIC_WIN) {

      this.winType = this.getNextWinType();
      this.startAmount = this.targetAmount;
      this.coins0?.pause();
      this.startCounter();
    } else {
      this.amount.text = formatCurrency(this.winAmount, this.decimal);
      this.winAmount = null;
      this.betAmount = null;
      this.timeStartCounter = null;
      const title = this.titles[this.winType];
      TweenMax.killTweensOf(title.scale);
      TweenMax.to(title.scale, 0.4, { x: 1.11, y: 1.11 });
      ControllerSounds.stopBigWinSFX();
      setTimeout(this.hide, 2500);
      return;

    }
  }
  getNextWinType = () => {
    return this.winType === this.eWinTypes.EWT_BIG_WIN
      ? this.eWinTypes.EWT_MEGA_WIN
      : this.eWinTypes.EWT_EPIC_WIN;
  }

  drawFunction = (progress) => {
    const amount = (this.targetAmount - this.startAmount) * progress + this.startAmount;
    this.amount.text = formatCurrency(amount, this.decimal);

    if (!this.winIsStarted) {
      this.winIsStarted = true;
      this.startAnimation();
    }
  }

  startAnimation = () => {
    for (const key in this.titles) {
      this.titles[key].visible = key === this.winType;
      TweenMax.killTweensOf(this.titles[key].scale);
    }
    this.startParticles();
    const title = this.titles[this.winType];
    title.scale.set(0);
    const duration = 0.5;
    TweenMax.to(title.scale, duration, { x: 1, y: 1, onComplete: this.showIdleTitle });
    this.animation.visible = false;
    this.animation.visible = true;
    this.animation.state.setAnimation(0, eAnimationNameBigWins[`EANBW_${this.winType}_START`], false);
    this.animation.state.addAnimation(0, eAnimationNameBigWins[`EANBW_${this.winType}_IDLE`], true, 0);
  }

  showIdleTitle = () => {
    const title = this.titles[this.winType];
    const duration = 0.6;
    const targetScale = title.scale.x === 1 ? 1.1 : 1;
    TweenMax.to(title.scale, duration, { x: targetScale, y: targetScale, ease: 'none' , onComplete: this.showIdleTitle });

  }

  startParticles = () => {
    this.coins0?.pause();
    let config;
    if (this.winType === this.eWinTypes.EWT_BIG_WIN) {
      config = particalConfigs.bigWinConfig;
    } else if (this.winType === this.eWinTypes.EWT_MEGA_WIN) {
      config = particalConfigs.megaWinConfig;
    } else {
      config = particalConfigs.epicWinConfig;
    }
    this.coins0 = new ParticleSystem(this.particleContainer, { animationType: eAnimationType.EAT_COINS, animationName: 'coin' }, {
      ...particalConfigs.defaultCondig,
      ...config
    });
  }

  showBlackout() {
    if (!this.overlay) return;
    this.overlay.show(true, 0.3);
  }

  onKeyDown(event) {
    if (event.keyCode === 32 && this.visible) { //32 - Space
      this.showed = false;
      this.stop();
      this.winAmountCounter.stop();
    }
  }

  onClick() {
    this.showed = false;
    this.stop();
    this.winAmountCounter.stop();
  }

  stop = () => {
    this.coins0?.stop();
    this.animation.visible = false;
    if (this.visible) {
      store.dispatch(actionsModals.closeModal({ type: this.type }));
    }
  }

}
