export default {
  EPBPA_HIGH_3_CARD_IDLE: 'idle_scorpio_card',
  EPBPA_HIGH_2_CARD_IDLE: 'idle_cobra_card',
  EPBPA_HIGH_1_CARD_IDLE: 'idle_croc_card',
  EPBPA_HIGH_3_CARD_BUY: 'buy_scorp_card',
  EPBPA_HIGH_2_CARD_BUY: 'buy_cobra_card',
  EPBPA_HIGH_1_CARD_BUY: 'buy_croc_card',
  EPBPA_BUY_BUTTON_SIDE: 'buy_button_side',
  EPBPA_TITLE_IDLE: 'title_idle',
  EPBPA_TITLE_START: 'title_start',
};
