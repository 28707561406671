import animationsLoader from '../animations/animationsLoader';
import eAnimationType from '../animations/eAnimationType';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import eBonusGameCrocodileAnimation from './eBonusGameCrocodileAnimation';
import FlashLib from 'flashlib';
import { TweenMax } from 'gsap';
import ControllerSounds, { eSoundType } from '../sounds/controllerSounds';

export default class BonusGameCrocodile extends FlashLib.MovieClip {
  constructor(data, $displayItemData) {
    super(data, $displayItemData);
    this.data = {};
    this.isCrawlUnder = false;
    this.visible = false;
    this.rulesCloud = this.getChildByName('cloud');
    this.rulesCloudDefaultPosition = {
      x: this.rulesCloud.x,
      y: this.rulesCloud.y
    };
    this.positionDefaultX = 520;
    this.positionDefaultY = 710;
    this.reversPositionDefaultX = 570;

    this.distance = 1250;

    this.moveSpeed = 0.078 * 1.5;

    this.step = storeObserver.addListenerAndGetValue(selectors.getBonusGameCrocodileProgress, this.onProgress);
    storeObserver.addListener(selectors.getBonusGameShowed, this.onShowed);
    this.onShowed(true);
    this.setProgress(this.step);
    this.setTargetDistance();

    this.startPositionX = this.targetDistanceX;
    this.startPositionY = this.positionDefaultY;

    this.movingStarted = false;
    this.initAnimation();
    // this.show();

    this.onProgress(this.step);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize = (data) => {

    if (PIXI.utils.isMobile.any && data.isLandscape) {

      this.rulesCloud.pivot.x = 0;
      this.rulesCloud.scale.set(1);

      this.rulesCloud.x = this.rulesCloudDefaultPosition.x;
      this.rulesCloud.y = this.rulesCloudDefaultPosition.y;

    } else if (PIXI.utils.isMobile.any) {
      this.rulesCloud.pivot.x = this.rulesCloud.displayData.width / 2;
      this.rulesCloud.x = this.displayData.width / 2;
      this.rulesCloud.scale.set(1.8);
      this.rulesCloud.y = -550;
    }
  }

  // show = () => {
  //   this.animation.visible = true;
  //   this.animation.state.setAnimation(0, eBonusGameCrocodileAnimation.EBGCA_CROCODILE_IDLE, true); //IDLE
  //   console.log('EBGCA_CROCODILE_IDLE');
  //   // this.showAnimationMoving();
  // }

  initAnimation = () => {
    this.animation = animationsLoader.createAnimation(eAnimationType.EAT_BONUS_GAME_CROCODILE);
    this.animation.x = this.startPositionX;
    this.animation.y = this.startPositionY;
    // this.animation.rotate = -Math.PI;
    this.animation.stateData.defaultMix = 0.2;
    this.animation.stateData.setMix(eBonusGameCrocodileAnimation.EBGCA_CROCODILE_HIDE, eBonusGameCrocodileAnimation.EBGCA_CROCODILE_SHOW, 0);
    this.animation.state.timeScale = 1.5;
    this.animation.state.addListener({
      complete: this.onComleteCrocodileAnimation
    });
    this.animation.visible = false;
    this.addChild(this.animation);

  }
  onComleteCrocodileAnimation = (data) => {
    const name = data.animation.name;
    if (name === eBonusGameCrocodileAnimation.EBGCA_CROCODILE_HIDE && this.isCrawlUnder) {
      this.toRotate180();
      this.showCrocodile();
      this.isCrawlUnder = false;
    } else if (name === eBonusGameCrocodileAnimation.EBGCA_CROCODILE_HIDE) {
      this.setDefaultState();
      this.hide();
    }
  }
  onProgress = (value) => {
    if (!(value >= 0)) return;
    this.step = value;
    this.setProgress(this.step);

    if (this.step === 0) {
      if (this.animation.visible) this.crawlUnder();
    } else if (this.step < 6) {
      if (!this.movingStarted) this.showAnimationMoving();
    } else if (this.step === 6) {
      this.crawlUnder();
    } else if (this.step > 6) {
      if (!this.movingStarted) this.showAnimationMoving();
    }

    if (!this.animation.visible) {
      console.log('showCrocodile');
      if (this.step >= 6) this.toRotate180();
      this.setCurrentPosition();
      this.showCrocodile();
    }
  }

  onShowed = (isShowed) => {
    const bonusGameEnable = selectors.getBonusGameCrocodileEnabled(store.getState());
    if (!isShowed) {
      this.data.bonusGameEnable = isShowed;
      this.hideCrocodile();
      console.log('!hideBonusGameCrocodile!');
    } else if (!this.data.bonusGameEnable && bonusGameEnable) {
      this.data.bonusGameEnable = bonusGameEnable;
      this.show();
      console.log('!startBonusGameCrocodile!');
    }
  }

  toRotate180 = () => {
    this.setTargetDistance();
    if (this.step>5) {
      this.animation.y = 555;
    } else {
      this.animation.y = this.positionDefaultY;
    }
    this.animation.x = this.targetDistanceX;
    this.moveSpeed = -this.moveSpeed;
    this.animation.rotation = this.animation.rotation + Math.PI;
  }

  setProgress = (value) => {
    if (value < 6) {
      this.progress = 1/ 5 * value;
    } else if (value === 6) {
      this.progress = 1/ 5 * (11 - value);
    } else if (value > 6) {
      this.progress = 1/ 5 * (11 - value);

    }
  }

  setTargetDistance = () => {
    const defoltPositionX = this.step > 5 ? this.reversPositionDefaultX : this.positionDefaultX;
    this.targetDistanceX = defoltPositionX + this.distance * this.progress;
  }

  setCurrentPosition = () => {
    this.setTargetDistance();
    this.animation.x = this.targetDistanceX;
  }

  showAnimationMoving = () => {
    // this.progress = 1/5 * 5;
    this.movingStarted = true;

    this.animation.state.setAnimation(0, eBonusGameCrocodileAnimation.EBGCA_CROCODILE_MOVE, true);
    console.log('moveCrocodile');

    requestAnimationFrame(this.update);
  }

  drawFunction = (deltaTime) => {
    this.animation.x += this.moveSpeed * deltaTime;
  };

  update = () => {
    if (!this.prevTime) {
      this.prevTime = Date.now();
    }
    const nowTime = Date.now();
    let deltaTime = nowTime - this.prevTime ;
    if (deltaTime > 100) deltaTime = 0;
    this.prevTime = nowTime;
    this.setTargetDistance();
    if ( (this.step < 6 && this.animation.x < this.targetDistanceX) || (this.step > 6 && this.animation.x > this.targetDistanceX) ) {
      this.drawFunction(deltaTime);
      requestAnimationFrame(this.update);
    } else {
      console.log('stopCrocodile');
      this.movingStarted = false;
      if (this.isCrawlUnder ) return;
      this.animation.state.setAnimation(0, eBonusGameCrocodileAnimation.EBGCA_CROCODILE_IDLE, true); //IDLE
    }
  };

  crawlUnder = () => {
    this.animation.state.setAnimation(0, eBonusGameCrocodileAnimation.EBGCA_CROCODILE_HIDE, false);
    console.log('crawlUnderCrocodile');

    this.isCrawlUnder = true;
  }

  showCrocodile = () => {
    this.animation.visible = false;
    this.animation.visible = true;
    this.animation.state.setAnimation(0, eBonusGameCrocodileAnimation.EBGCA_CROCODILE_SHOW, false);
    this.animation.state.addAnimation(0, eBonusGameCrocodileAnimation.EBGCA_CROCODILE_IDLE, true, 0); //IDLE
  }

  hideCrocodile = () => {
    this.isCrawlUnder = false;
    console.log('hideCrocodile');
    this.animation.state.setAnimation(0, eBonusGameCrocodileAnimation.EBGCA_CROCODILE_HIDE, false);
  }

  setDefaultState = () => {
    this.animation.y = this.positionDefaultY;
    this.animation.x = this.positionDefaultX;
    this.animation.rotation = 0;
    this.moveSpeed = Math.abs(this.moveSpeed);
  }

  show = () => {
    this.visible = true;
    this.rulesCloud.alpha = 0;
    TweenMax.to(this.rulesCloud, 0.3, { alpha: 1 });
    ControllerSounds.replaceSFX(eSoundType.EST_SFX_BONUS_GAME_CROCODILE);
  }

  hide = () => {
    TweenMax.to(this.rulesCloud, 0.3, {
      alpha: 0,
      onComplete:()=> {
        this.animation.visible = false;
        this.visible = false;
      }
    });
  }
}
