// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js!./fonts.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/preloader_background.webp");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "html {\n  height: 100%;\n  min-height: 100%;\n  max-height: 100%;\n  background: #000000;\n}\n\nbody {\n  padding: 0;\n  margin: 0;\n  color: #000;\n  width: 100%;\n  height: 100%;\n  min-height: 100%;\n  max-height: 100%;\n  overflow: hidden;\n  background: #000000;\n}\n\n#container, #gameCanvas {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  margin: 0;\n  padding: 0;\n  overflow: visible;\n  width: 100%;\n  min-height: 100%;\n}\n\n#gameCanvas {\n  left: 50%;\n  transform: translateX(-50%);\n}\n\ninput[type=number]::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.blocker {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-color: black;\n  opacity: 0.5;\n  z-index: -1;\n}\n\n.preloader {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}", ""]);
// Exports
module.exports = exports;
